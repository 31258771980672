import { SYSTEM_TYPE_DEVICES, SYSTEM_TYPE_TRANSMISSIO_POINT, SYSTEM_TYPE_SCADA } from "../../siteConstants";
import { FLOW_METER_DEVICE_TYPE } from "../../constant";
export const getTemplatesBySystemType = (meterOrFacility, data) =>  {
    const statusClass = data.status == 'Live' ? 'esri-status-live': 'esri-status-offline';
    if(meterOrFacility === SYSTEM_TYPE_DEVICES) {
        const {uplinkReferenceKey, domain, status, deviceType, deviceManufacturer, consumptionFor30Days,consumptionUnit, percentageData, measurableLocation, dmsLatitude, dmsLongitude, activeAlerts} = data;
        return `
          <div>
            <div class="align-items-center" style="display:flex;margin-top:7px;">
             Device Serial:
             <div style="padding-left: 5px;font-size: 14px;">
              <span style="font-size:14px; color:#000"> 
                <a style="color: #6e6e6e;text-decoration: underline;cursor: pointer;font-weight: bold;padding-right:10px;" href=${domain}  target="_blank">
                  ${uplinkReferenceKey}
                </a>
              </span>
             </div>
              <div class="align-items-center" style="display:flex;margin-top:3px">
                ${
                  activeAlerts && false ? 
                    ` <div style="display:flex; aligh-items:center">
                        <span class="material-symbols-outlined" style="font-size:15px; margin-top:5px; color:#ff1414">warning</span> &nbsp; <span style="font-size:14px; font-weight:bold">${activeAlerts}<span>
                      </div>
                    `
                  :
                  ''
                }
                <div class=${statusClass}>${status}</div>
                </div>
          </div>
          <div class="align-items-center" style="display:flex;padding-top: 10px">
          <div> Device Type: <span style="font-weight: bold; color:#000;"> ${deviceType} </span> </div>
          <div style="font-size: 13px;color: #616161;font-weight: 300;padding-left: 7px">Manufacturer: <span style="font-weight: bold; color:#000;">${deviceManufacturer}<span></div>
          </div>
          <div class="align-items-center" style="padding-top: 10px">
            <div> Consumption : <span style="font-weight: bold; color:#000;"> ${consumptionFor30Days} ${consumptionUnit} (Last 30 days) </span> </div>
          </div>
          <div class="align-items-center" style="padding-top: 10px">
            <div> Data Availability Score: <span style="font-weight: bold; color:#000;"> ${percentageData} % (Last 30 days) </span> </div>
          </div>
          <div class="align-items-center mclass" style="display:flex; margin: 10px 0 5px;height:20px;">
            <div> Location: <span style="font-weight: bold; color:#000;"> ${measurableLocation} </span> </div>
            <div style="padding-left: 7px">Latitude: ${dmsLatitude}" <span style="padding-left:7px">Longitude: ${dmsLongitude}"</span></div>
          </div>
        </div>`
    }
    else if(meterOrFacility === FLOW_METER_DEVICE_TYPE) {
        const {domain, description, lastDataReceivedOn, volume, flow, status, dmsLatitude, dmsLongitude, activeAlerts} = data;
        return  `
        <div>
            <div class="align-items-center" style="display:flex;margin-top:7px;">
                <div style="font-size: 12px;margin-top:4px;"> 
                  Device Facility: <span> <a style="color: #6e6e6e;text-decoration: underline;cursor: pointer;font-weight: bold;font-size:14px;padding-right:10px;" href=${domain}  target="_blank">${description}</a> </span> 
                </div>
              <div class="align-items-center" style="display:flex;margin-top:3px">
                 ${
                  activeAlerts && false? 
                    ` <div style="display:flex; aligh-items:center">
                        <span class="material-symbols-outlined" style="font-size:15px; margin-top:5px; color:#ff1414">warning</span> &nbsp; <span style="font-size:14px; font-weight:bold">${activeAlerts}<span>
                      </div>
                    `
                  :
                  ''
                }
                <div class=${statusClass}>${status}</div>
                </div>
            </div>
            <div class="align-items-center" style="display:flex; padding-top: 10px">
              <div> Volume: <span style="font-weight: bold; color:#000;"> ${volume} </span> </div>
              <div style="padding-left: 7px"> Flow: <span style="font-weight: bold; color:#000;"> ${flow} </span> </div>
            </div>
            <div class="align-items-center" style="padding-top: 10px">
              <div> Last Data Received On: <span style="font-weight: bold; color:#000;"> ${lastDataReceivedOn} </span> </div>
            </div>
            <div class="align-items-center mclass" style="display:flex; margin: 10px 0 5px;height:20px;">
              <div>Latitude: ${dmsLatitude}" <span>Longitude: ${dmsLongitude}"</span></div>
            </div>
            </div>
        `
    }
    else if(meterOrFacility === SYSTEM_TYPE_SCADA) {
        const {domain, description, Conductivity, Turbidity, pH, lastDataReceivedOn, status, dmsLatitude, dmsLongitude, activeAlerts} = data;
        return `
        <div>
            <div class="align-items-center" style="display:flex;margin-top:7px;">
                <div style="font-size: 12px;margin-top:4px;"> 
                  Device Facility: <span> <a style="color: #6e6e6e;text-decoration: underline;cursor: pointer;font-weight: bold;font-size:14px;padding-right:10px;" href=${domain}  target="_blank">${description}</a> </span> 
                </div>
                 <div class="align-items-center" style="display:flex;margin-top:3px">
            <div>
               ${
                  activeAlerts && false ? 
                    ` <div style="display:flex; aligh-items:center">
                        <span class="material-symbols-outlined" style="font-size:15px; margin-top:5px; color:#ff1414">warning</span> &nbsp; <span style="font-size:14px; font-weight:bold">${activeAlerts}<span>
                      </div>
                    `
                  :
                  ''
                }
            </div>
            <div class=${statusClass}>${status}</div>
            </div>
            </div>
            </div>
            <div class="align-items-center" style="display:flex; padding-top: 10px">
              <div> Conductivity: <span style="font-weight: bold; color:#000;"> ${Conductivity} </span> </div>
              <div style="padding-left: 7px"> Turbidity: <span style="font-weight: bold; color:#000;"> ${Turbidity} </span> </div>
              <div style="padding-left: 7px"> pH: <span style="font-weight: bold; color:#000;"> ${pH} </span> </div>
            </div>
            <div class="align-items-center" style="padding-top: 10px">
              <div> Last Data Received On: <span style="font-weight: bold; color:#000;"> ${lastDataReceivedOn} </span> </div>
            </div>
            <div class="align-items-center mclass" style="display:flex; margin: 10px 0 5px;height:20px;">
              <div>Latitude: ${dmsLatitude}" <span>Longitude: ${dmsLongitude}"</span></div>
            </div>
            </div>
        `
    }
    else if(meterOrFacility === SYSTEM_TYPE_TRANSMISSIO_POINT) {
        let {domain, description, status, lastDataReceivedOn, dmsLatitude, dmsLongitude, activeAlerts} = data;
        const site = domain.split("?site=")[1];
        if(site) {
          localStorage.setItem('industrialDashboardSite',site);
          domain = domain.split("?")[0];
        }
        return `
        <div>
          <div class="align-items-center" style="display:flex;margin-top:7px;">
            <div style="font-size: 12px;margin-top:4px;"> 
               Device Facility: 
                <span> 
                  <a style="color: #6e6e6e;text-decoration: underline;cursor: pointer;font-weight: bold;font-size:12px;padding-right: 8px;" href=${domain}  target="_blank">${description}
                  </a> 
                </span> 
            </div>
            <div class="align-items-center" style="display:flex;margin-top:3px">
            <div>
              ${
                  activeAlerts && false ? 
                    ` <div style="display:flex; aligh-items:center">
                        <span class="material-symbols-outlined" style="font-size:15px; margin-top:5px; color:#ff1414">warning</span> &nbsp; <span style="font-size:14px; font-weight:bold">${activeAlerts}<span>
                      </div>
                    `
                  :
                  ''
                }            </div>
            <div class=${statusClass}>${status}</div>
            </div>
        </div>
         <div class="align-items-center" style="padding-top: 10px">
          <div> Downstream Pressure: <span style="font-weight: bold; color:#000;"> ${data['Downstream Pressure']} </span> </div>
          <div style="padding-top: 10px"> Upstream Pressure: <span style="font-weight: bold; color:#000;"> ${data['Upstream Pressure']} </span> </div>
        </div>
        <div class="align-items-center">
          <div style="padding-top: 10px"> Flow Rate: <span style="font-weight: bold; color:#000;"> ${data['Flow Rate'] ? data['Flow Rate'] : '-'} </span> </div>
          <div style="padding-top: 10px"> Last Data Received On: <span style="font-weight: bold; color:#000;"> ${lastDataReceivedOn} </span> </div>
        </div>
        <div class="align-items-center mclass" style="display:flex; margin: 10px 0 5px;height:20px;">
          <div>Latitude: ${dmsLatitude}" <span >Longitude: ${dmsLongitude}"</span></div>
        </div>
        </div>`
    }
}


export const createCustomPaginationForPopupHeader = (popupHeader, current, totalCount) => {
    // Clear the existing content (if any)
    while (popupHeader.firstChild) {
      popupHeader.removeChild(popupHeader.firstChild);
    }
  
    // Create a DocumentFragment to assemble the new structure
    const fragment = document.createDocumentFragment();
  
    // Create the custom div for the new content
    const customDiv = document.createElement('div');
    customDiv.style.display = 'flex';
    customDiv.style.alignItems = 'center';
    customDiv.style.justifyContent = 'space-between';
    customDiv.style.width = '100%';
  
    // Create the left-side zoom section
    const zoomDivContainer = document.createElement('div');
    const zoomDiv = document.createElement('div');
    zoomDiv.classList.add('material-symbols-outlined');
    zoomDiv.id = 'custom-esri-zoom';
    zoomDiv.textContent = 'zoom_in';
  
    const zoomSpan = document.createElement('span');
    zoomSpan.textContent = ' Zoom to';
  
    zoomDiv.appendChild(zoomSpan);
    zoomDivContainer.appendChild(zoomDiv);
  
    // Create the pagination section
    const paginationDivContainer = document.createElement('div');
    const paginationInnerDiv = document.createElement('div');
    paginationInnerDiv.style.display = 'flex';
    paginationInnerDiv.style.alignItems = 'center';
    paginationInnerDiv.style.justifyContent = 'space-between';
    paginationInnerDiv.style.width = '100%';
  
    // Previous button
    const prevButton = document.createElement('div');
    prevButton.classList.add('material-symbols-outlined');
    prevButton.id = 'esri-pagination-prev';
    prevButton.style.cursor = 'pointer';
    prevButton.style.marginRight = '10px';
    prevButton.textContent = 'chevron_backward';
  
    // Pagination text
    const paginationText = document.createElement('div');
    paginationText.id = 'esri-pagination-text';
    paginationText.textContent = `${current} of ${totalCount}`;
  
    // Next button
    const nextButton = document.createElement('div');
    nextButton.classList.add('material-symbols-outlined');
    nextButton.id = 'esri-pagination-next';
    nextButton.style.cursor = 'pointer';
    nextButton.style.marginLeft = '10px';
    nextButton.textContent = 'chevron_forward';
  
    // Append pagination elements
    paginationInnerDiv.appendChild(prevButton);
    paginationInnerDiv.appendChild(paginationText);
    paginationInnerDiv.appendChild(nextButton);
  
    paginationDivContainer.appendChild(paginationInnerDiv);
  
    // Append both sections to the custom div
    customDiv.appendChild(zoomDivContainer);
    customDiv.appendChild(paginationDivContainer);
  
    // Append customDiv to the DocumentFragment
    fragment.appendChild(customDiv);
  
    // Finally, append the fragment to the popupHeader
    popupHeader.appendChild(fragment);
}