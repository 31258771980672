import { Component, EventEmitter, Output, Input, OnChanges, SimpleChanges, QueryList, ViewChildren, ElementRef} from '@angular/core';
import { IDeviceInformation, IDeviceTelemetryParams } from 'src/app/modules/alerts/alerts.interface';

@Component({
  selector: 'app-attribute-dropdown',
  templateUrl: './attribute-dropdown.component.html',
  styleUrls: ['./attribute-dropdown.component.css']
})
export class AttributeDropdownComponent {
  @Input() filteredAttributes: IDeviceInformation[] = [];
  @Input() activeIndex: number = 0;
  @Output() attributeSelected = new EventEmitter<IDeviceInformation>();

  @ViewChildren('attributeItem') attributeItems!: QueryList<ElementRef>;

  selectAttribute(attribute: IDeviceInformation) {
    this.attributeSelected.emit(attribute);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['activeIndex'] && !changes['activeIndex'].firstChange) {
      this.scrollToActiveItem();
    }
  }

  scrollToActiveItem(): void {
    const itemsArray = this.attributeItems.toArray();
    const activeElement = itemsArray[this.activeIndex]?.nativeElement;
    if (activeElement) {
      activeElement.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
  }

}
