import { Component, OnInit, ViewChild, Output, EventEmitter, Input } from '@angular/core';
import { ElementRef } from '@angular/core';
import { ArcgisMapService } from 'src/app/services/arcgis-map/arcgis-map.service';
import { loadModules } from "esri-loader";
import { ArcgisMapsDeviceConfigurationsService } from 'src/app/services/arcgis-map/devices-fields-template-renderer/arcgis-maps-device-configurations.service';
import { MapInfo } from 'src/app/services/arcgis-map/interfaces';
import { createCustomPaginationForPopupHeader } from 'src/app/services/arcgis-map/devices-fields-template-renderer/device-templates';

@Component({
  selector: 'app-device-listing-esri',
  templateUrl: './device-listing-esri.component.html',
  styleUrls: ['./device-listing-esri.component.css']
})
export class DeviceListingEsriComponent implements OnInit {

  @ViewChild("mapViewNode", { static: true }) private mapViewEl: ElementRef;
  @Output() mapInitialized = new EventEmitter<any>();
  @Output() heatMapAdded = new EventEmitter<any>();
  @Input() selectedHeatMap = false;
  mapInfo:MapInfo = {
    center: [35.113245, 28.114319],
    zoom: 9,
  }
  heatmapLayer;
  devicesFeatureLayer;
  labelLayer;
  map;
  view;
  devices;
  systemType;
  hitTestHandles;
  currentIndex = 0;
  totalOverllapedDevice = [];
  actionHandler;
  selectedGeometry;
  currentZoom;
  isClusterEnabled = false;
  LabelClass
  clusterInfo = {
    clusterRadius: '50px',
    clusterMinSize: '50px',
    clusterMaxSize: '60'
  }
  constructor(
    private arcgisMapService:ArcgisMapService,
    private arcgisMapsDeviceConfigurationsService: ArcgisMapsDeviceConfigurationsService
  ) { }

  async ngOnInit() {
  }   

  async ngAfterViewInit():Promise<any> {
    await this.arcgisMapService.initializeMap(this.mapViewEl, this.mapInfo);
    this.map = this.arcgisMapService.getMapInstance();
    this.view = this.arcgisMapService.getViewInstance();
    this.view.popup.autoOpenEnabled = false;
    this.mapInitialized.emit(true);
    this.view.constraints = {
      minZoom: 7
    };
    this.addViewWatchZoomEvent();
  }

  async addDevicesToMap(devices, mapInfo) {
    // set the mapInfo for recentering logic
    this.mapInfo = mapInfo;
    this.arcgisMapService.setMapInfo(mapInfo);
    this.currentZoom = mapInfo.zoom;
    await this.addDevices(devices, mapInfo.center);
  }

  private async addDevices(devices, center) {
    this.devices = devices;
    this.systemType = devices[0]?.systemType || 'Devices';
    const [FeatureLayer, Point, SimpleMarkerSymbol, Graphic, UniqueValueRenderer, TextSymbol, LabelClass] = await loadModules([
      "esri/layers/FeatureLayer",
      "esri/geometry/Point",
      "esri/symbols/SimpleMarkerSymbol",
      "esri/Graphic",
      "esri/renderers/UniqueValueRenderer",
      "esri/symbols/TextSymbol",
      "esri/layers/support/LabelClass"
    ]);

    this.LabelClass = LabelClass;
    // remove existing layer if any and set the center
    this.removeAllLayer();
    this.view.center = center;

    // create the graphics array of devices
    const graphics = [];
    const groupedDevices = this.arcgisMapsDeviceConfigurationsService.groupTheDeviceCountOnLatLong(devices);
    devices.forEach(device => {
      if(device.location && this.isItValidCoordinates(device.location)) {
        const point = new Point({
          longitude: device.location.longitude,
          latitude: device.location.latitude,  
        });
  
        const graphic = new Graphic({
          geometry: point,
          attributes: this.arcgisMapsDeviceConfigurationsService.createAttributes(device, groupedDevices, this.systemType)
        });
        graphics.push(graphic);
      }
    });

    this.devicesFeatureLayer = new FeatureLayer({
      source: graphics,
      fields: this.arcgisMapsDeviceConfigurationsService.createFields(this.systemType),
      objectIdField: 'oid',
      geometryType: 'point',
      id: 'devicesFeatureLayer',
      spatialReference: { wkid: 4326 },
      title: 'device',
      renderer: this.arcgisMapsDeviceConfigurationsService.getRenderer(UniqueValueRenderer, SimpleMarkerSymbol),
    });
    this.addCluster();
    this.map.add(this.devicesFeatureLayer);
    this.addHitTestEvent();
    this.addCustomActionOnPopupTooltip();
  }

  addHitTestEvent() {
    // listen to click event on map
    this.hitTestHandles = this.view.on("click", (event) => {
      this.view.hitTest(event).then(({ results }) => {
        // By click on map, close the popupTemplate first and 
        this.closePopupTemplate();
        this.handleClick(results);

        /* call open popup component after 100ms so that map will complete there operations meanwhile
           open popup function is not async function and it not either give the instance to check of it open status, that's why need to wait manually
        */
        setTimeout(() => {
          this.openPopupTemplate(results, event);
        }, 100);
      });
    });
    this.addCustomActionOnPopupTooltip();
  }

  addViewWatchZoomEvent() {
    this.view.watch('zoom', (newZoom) => {
      this.currentZoom = newZoom;
      if(!this.selectedHeatMap) {
        if (newZoom > 15) {
          this.isClusterEnabled = false;
          this.removeCluster();
        } else {
          this.isClusterEnabled = true;
          this.addCluster();
        }
      }
    }); 
  }

  isItValidCoordinates({longitude, latitude}) {
    return latitude && longitude && latitude >= -90 && latitude <= 90 && longitude >= -180 && longitude <= 180;
  }

  addCluster() {
    this.clusterInfo = {
      clusterRadius: '50px',
      clusterMinSize: '50px',
      clusterMaxSize: '60'
    }
    this.devicesFeatureLayer.featureReduction = this.arcgisMapsDeviceConfigurationsService.getFeatureReductionTemplateForRadius(this.clusterInfo, this.LabelClass, '');
  }

  removeCluster() {
    this.devicesFeatureLayer.featureReduction = null;
  }

  // if the clicked one is cluster then zoom into it
  handleClick(results) {
    const isCluster = results[0].layer?.featureReduction?.type === "cluster";
    const clusterCount = results[0]?.graphic?.attributes?.cluster_count;
    // if it is cluster and size greater than 1 then zoom to devices
    if(isCluster && clusterCount > 1){
      const cluster = {target:results[0].graphic.geometry, zoom: 16};
      this.view.goTo(
        {
          target: cluster.target,
          zoom: cluster.zoom
        },
        {
          duration: 1200, // Duration in milliseconds (e.g., 2000ms = 2 seconds)
          easing: "ease-in-out" // Easing function for smooth transition
        }
      );
    }
  }

  addCustomActionOnPopupTooltip() {
    this.view.popup.watch('visible', (isVisible) => {
      if (isVisible) {
        let current = this.currentIndex+1;
        let totalCount = this.totalOverllapedDevice.length;
        setTimeout(() => {
          const popupHeader = document.querySelector('.esri-popup__inline-actions-container'); // Adjust the selector as necessary
          if (popupHeader) {
            createCustomPaginationForPopupHeader(popupHeader, current, totalCount)
            let paginationText = document.getElementById('esri-pagination-text');
            // listen event for click next
            document.getElementById("esri-pagination-next")?.addEventListener("click", () => {
              if(this.currentIndex+1 < this.totalOverllapedDevice.length ) {
                this.currentIndex = this.currentIndex +1 ;
                const selected = this.totalOverllapedDevice[this.currentIndex];
                const data = this.devicesFeatureLayer.source.items.filter((source) => source.attributes.oid === selected.graphic.attributes.oid)[0];
                paginationText.textContent = `${this.currentIndex+1} of ${this.totalOverllapedDevice.length}`;
                this.view.popup.content = this.arcgisMapsDeviceConfigurationsService.createTemplate(data.attributes, this.systemType);
                this.selectedGeometry = selected.graphic.geometry;
              }
            });
            
            // listen event for click prev
            document.getElementById("esri-pagination-prev")?.addEventListener("click", () => {
              if(this.currentIndex > 0) {
                this.currentIndex = this.currentIndex - 1;
                const selected = this.totalOverllapedDevice[this.currentIndex];
                const data = this.devicesFeatureLayer.source.items.filter((source) => source.attributes.oid === selected.graphic.attributes.oid)[0];
                paginationText.textContent = `${this.currentIndex+1} of ${this.totalOverllapedDevice.length}`;
                this.view.popup.content = this.arcgisMapsDeviceConfigurationsService.createTemplate(data.attributes, this.systemType);
                this.selectedGeometry = selected.graphic.geometry;
              }
            });

            // listen event of click zoom
            document.getElementById("custom-esri-zoom")?.addEventListener("click", () => {
              this.currentZoom = this.currentZoom + 1;  
              this.view.goTo( {
                  target: this.selectedGeometry,
                  zoom: this.currentZoom
                },
                {
                  duration: 300, // Duration in milliseconds (e.g., 2000ms = 2 seconds)
                  easing: "ease-in-out" // Easing function for smooth transition
                })
            });
          }
        }, 100); // Delay to ensure the popup header is available
      }
    });
    
  }

  openPopupTemplate(response, event) {
    const results = response.filter((result) => result.graphic.layer === this.devicesFeatureLayer);
    if(!results || !results.length) return;
    const isCluster = results[0].layer?.featureReduction?.type === "cluster";
    const clusterCount = results[0]?.graphic?.attributes?.cluster_count;

    // if it is cluster with size > 1 or it some random point then no need to show tooltip
    if(isCluster && clusterCount > 1) return ;

    // paginate if more than 1 device at a point
    this.currentIndex = 0; 
    this.selectedGeometry = null;
    let selected = results[this.currentIndex];
    // filter out the devices which having same latitude and longitude and it also resolve the graphic overlapping pagination count issue
    this.totalOverllapedDevice = results.filter((result) => result.graphic.geometry.longitude == selected.graphic.geometry.longitude && result.graphic.geometry.latitude == selected.graphic.geometry.latitude);
    let data = this.devicesFeatureLayer.source.items.filter((source) => source.attributes.oid === selected.graphic.attributes.oid)[0];
    this.selectedGeometry = selected.graphic.geometry;
    this.showDataOnPopupTooltip(data, selected.graphic.geometry);
  }

  showDataOnPopupTooltip(data, geometry) {
    this.view.popup.open({
      content: this.arcgisMapsDeviceConfigurationsService.createTemplate(data.attributes, this.systemType),
      location: geometry,
    });
  }

  async updateData() {
    const [Point, Graphic] = await loadModules([
      "esri/geometry/Point",
      "esri/Graphic"
    ]);
    const source = this.devicesFeatureLayer.source;
    this.devicesFeatureLayer.refresh();
  }

  
  async removeAllLayer() {
    this.map?.remove(this.devicesFeatureLayer);
    this.map?.remove(this.heatmapLayer);
    this.closePopupTemplate();
    this.hitTestHandles?.remove();
  }

  closePopupTemplate() {
    this.view.popup.close();
    this.actionHandler?.remove();
    this.actionHandler = null;
  }
  async addHeatMap(devices) {
    const [FeatureLayer,] = await loadModules([
      "esri/layers/FeatureLayer",
    ]);
    // remove existing Heat map layer if any and recenter map if not in focus;
    this.removeHeatMapLayer();
    if(this.isClusterEnabled) {
      this.removeCluster();
    }

    if (devices.length > 0) {
      const [Graphic] = await loadModules([
        "esri/Graphic",
      ]);
      const features = devices.map((data, index) => {
        let intensity = data.deviceType === "Water Meter" ? data.consumptionFor30Days * 25 : data.consumptionFor30Days;
        return new Graphic({
          geometry: {
            type: "point",
            latitude: data.location.latitude,
            longitude: data.location.longitude
          },
          attributes: {
            deviceID: data.deviceID,
            Intensity: intensity > 18 ? 180 : Math.ceil(intensity) * 10
          }
        });
      });
      this.heatmapLayer = new FeatureLayer({
        source: features,
        objectIdField: "deviceID",
        fields: [
          {
            name: "deviceID",
            alias: "Device ID",
            type: "string"
          },
          {
            name: "Intensity",
            alias: "Intensity",
            type: "integer"
          }
        ],
        renderer: {
          type: "heatmap",
          field: "Intensity",
          colorStops: this.arcgisMapsDeviceConfigurationsService.getColorStopsForHeatMap(),
          radius: 25,
          // blurRadius: 15,
          maxPixelIntensity: 10,
          minPixelIntensity: 0,
          maxDensity: 0.14,
          minDensity: 0,
          zoom: 4,

        }
      });
      this.view.constraints.snapToZoom = false;
      this.view.constraints.minScale = 1155582;
      this.map.add(this.heatmapLayer);
    }
  }

  removeHeatMapLayer() {
    if(this.currentZoom <= 15) {
      this.addCluster();
    } 
    this.map?.remove(this.heatmapLayer);

  }

  removeDevicePointLayer() {
    this.map?.remove(this.devicesFeatureLayer);
  } 

  addDevicePointLayer() {
    this.map.add(this.devicesFeatureLayer);
  }

}
