import * as d3 from "d3";
export const FORCASTCOLOR = [
  "#000000",
  '#4CD964',
  '#2D60F9'
]
export const COLOR =
  [
    '#007bff',//'#5AC8FA',
    '#4CD964',
    '#5AC8FA',
    '#A67CDE',
    '#FEB703',
    "#4285F4",//"#4285F4",
    "#AEC7E8",
    "#FF7F0E",
    "#FFBB78",
    "#2CA02C",
    "#98DF8A",
    "#D62728",
    "#FF9896",
    "#9467BD",
    "#C5B0D5",
    "#8C564B",
    "#C49C94",
    "#E377C2",
    "#F7B6D2",
    "#7F7F7F",
    "#C7C7C7",
    "#BCBD22",
    "#DBDB8D",
    "#17BECF",
    "#9EDAE5"
  ];

  export const COLOR_BAR_CHART =
  [
    '#205BF1',
    '#915EFD',
    '#007bff',//'#5AC8FA',
    '#4CD964',
    '#5AC8FA',
    '#A67CDE',
    '#FEB703',
    "#4285F4",//"#4285F4",
    "#AEC7E8",
    "#FF7F0E",
    "#FFBB78",
    "#2CA02C",
    "#98DF8A",
    "#D62728",
    "#FF9896",
    "#9467BD",
    "#C5B0D5",
    "#8C564B",
    "#C49C94",
    "#E377C2",
    "#F7B6D2",
    "#7F7F7F",
    "#C7C7C7",
    "#BCBD22",
    "#DBDB8D",
    "#17BECF",
    "#9EDAE5"
  ];

/* axisGrid format */
export const axisGridColor = "#eceef1";
export const axisTextColor = "#929ca8";
export const axisBottomTextColor = "#000000";
export const axisGridWidth = "1px";
/* axisGrid format */

/* tooltip format */
export const tooltipLineColor = "#929ca8";
export const tooltipLineWidth = 2;
export const tooltipLineDasharray = 5;
export const tooltipCircleRadius = 5;
export const tooltipCircleStrokeWidth = 10;
export const tooltipCircleStrokeOpacity = 0.6;
/* tooltip format */

/* scroll filter format */
export const handleWidth = 4;
export const handleWidthVP = "0.3vh";
export const filterScrollTextPadding = 10
/* scroll filter format */
/* Windows Size */
export const viewportWidth = window.innerWidth;
export const viewportHeight = window.innerHeight;

/* Windows Size */

/* line and area format */
export const lineOpacity = 1;
export const areaOpacity = 0.5;
export const lineWidth = 2;
export const lineStroke = "1.5px";
/* line and area format */

/* alert format */
export const alertLineColor = "#00a363";
export const alertEventColor = COLOR[0];;
export const alertEventOpacity = 0.1;
export const alertEventWidth = 1;

/* alert format */

/* opration range format */
export const operationalRangeColor = COLOR[0];
export const operationalRangeOpacity = 0.1;
export const operationalRangeWidth = 1;

/* alert format */


/* date format */
export const nativeFormat = d3.timeFormat("%d/%m/%Y %H:%M:%S");
export const hourlyFormat = d3.timeFormat("%d/%m/%Y %H:%M");
export const dailyFormat = d3.timeFormat("%d/%m/%Y");
export const weeklyFormat = d3.timeFormat("Week %V");
export const monthlyFormat = d3.timeFormat("%b' %Y");
export const yearlyFormat = d3.timeFormat("%Y");
/* date format */
/* Handle Ticks */
export const tickSize = 5;
/* Handle Ticks */

export const axisConfig = {
  yAxis: {
    showGrid: true,
    axisGridColor: axisGridColor,
    showTick: false,
    axisTextColor: axisTextColor
  },
  xAxis: {
    showGrid: false,
    axisGridColor: axisGridColor,
    showTick: true,
    axisTextColor: axisTextColor
  }
}

export const margin = {
  top: 10,
  right: 35,
  bottom: 40,
  left: 60
}
export const VPmargin = {
  ploatfilterheight: '2.083vw',
  yAxisMarginleft: '7.3vh',
  yAxisMarginTop: '0.521vw',
  yAxisMarginLeftStatus: '8.8vh',
  xAxisMarginBottom: '2.083vw',
  marginRight: '3.5vh'

}

export const AGGREGATION_INTERVAL_OPTIONS = [
  {
    name: '15 min',
    value: 'quarter_hourly',
  },
  {
    name: 'Hourly',
    value: 'hourly',
  },
  {
    name: 'Daily',
    value: 'daily',
  }, {
    name: 'Weekly',
    value: 'weekly',
  }, {
    name: 'Monthly',
    value: 'monthly',
  }, {
    name: 'Yearly',
    value: 'yearly',
  }, {
    name: 'Native',
    value: 'native',
  }
];

export const CHART_TYPE_OPTIONS = [
  {
    name: 'Area Chart',
    value: 'areachart',
  },
  {
    name: 'Bar Chart',
    value: 'barchart',
  },
  {
    name: 'Line Chart',
    value: 'linechart',
  },
  {
    name: 'Table Chart',
    value: 'tablechart',
  }
];

export const CHART_TYPE_OPTIONS_NATIVE = [
  {
    name: 'Area Chart',
    value: 'areachart',
  },
  {
    name: 'Line Chart',
    value: 'linechart',
  },
  {
    name: 'Table Chart',
    value: 'tablechart',
  }
];

export const AGGREGATION_TYPE_OPTIONS = [
  {
    name: 'Sum',
    value: 'Sum',
  },
  {
    name: 'Difference',
    value: 'Diff',
  },
  {
    name: 'Average',
    value: 'Average',
  },
  {
    name: 'Maximum',
    value: 'Max',
  },
  {
    name: 'Minimum',
    value: 'Min',
  }];

export const alertColors = {
  'Numeric Threshold': '#4285F4',
  'Stale Data': '#FF7F0E',
  'Stagnant Reading': '#C7C7C7',
  'Subscribed': '#DBDB8D' 
}