<!--New Code-->
<div id="mainContainer" class="main-Dashboard">
    <!-- <h2 class="dashboardHeading">Welcome, {{activeUserName | titlecase}}</h2> -->

    <!-- <app-common-cpaa></app-common-cpaa> -->

    <div class="mapBlk">
        <!-- <app-device-listing-map *ngIf="isMap"
            [data]="filteredDevices"
            [devices]="devices"
            [selectedSite]="selectedSite" 
            [centerOfMap]="centerOfMap"
            [accessingFrom]="'dashboard'"
            (selectedToggle)="selectedToggle($event)"
            (mapGraphToggle)="mapGrapgToggle($event)"
        ></app-device-listing-map> -->
        <app-landing-page-map></app-landing-page-map>

        <!-- <div class="chart-container" *ngIf="!isMap">
            <app-home
            [accessingFrom]="'dashboard'"
            (mapGraphToggle)="mapGrapgToggle($event)"
            ></app-home>
        </div> -->
    </div>

    <!-- <app-meter-status></app-meter-status> -->

    <!-- <app-pinned-dashboards></app-pinned-dashboards> -->











    <!-- old code -->
    <!-- <header class="containerHeader">
        <div class="commonHeading">Welcome, {{activeUserName | titlecase}}</div>
    </header>
    <div id="containerBody">
        <router-outlet></router-outlet>
        <div id="customDashboardList">
            <app-custom-dashboard-list></app-custom-dashboard-list>
        </div>
    </div> -->
</div>