export const DEVICE_TYPE_PARAMETER_WATER = 'water';
export const DEVICE_TYPE_PARAMETER_ENERGY = 'energy';
export const DEVICE_TYPE_PARAMETER_COOLING = 'cooling';
export const PIPE_BURST = 'Pipe Burst';
export const OPEN_TAP = 'Open Tap';
export const ALERT_NAME_CONTINOUS_FLOW = 'Continuous Flow';
export const DUBA_PLANT_SCADA = 'DubaPlantScada';
export const DEVICE_TYPE_WATER_METER_GATEWAY = 'Water Meter Gateway';
export const DEVICE_TYPE_GATEWAY = 'Gateway';
export const NOTIFICATION_STATUS_NOT_SEEN = 'notseen';
export const NOTIFICATION_STATUS_SEEN = 'seen';
export const NOTIFICATION_STATUS_CLICKED = 'clicked';
export const HYDRUS_METER = 'Hydrus Meter';
export const MONTHS_NAME = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "July", "Aug", "Sep", "Oct", "Nov", "Dec"];
export const FULL_MONTHS_NAME = ['January', 'Feburary', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
export const WEEKS_DAY = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
export const FULL_WEEKS_DAY = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
export const AGGREGATE_DATA_HOUR = 'hour';
export const AGGREGATE_DATA_DAY = 'day';
export const AGGREGATE_DATA_WEEK = 'WEEK';
export const LOW = 'Low';
export const MEDIUM = 'Medium';
export const HIGH = 'High';
export const CRITICAL = 'Critical';
export const ACTIVE = 'Active';
export const RESOLVED = 'Resolved';
export const SMART = 'smart';
export const DEVICE = 'device';
export const THRESHOLD = 'threshold';
export const DATE_TIME_FORMAT = "YYYY-MM-DDTHH:mm:ss.00Z";
export const DATE_TIME_FORMAT_DAY_START = "YYYY-MM-DDT00:00:00.00Z";
export const DATE_TIME_FORMAT_DAY_END = "YYYY-MM-DDT23:59:59.00Z";
export const DATE_FORMAT = "YYYY-MM-DD";
export const DATE_TIME_FORMAT_IGNORE_MILLISECONDS = "YYYY-MM-DDTHH:mm:ss.00Z";
export const DATE_TIME_FORMAT_CURRENT = "DD-MMM-YYYY HH:mm:ss";
export const ENCRYPTION_KEY = '#C|!jwsXY4hu9nXa';

// meter types
export const DUBA_DEVICE_TYPE = 'DubaPlantScada'
export const PUMP_STATION_DEVICE_TYPE = 'Pump Station'
export const ALBADA_DEVICE_TYPE = 'Water Recycling Plant'
export const HPR_STAION_DEVICE_TYPE = 'Dosing Plant';
export const FLOW_METER_DEVICE_TYPE = 'Flow Meter';
export const SHARMA_OPCUA_DEVICE_TYPE = 'Sharma Opcua Central Server'
export const LOGISTICS_PARK_DEVICE_TYPE = 'Logistics Park';
export const NEOM_BAY_AIRPORT_DEVICE_TYPE = 'NEOM Bay Airport';
export const EXPERIENCE_CENTER_DEVICE_TYPE = 'Experience Center';
export const DEVICE_TYPE_WATER_METER = 'Water Meter';
export const DEVICE_TYPE_ENERGY_METER = 'Energy Meter';
export const DEVICE_TYPE_COOLING_METER = 'Cooling Meter';

// export const DUBA_DEVICE_TYPE = 'DubaPlantScada'
//chart constant values
export const EN_GB_LOCAL_STRING = 'en-GB';
export const DAILY = 'daily';
export const WEEKLY = 'weekly';
export const MONTHLY = 'monthly';
export const YEARLY = 'yearly';
export const DATE_FORMAT_FOR_DAILY_AGGREGATION = 'DD/MM/YYYY';
export const DATE_FORMAT_FOR_MONTHLY_AGGREGATION = "MMM' YYYY";
export const DATE_FORMAT_FOR_YEARLY_AGGREGATION = 'YYYY';

// Form Errors 
export const SQL_INJECTION_REGEX = /^[a-zA-Z0-9. \-_@]+$/; // this regex only allow Only allows alphabets, numbers,@, -, _ and .
export const INVALID_CHAR_REGEX = /[~`!#$%^&*()+={}|[\]\\;':",<>?]|--/; // This regex checks for invalid characters
export const SQL_INJECTION_ERROR = 'Only allows alphabets, numbers, @, -, _ and .';
export const STRING_LENGTH_EXCEDDED_ERROR = 'Characters limit exceeded';
export const DESALINATION_PLANT = 'Desalination Plant';
export const ALPHANUMERIC_REGEX = /[^\w\s]/gi;
export const SPECIAL_CHARACTER_NOT_ALLOWED = "Special character not allowed";
export const INVALID_INPUT = 'Invalid Input';

export const NUMERIC_STRING_REGEX = /^-?\d*\.?\d+$/;
export const REQUIRED_FIELD_ERROR = 'Mandatory field is empty'
export const NOT_A_VALID_NUMBER = 'The entered value should be a valid number';
export const NOT_A_VALID_INTEGER = 'The entered value should be an integer';
export const VALUE_LIMIT_EXCEEDED = 'Value limit exceeded'

export const TYPE_INTEGER = 'INTEGER';
export const TYPE_NUMBER = 'NUMBER';

export const severityColor = {
    'Low': '#188353',
    'Medium': '#ffd806',
    'High': '#fcab53',
    'Critical': '#e36748'
}

export const meterType = {
    'water': 'Water Meter',
    'energy': 'Energy Meter',
    'cooling': 'Cooling Meter'
}

export const meterTypeOptions = [
    {
        title: 'Water Meter',
        value: 'water',
    },
    {
        title: 'Energy Meter',
        value: 'energy',
    },
    {
        title: 'Cooling Meter',
        value: 'cooling',
    }
];

export const waterCommercial = {
    [FLOW_METER_DEVICE_TYPE]: {
        title: 'Flow Meters',
        route: '/watermeters-customer/flow-meters',
        abbr: 'FM',
        deviceId: false,
        vpnName: 'albada.plant'
    }
}

//append as innerHTML inside HTML element
export const METERS_UNIT = {
    [DEVICE_TYPE_WATER_METER]: `<span>m <sup class="subsciptColor">3</sup></span>`,
    [DEVICE_TYPE_ENERGY_METER]: 'kWh',
    [DEVICE_TYPE_COOLING_METER]: 'kWh'
}

export const waterIndustrials = {
    [DEVICE_TYPE_WATER_METER]: {
        abbr: 'WM',
        deviceId: true
    },
    [DEVICE_TYPE_ENERGY_METER]: {
        abbr: 'EM',
        deviceId: true
    },
    [DUBA_DEVICE_TYPE]: {
        title: 'Duba Desalination Plant',
        route: '/watermeters-industrial/dubadesalplant',
        abbr: 'DP',
        deviceId: false,
        vpnName: 'duba.plant'
    },
    [PUMP_STATION_DEVICE_TYPE]: {
        title: 'Sharma Pump Station',
        route: '/watermeters-industrial/sharmaPumpStation',
        abbr: 'SP',
        deviceId: false,
        vpnName: 'sharma.plant'
    },
    [ALBADA_DEVICE_TYPE]: {
        title: 'Al Bada Water Recycling Plant',
        route: '/watermeters-industrial/ALBADA',
        abbr: 'ALBD',
        deviceId: false,
        vpnName: 'albada.plant'
    },
    [HPR_STAION_DEVICE_TYPE]: {
        title: 'High Point Reservoir',
        route: '/watermeters-industrial/hprstation',
        abbr: 'HPR',
        deviceId: false,
        vpnName: 'hpr.dosing'
    },
    [LOGISTICS_PARK_DEVICE_TYPE]: {
        title: 'Logistics Park',
        route: '/watermeters-industrial/logisticspark',
        abbr: 'LP',
        deviceId: false,
        vpnName: 'sharma.station'
    },
    [NEOM_BAY_AIRPORT_DEVICE_TYPE]: {
        title: 'NEOM Bay Airport',
        route: '/watermeters-industrial/neombayairport',
        abbr: 'BA',
        deviceId: false,
        vpnName: 'neom.airport'
    },
    [EXPERIENCE_CENTER_DEVICE_TYPE]: {
        title: 'Experience Center',
        route: '/watermeters-industrial/experiencecenter',
        abbr: 'EC',
        deviceId: false,
        vpnName: 'experience.center'
    },
    [FLOW_METER_DEVICE_TYPE]: {
        title: 'Flow Meters',
        route: '/watermeters-customer/flow-meters',
        abbr: 'FM',
        deviceId: false,
        vpnName: 'albada.plant'
    }
}

// Responsive Design Relates constants
export const MOBILE_PORTRAIT = '(max-width: 599px) and (orientation: portrait)';
export const MOBILE_LANDSCAPE = '(max-width: 959px) and (orientation: landscape)';
export const TABLET_PORTRAIT = '(min-width: 600px) and (max-width: 839px) and (orientation: portrait)';
export const TABLET_LANDSCAPE = '(min-width: 960px) and (max-width: 1279px) and (orientation: landscape)';
export const DESKTOP = '(min-width: 1280px)';

export const breakPoints = {
    isMobilePortrait: false,
    isMobileLandscape: false,
    isTabletPortrait: false,
    isTabletLandscape: false,
    isDesktop: false
}


// Device CSV Related constants
export const WATER_METER_TELEMETRY_COLUMNS = ['Time', 'Meter Reading', 'Volume Consumed', 'Max Flow Rate', 'Max Flow Temperature', 'Device Serial'];
export const WATER_METER_NATIVE_COLUMNS = ['Timestamp', 'Volume', 'Flow Rate', 'Flow Temperature', 'Battery Life', 'Device Serial'];
export const ENERGY_METER_TELEMETRY_COLUMNS = ['Time', 'Meter Reading', 'Max kW-Ins', 'Max avg-Power-Factor', 'Max Current-A-THD', 'Max Current-B-THD', 'Max Current-C-THD', 'Max Voltage-A-THD', 'Max Voltage-B-THD', 'Max Voltage-C-THD', 'Max Vln-an', 'Max Vln-bn', 'Max Vln-cn', 'Device Serial'];
export const ENERGY_METER_NATIVE_COLUMNS = ['Time', 'Meter Reading', 'Avg Power-factor', 'kW-Inst', 'Current-A-THD', 'Current-B-THD', 'Current-C-THD', 'Voltage-A-THD', 'Voltage-B-THD', 'Voltage-C-THD', 'Vln-an', 'Vln-bn', 'Vln-cn', 'Device Serial'];

// Device Title
export const deviceTypeTitle = {
    'Hydrus Meter': 'Water Meter',
    'Water Meter': 'Water Meter',
    'Energy Meter': 'Energy Meter',
    'DubaPlantScada': 'Duba Desalination Plant',
    'Pump Station': 'Sharma Pump Station',
    'Water Recycling Plant': 'Al Bada Water Recycling Plant',
    'Dosing Plant': 'High Point Reservoir',
    'Cooling Meter': 'Cooling Meter',
    'Flow Meter': 'Flow Meter',
    'Sharma Opcua Central Server': 'Sharma Opcua Central Server'
}

// List of industerial plants
export const industrialPlants = ['DubaPlantScada', 'Pump Station', 'Water Recycling Plant', 'Dosing Plant', 'Logistics Park', 'NEOM Bay Airport', 'Experience Center', 'Sharma Opcua Central Server'];

//HG : Temporary device identifiers, need to be removed.
export const deviceTypeIndetifiers = {
    'DubaPlantScada': '0007d959-82e2-3656-853d-3eef45d98099',
    'Pump Station': '6be85970-a428-321c-93d7-756aa5a1809a',
    'Water Recycling Plant': '552efb27-addd-31e1-8597-76549cf33e2c',
    'Dosing Plant': '791eeced-13db-3723-aa0c-e05b01648bac'
}

// opearator related variable
export const EQUAL_TO = 'Equal To';
export const NOT_EQUAL_TO = 'Not Equal To';
export const GREATER_THAN = 'Greater Than';
export const LESS_THAN = 'Less Than';
export const GREATER_THAN_EQUAL_TO = 'Greater Than Equal To';
export const LESS_THAN_EQUAL_TO = 'Less Than Equal To';
export const IN_BETWEEN = 'In Between';

export const EQUAL_TO_OPERATOR = '==';
export const NOT_EQUAL_TO_OPERATOR = '!=';
export const GREATER_THAN_OPERATOR = '>';
export const LESS_THAN_OPERATOR = '<';
export const GREATER_THAN_EQUAL_TO_OPERATOR =  '>=';
export const LESS_THAN_EQUAL_TO_OPERATOR =  '<=';

// stale-stagnant variable
export const STALE_TIME = 'Stale - Time';
export const STAGNANT_TIME = 'Stagnant - Time';
export const STAGNANT_READING = 'Stagnant - Reading';
export const STAGNANT_TIME_OPERATOR = '$STH';
export const STALE_TIME_OPERATOR = '$SH';
export const STAGNANT_READING_OPERATOR = '$SCR';

// download telemetry data - blacklisted schema columns
export const blackListedColumns = ['snr', 'rssi', 'moduleid', 'flag', 'telegramcounter', 'filename'];
export const coolingMeterblackListedColumns = ['snr', 'rssi', 'moduleid', 'telegramcounter', 'filename'];
export const restrictedUsers = []

export const DEVICE_STATUS_OPTIONS = [
    "Decommissioned",
    "Registered",
    "Provisioned",
    "Updating",
    "Fault",
    "Offline",
    "Live"
];

export const TELEMETRY = 'telemetry';
export const LABEL_TELEMETRY = 'Telemetry';
export const LABEL_METER_PROFILE = 'Meter Profile';

export const METERS = ['Water Meter', 'Energy Meter', 'Cooling Meter', 'Flow Meter'];

export const ALERT_CLASSFICATION_THRESHOLD = 'Numeric Threshold';
export const ALERT_CLASSFICATION_STALE = 'Stale Data';
export const ALERT_CLASSFICATION_STAGNANT = 'Stagnant Reading';

export const ALERT_TYPE_THRESHOLD = 'Threshold';
export const ALERT_TYPE_STALE = 'Stale';
export const ALERT_TYPE_STAGNANT = 'Stagnant';
export const UPDATE_ALERT_USER_ACTION = "Alert(s) stage updated successfully";
export const ALERT_STATUS_TYPE = [{
    key: 'Open',
    value: 'Open'
},
{
    key: 'Investigating',
    value: 'Investigating'
},
{
    key: 'Accepted and Assigned',
    value: 'Accepted and Assigned'
},
{
    key: 'Rejected',
    value: 'Rejected'
},
{
    key: 'Remediation in Progress',
    value: 'Remediation in Progress'
},
{
    key: 'Closed',
    value: 'Closed'
}];


export const THRESHOLD_CONDITIONS_HOUR_LIMIT = 72;

export const DEVICE_ID = 'deviceID';
export const MEASURABLE_LOCATION = 'measurableLocation';
export const ZONE = 'zone';
export const MANUFACTURER_NAME = 'manufacturerName';
export const SITE = 'site';

export const TRIGGER_HISTORY_BUTTON_TEXT = 'Trigger History';
export const RULE_MANAGEMENT_BUTTON_TEXT = 'Rule Mangement';
export const TAG_DEVICES = ['DubaPlantScada', 'Flow Meter', 'Sharma Opcua Central Server', 'Water Recycling Plant'];

export const CONSUMER = "Consumer";
export const INDUSTRIAL = "Industrial";

export const THRESHOLD_OPERATOR = 'thresholdOperator';
export const THRESHOLD_VALUE = 'thresholdValue';
export const THRESHOLD_ENUM_VALUE = 'thresholdEnumValue';
export const LOWER_BOUND_VALUE = 'lowerBoundValue';
export const UPPER_BOUND_VALUE = 'upperBoundValue';
export const STALE_VALUE = 'staleValue';
export const STAGNANT_TIME_VALUE = 'stagnantTimeValue';
export const STAGNANT_READING_VALUE = 'stagnantReadingValue';
export const HOLD_OFF_VALUE = 'holdOffValue';
export const CONSOLIDATION_WINDOW = 'consolidationWindow';

export const SUBSCRIPTION = 'subscription';
export const RULE_TYPE_OPERATIONAL_USER = 'operational-user';
export const RULE_TYPE_USER = 'user';

export const LEAKAGE = 'leakage';
export const NO_FLOW = 'no-flow';
export const HIGH_TEMPERATURE = 'high-temperature';

export const OPERATIONAL_USER_RULE_ERROR_MESSAGE = 'You cannot edit a rule that was created on the industrial dashboard.'
export const THRESHOLD_CONDITION_INCOMPLETE = 'Threshold Condition is Incomplete';
export const FILTER_CONDITION_INCOMPLETE = 'Filter Condition is Incomplete';
export const VALUES_NOT_IN_EXPECTED_FORMAT = 'The values are not in the expected format';
export const ATLEAST_ONE_EMAIL_RECIPIENT_REQUIRED = 'At least one Receipient is required.';
export const SAVE_RULE_SUCCESS_TOAST_MESSAGE = 'Rule changes saved successfully';
export const SAVE_RULE_ERROR_TOAST_MESSAGE = 'Error occurred while saving rule';
export const DEFAULT_ERROR_MESSAGE = 'An error occured';

export const SQL_INJECTION = 'sqlInjection';
export const THRESHOLD_INPUT = 'thresholdInput';
export const ALERT_NAMES = {
    'smart': 'AI',
    'device': 'Device',
    'threshold': 'User Defined',
}

export const COLLAPSE_BUTTON_TEXT = 'Collapse';
export const EXPAND_BUTTON_TEXT = 'Expand';

export const DERIVED_ATTRIBUTE_CONSTANTS = {
    operators: ['+', '-', '*', '/'],
    brackets: ['(', ')'],
    messages: {
        saveSuccess: 'Attribute saved successfully!',
        invalidExpression: 'This expression is invalid.',
        requiredNameField: 'Name cannot be empty.'
    },
    operatorText: {
        add: 'Add(+)',
        subtract: 'Subtract(-)',
        multiply: 'Multiply(*)',
        divide: 'Divide(/)'
    },
    text: {
        addHeading: 'Add New Attributes',
        updateHeading: 'Update Attribute',
        saveButton: 'Save Attribute',
        resetButton: ' Reset All',
        discardChanges: ' Discard Changes',
        tooltipMessage: 'Derived Attribute'
    },
    attributeTypes: {
        attribute: 'attribute',
        operator: 'operator',
        bracket: 'bracket',
        number: 'number',
        text: 'text'
    }
};

export const UNIT_CONVERSIONS = {
    'cubic meter': 'm^3',
    'volt': 'V',
    'cubic meter per hour': 'm^3/h',
    'm3/h': 'm^3/h',
    'm3/hr': 'm^3/h',
    'm': 'm',
    'degree': 'degC',
    'degree celsius': 'degC',
    'c': 'degC',
    'micro siemens per centimeter': 'uS/cm',
    'Comms/day': 'Communications/Day',
    'null': '',
    'n/a': '',
    '%': ''
}

export const CUSTOM_UNITS = {
    'ppm': {
        definition: '1 mg/L',
        offset: 0,           // No offset
        baseName: 'ppm',     // Ensures the label "ppm" remains visible
        prefixes: 'none'     // Prevents prefixes like "kilo" or "mega" from being applied
    },
    'Ppm': {
        definition: '1 mg/L',
        offset: 0,           // No offset
        baseName: 'ppm',     // Ensures the label "ppm" remains visible
        prefixes: 'none'     // Prevents prefixes like "kilo" or "mega" from being applied
    },
    'Bar': '1 bar',
}

export const EXISTING_UNITS = ['m^3/h', 'm^3', 'uS/cm', 'uS', 'mS/cm', 'mg/l'];

export const METRIC_ID_REGEX = /[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/g;
export const TAG_REGEX = /\[([^\[\]]+)\]/g;