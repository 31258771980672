<ng-container *ngIf="(total || total === 0); else CircularShimmer">
  <div class="single-chart">
    <svg viewBox="0 0 36 36" class="circular-chart">
      
      <!-- Background Circle (Gray) -->
      <path class="circle-bg"
        d="M18 2.0845
           a 15.9155 15.9155 0 0 1 0 31.831
           a 15.9155 15.9155 0 0 1 0 -31.831" />
  
      <!-- Offline Devices (Gray Path) - Starts where Live ends -->
      <path class="circle offline-circle"
        [attr.stroke-dasharray]="getDonutChartStrokeForOfflineDevices(live, total)"
        [attr.stroke-dashoffset]="getLiveDeviceStrokeOffset(live, total)"
        d="M18 2.0845
           a 15.9155 15.9155 0 0 1 0 31.831
           a 15.9155 15.9155 0 0 1 0 -31.831"
        matTooltip="Offline: {{ total - live }}"
        matTooltipClass="above" 
        matTooltipPosition="above" 
        />
  
         <!-- Live Devices (Green Path) -->
      <path class="circle live-circle"
      [attr.stroke-dasharray]="getDonutChartStrokeForLiveDevices(live, total)"
      [attr.stroke-dashoffset]="0"
      d="M18 2.0845
         a 15.9155 15.9155 0 0 1 0 31.831
         a 15.9155 15.9155 0 0 1 0 -31.831"
      matTooltip="Live: {{ live }}"
      matTooltipClass="above" 
      matTooltipPosition="above"
      />
      <!-- Live Percentage in Center -->
      <text x="19" y="19" text-anchor="middle" class="center-text">
        {{ getLiveDevicePercentage(live, total) }}%
      </text>
  
    </svg>
  </div>
</ng-container>

<ng-template #CircularShimmer>
  <app-circular-shimmer></app-circular-shimmer>
</ng-template>