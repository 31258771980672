export const filterTypeOptions = [ 'deviceID', 'uplinkReferenceKey', 'deviceType', 'status','zone','site', 'measurableLocation', 'manufacturerName', 'deviceModel', 'metadata.utilityServiceType', 'metadata.customerMeterType', 'metadata.customerType', 'metadata.buildingNumber', 'metadata.batch', 'metadata.servicePointID', 'metadata.parentMeterServicePointID', 'metadata.isBillable', 'metadata.waterDiameter'];
export const metadataFilterTypes = ['utilityServiceType', 'customerMeterType', 'customerType', 'buildingNumber', 'batch', 'servicePointID', 'parentMeterServicePointID', 'isBillable', 'waterDiameter'];

export const noDataFound = {
    title: "No data available for the selected filter",
    subtitle: "Please select a filter for which data is available"
}

export const columnnsToDisplay = ['Device Serial', 'Device Type', 'Zone', 'State', 'Missing Dates', 'Location', 'Manufacturer', 'Model No'];

export const searchFieldsArray = ["uplinkReferenceKey", "deviceType", "deviceModel", "manufacturerName", "zone", "measurableLocation", "percentageData"];

export const dataAvailabilityReportColumns = ['Date', 'Value'];

export const alertsTableColumns = ['Alert Name', 'Severity', 'Status', 'Status', 'Alert Type', 'Created On', 'Active For'];

export const meterLogsColumns = ['Event Name', 'Timestamp', 'Event Details'];

export const noDataMessage = 'No Data Available';