import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { TableAppliedFiltersComponent } from 'src/app/reusable/custom-table/table-applied-filters/table-applied-filters.component';
import { TableSearchComponent } from 'src/app/reusable/custom-table/table-search/table-search.component';
import { PaginationComponent } from 'src/app/reusable/custom-table/pagination/pagination.component';
import { Router } from '@angular/router';
import { ALERT_NAMES } from '../constant';
import { saveAs } from 'file-saver-es';
import { alertsTableColumns, noDataMessage } from 'src/app/modules/device-management/device-filter/helper';
import { ToastService } from 'src/app/services/toast/toast.service';

@Component({
  selector: 'app-alerts-table',
  templateUrl: './alerts-table.component.html',
  styleUrls: ['./alerts-table.component.css', '../../../assets/Reusable-CSS/table.scss','../../../assets/Reusable-CSS/main.scss', '../../../assets/Reusable-CSS/matTooltip.scss' ]
})
export class AlertsTableComponent implements OnInit {
  @Input() filterColumns = {};
  @Input() sortFilter = {};
  @Input() columnNames = {};
  @Input() loading = false;
  @Input() allAlerts = [];
  alertNames = ALERT_NAMES;

  @ViewChild('tableAppliedFilter', { static: false }) tableAppliedFilter: TableAppliedFiltersComponent;
  @ViewChild('tableSearchBar', { static: false }) tableSearchBar: TableSearchComponent;
  @ViewChild('tablePagination', { static: false }) tablePagination: PaginationComponent;


  constructor(private router: Router,
    private toastr: ToastService
  ) { }
  ngOnInit(): void {
  }

  public appliedFilters = [];
  public paginatedData: any = []; // this will store the paginated data based on item per page, like 10 if itemPerPage = 10, this variable used on the ui
  public filterAlerts = [];
  //  pagination related variable
  public currentPage: number = 1;
  public itemPerPage: number = 5;
  public pageSizeOption = [5,10,15,20,25]
  

  handleAppliedFilter(item) {
    if(item.selected) {
      this.appliedFilters.push(item);
    }
    else {
      const idx = this.appliedFilters.findIndex(filter => filter.name === item.name);
      this.appliedFilters.splice(idx, 1);
    }
    this.tablePagination.handleAppliedFilters();
  }

  handleRemoveFilter(item) {
    const idx = this.appliedFilters.findIndex(filter => filter.name === item.name);
    this.appliedFilters.splice(idx, 1);
    this.tablePagination.handleAppliedFilters();
  }

  sortTable(data) {
    this.tablePagination.handleSort(data);
  }

  handlePaginationDone(data) {
    this.filterAlerts = data.filteredData;
    this.paginatedData = data.paginatedData;
  }

  getStatusClass(status) {
    return status === 'Active' ? 'statusActive' : 'statusResolved';
  }

  getAlertType(alertTypes) {
    return alertTypes.split(',').map((alertType) => this.alertNames[alertType?.toLowerCase()].charAt(0).toUpperCase() + this.alertNames[alertType?.toLowerCase()].substring(1)).toString();
  }

  getBorderColor(severity) {
    if (severity === 'Low') {
      return 'borderLow';
    }
    else if (severity === 'Medium') {
      return 'borderMedium';
    }
    else if (severity === 'High') {
      return 'borderHigh';
    }
    else if (severity === 'Critical') {
      return 'borderCritical';
    }
  }

  getDotColor(severity) {
    if (severity === 'Low') {
      return 'dotLow';
    }
    else if (severity === 'Medium') {
      return 'dotMedium';
    }
    else if (severity === 'High') {
      return 'dotHigh';
    }
    else if (severity === 'Critical') {
      return 'dotCritical';
    }
  }

  getTextColor(severity) {
    if (severity === 'Low') {
      return 'textLow';
    }
    else if (severity === 'Medium') {
      return 'textMedium';
    }
    else if (severity === 'High') {
      return 'textHigh';
    }
    else if (severity === 'Critical') {
      return 'textCritical';
    }
  }

  goToManageAlert(alert: any) {
    this.router.navigate(
      [`/alert-management/alert/${alert.alertID}`]
    );
  }

  downloadAlertsData() {
    if(!this.filterAlerts?.length){
      this.toastr.error(noDataMessage);
      return;
    }
    const replacer = (key, value) => value === null ? '' : value;
    const columnsToDisplay = alertsTableColumns;
    const header = columnsToDisplay;
    let tempArr = [];
    this.filterAlerts?.forEach(row => {
      tempArr.push({ 'Alert Name': row.alertName, 'Severity': row.severity, 'Status': row.status, 'Alert Type': this.getAlertType(row.alertType), 'Created On': row.createdOn, 'Active For': row.activeFor})
    })

    // header.shift();
    let csv = tempArr.map(row => header.map(filename => JSON.stringify(row[filename], replacer)).join(','));
    csv.unshift(header.join(','));
    const csvArray = csv.join('\r\n');
    const filenamelatest = 'alerts-' + new Date().getDate() + "-" + (new Date().getMonth() + 1) + "-" + new Date().getFullYear() + " " + new Date().getHours() + ":" + new Date().getMinutes() + '.csv';

    const blob = new Blob([csvArray], { 'type': 'text/csv' });
    saveAs(blob, filenamelatest);
    return;
  }
}
