<!--The content below is only a placeholder and can be replaced.-->
<!-- <div class="sidebar">sidebar</sidebar> -->
<div *ngIf="goheader">
    <app-header *ngIf="showHeader"></app-header>
    <app-per-login-header *ngIf="showHeaderPreLogin"></app-per-login-header>

    <div class="row">
        <div class="col-lg-12">
            <div class="page">
                <app-sidebar *ngIf="showHeader"></app-sidebar>
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</div>