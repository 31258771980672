import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { IEntityHeader, lineOfBusiness } from './entity.interface';


@Injectable({
  providedIn: 'root'
})
export class RegistrationService {

  constructor(private apiService: ApiService) { }

  public getDevices(payload) {
    const { deviceType } = payload;
    const entity: IEntityHeader = {
      deviceType,
      lineOfBusiness: lineOfBusiness.customer,
      // site: site,
      sector: deviceType?.split(' ')[0],
    }
    return this.apiService.post(`/registration/devices`, payload, entity);
  }

  public getDeviceCount(filter) {
    const {deviceType} = filter;
    const entity: IEntityHeader = {
      deviceType: deviceType,
      lineOfBusiness: lineOfBusiness.customer,
      // site: site,
      sector: deviceType?.split(' ')[0],
    }
    return this.apiService.post(`/registration/get-device-count`,filter, entity);
  }

  public getDeviceById(deviceID: any, entity?: IEntityHeader) {
    return this.apiService.get(`/registration/device-by-id?deviceID=${deviceID}`, entity);
  }

  public realTimeData(payload: any) {
    return this.apiService.post(`/registration/real-time-data`, payload);
  }

  public getParameterValue(payload: any) {
    return this.apiService.post(`/registration/parameter-value`, payload);
  }

  public getDeviceList(payload: any) {
    return this.apiService.post(`/registration/list-devices`, payload);
  }

  public saveDeviceRange(payload) {
    const entity: IEntityHeader = {
      deviceType: payload[0].deviceType,
      lineOfBusiness: lineOfBusiness.industrial,
      sector: 'Water',
    }
    return this.apiService.put(`/registration/device`, payload, entity);
  }

  public fetchRegistrationMetadata(deviceID, entity: IEntityHeader) {
    return this.apiService.get(`/registration/registration-metadata?deviceID=${deviceID}`, entity);
  }

  public getTagDataForStatusValues() {
    return this.apiService.get(`/registration/device-with-enum`);
  }

  public GetFilterTypeAndCount(meterType: string) {
    return this.apiService.get(`/registration/filter-type-and-count?meterType=${meterType}`); 
  }

  public getDeviceInformation(payload) {
    return this.apiService.post(`/registration/get-device-information`, payload); 

  }

  getDeviceByDescription(payload: any) {
    return this.apiService.post('/registration/get-device-by-description', payload);
  }

  // getStatusCountByDeviceType(payload) {
  //   return this.apiService.post('/registration/get-status-count-by-device-type', payload);
  // }
  productStatusCount(payload:string[]) {
    return this.apiService.post('/registration/product-count-by-status', payload);
  }

  public getSiteZonesByDeviceType(deviceType: string) {
    return this.apiService.get(`/registration/get-sites-zones-by-device-type?deviceType=${deviceType}`);
  }

  public getZoneBySite(productName) {
    const payload = {productName}
    return this.apiService.post(`/registration/zones-by-site?productName`, payload);
  }

  getDeviceByMetricIds(payload: any) {
    return this.apiService.post('/registration/get-device-by-metricIDs', payload);
  }

  registerDerivedAttribute(payload: any) {
    return this.apiService.post('/registration/register-derived-attribute', payload);
  }

  getDerivedAttributes(deviceId: string) {
    return this.apiService.get(`/registration/get-derived-attributes?deviceId=${deviceId}`);
  }

  updateDerivedAttribute(payload: any) {
    return this.apiService.post('/registration/update-derived-attribute', payload);
  }

  deleteDerivedAttribute(payload: any) {
    return this.apiService.post('/registration/delete-derived-attribute', payload);
  }

  getDevicesLocationDetails(payload, username) {
    return this.apiService.post(`/registration/get-devices-location-details?username=${username}`, payload);
  }

  // siteWiseDataByDeviceType(payload:string[]) {
  //   return this.apiService.post('/registration/site-wise-data-by-device-type', payload);
  // }

  siteWiseDataByProductName(payload: string[]) {
    return this.apiService.post('/registration/site-wise-data-by-product-name', payload);
  }
  getFacilitiesParametersData(payload) {
    return this.apiService.post('/registration/facilities-parameters-data', payload);
  }

  getZoneWiseMeterCount(payload) {
    return this.apiService.post(`/registration/get-zonewise-meter-count`, payload);
  }

  getZoneWiseGatewayCount(payload) {
    return this.apiService.post(`/registration/get-zonewise-gateway-count`, payload);
  }

  getDeviceFilterOptions(payload: any) {
    return this.apiService.post(`/registration/get-device-filter`, payload);
  }

  getFacilities() {
    return this.apiService.get(`/registration/facilities-details`);
  }

  getDeviceDetailsBySiteAndProductName(payload) {
    return this.apiService.post(`/registration/devices-details-by-site-and-product-name`, payload);
  }
  getGatewayDetailsBySiteAndProductName(payload) {
    return this.apiService.post(`/registration/gateway-details-by-site-and-product-name`, payload);
  }
  getConsumptionUnit() {
    return this.apiService.get(`/registration/consumption-unit`);
  }
  productDetailsBySystemType() {
    return this.apiService.get(`/registration/product-details-by-system-type`);
  }
  getDashboards() {
    return this.apiService.get(`/registration/dashboards`);
  }
  getProductNames() {
    return this.apiService.get(`/registration/product-names`);
  }
  getDeviceByProductNameParameters(payload: any) {
    return this.apiService.post('/registration/device-by-product-name-parameters', payload);
  }
}

