import * as moment from "moment";
import { FLOW_METER_DEVICE_TYPE } from "src/app/reusable/constant";
import { SYSTEM_TYPE_DEVICES, SYSTEM_TYPE_SCADA, SYSTEM_TYPE_TRANSMISSIO_POINT, SYSTEM_TYPE_GATEWAYS } from "src/app/reusable/siteConstants";
export const getTemplatesBySystemType = (meterOrFacility, data) =>  {
    let iconBg = data.status == 'Live' ? 'esri-tooltip-icon-bg-live': 'esri-tooltip-icon-bg-offline';
    let iconBorder = data.status == 'Live' ? 'esri-tooltip-icon-border-live': 'esri-tooltip-icon-border-offline';
    const priductNameVerseIconInfo = JSON.parse(localStorage.getItem('priductNameVerseIconInfo'));

    if(data?.activeAlerts > 0) {
      iconBg = 'esri-tooltip-icon-bg-active-alert';
      iconBorder = 'esri-tooltip-icon-border-active-alert';
    }
    if(meterOrFacility === SYSTEM_TYPE_DEVICES) {
        const {uplinkReferenceKey, domain, status, productName, deviceManufacturer, consumptionFor30Days,consumptionUnit, percentageData, measurableLocation, dmsLatitude, dmsLongitude, activeAlerts} = data;
        return `
          <div class=${iconBg}>
            <div class="collingMeterIconContent">
                <div class=${iconBorder}>
                  <span class="material-symbols-outlined">${priductNameVerseIconInfo[productName]}</span>
                </div>
                <div class="collingContent">
                  <a href=${domain}  target="_blank">${uplinkReferenceKey}</a>
                  <span>${productName}</span>
                </div>
            </div>
            <div class="manufacturer-blk">
              <div class="tableBlk">
                <span>Manufacturer</span>
                <span class="bold">${deviceManufacturer}</span>
              </div>
              
               <div class="tableBlk">
                <div><span>Consumption</span> <span style="color:red">*</span></div>
                <span class="bold"> ${getValidValue(consumptionFor30Days?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }))} ${consumptionUnit || ''}</span>
              </div>

              <div class="tableBlk">
                <div><span>Data Availability Score</span> <span style="color:red">*</span></div>
                <span class="bold">${ getValidValue(percentageData?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }))} %</span>
              </div>

              <div class="tableBlk">
                <span>Location</span>
                <span class="bold">${measurableLocation}</span>
              </div>

              <div class="tableBlk">
                <span>Active Alerts</span>
                <span class="bold">${getValidValue(activeAlerts)}</span>
              </div>

                <div>
                  <span style="color:red">*</span> <span>Last 30 days data </span>
              </div>
            </div>
          </div>
        `
    }
    else if(meterOrFacility === FLOW_METER_DEVICE_TYPE) {
        const {domain, description, lastDataReceivedOn, volume, flow, status, dmsLatitude, dmsLongitude, activeAlerts} = data;
        return `
        <div class=${iconBg}>
          <div class="collingMeterIconContent">
              <div class=${iconBorder}>
                <span class="material-symbols-outlined">mode_fan</span>
              </div>
              <div class="collingContent">
                <a href=${domain}  target="_blank">${description}</a>
                <span>${meterOrFacility}</span>
              </div>
          </div>
          <div class="manufacturer-blk">
            <div class="tableBlk">
              <span>Volume</span>
              <span class="bold">${getValidValue(volume)}</span>
            </div>

            <div class="tableBlk">
              <span>Flow</span>
              <span class="bold">${getValidValue(flow)}</span>
            </div>
            <div class="tableBlk">
              <span>Last Data Received On</span>
              <span class="bold">${lastDataReceivedOn}</span>
            </div>
          </div>
        </div>
      `
    }
    else if(meterOrFacility === SYSTEM_TYPE_SCADA) {
        const {domain, description, Conductivity, Turbidity, pH, lastDataReceivedOn, status, dmsLatitude, dmsLongitude, activeAlerts} = data;
        return `
          <div class=${iconBg}>
            <div class="collingMeterIconContent">
                <div class=${iconBorder}>
                  <span class="material-symbols-outlined">humidity_low</span>
                </div>
                <div class="collingContent">
                  <a href=${domain}  target="_blank">${description}</a>
                  <span>${meterOrFacility}</span>
                </div>
            </div>
            <div class="manufacturer-blk">
              <div class="tableBlk">
                <span>Conductivity</span>
                <span class="bold">${getValidValue(Conductivity)}</span>
              </div>

              <div class="tableBlk">
                <span>Turbidity</span>
                <span class="bold">${getValidValue(Turbidity)}</span>
              </div>
              <div class="tableBlk">
                <span>pH</span>
                <span class="bold">${getValidValue(pH)}</span>
              </div>

              <div class="tableBlk">
                <span>Last Data Received On</span>
                <span class="bold">${lastDataReceivedOn}</span>
              </div>
              
              <div class="tableBlk">
                <span>Active Alerts</span>
                <span class="bold">${getValidValue(activeAlerts)}</span>
              </div>
            </div>
          </div>
        `
    }
    else if(meterOrFacility === SYSTEM_TYPE_TRANSMISSIO_POINT) {
        let {domain, description, status, lastDataReceivedOn, dmsLatitude, dmsLongitude, activeAlerts} = data;
        const site = domain.split("?site=")[1];
        if(site) {
          localStorage.setItem('industrialDashboardSite',site);
          domain = domain.split("?")[0];
        }
        return `
        <div class=${iconBg}>
          <div class="collingMeterIconContent">
              <div class=${iconBorder}>
                <span class="material-symbols-outlined">humidity_low</span>
              </div>
              <div class="collingContent">
                <a href=${domain}  target="_blank">${description}</a>
                <span>${meterOrFacility}</span>
              </div>
          </div>
          <div class="manufacturer-blk">
            <div class="tableBlk">
              <span>Downstream Pressure</span>
              <span class="bold"> ${getValidValue(data['Downstream Pressure'])}</span>
            </div>

            <div class="tableBlk">
              <span>Upstream Pressure</span>
              <span class="bold">${getValidValue(data['Upstream Pressure'])}</span>
            </div>
            <div class="tableBlk">
              <span>Flow Rate</span>
              <span class="bold">${getValidValue(data['Flow Rate'] ? data['Flow Rate'] : '-')}</span>
            </div>

            <div class="tableBlk">
              <span>Last Data Received On</span>
              <span class="bold">${lastDataReceivedOn}</span>
            </div>
            <div class="tableBlk">
              <span>Active Alerts</span>
              <span class="bold">${getValidValue(activeAlerts)}</span>
            </div>
          </div>
        </div>
      `
    }

    if(meterOrFacility === SYSTEM_TYPE_GATEWAYS) {
      const {uplinkReferenceKey, domain, status, productName, deviceManufacturer, consumptionFor30Days,consumptionUnit, percentageData, measurableLocation, dmsLatitude, dmsLongitude, activeAlerts} = data;
      return `
      <div class=${iconBg}>
        <div class="collingMeterIconContent">
            <div class=${iconBorder}>
              <span class="material-symbols-outlined">humidity_low</span>
            </div>
            <div class="collingContent">
              <a href=${domain}  target="_blank">${uplinkReferenceKey}</a>
              <span>${productName}</span>
            </div>
        </div>
        <div class="manufacturer-blk">
          <div class="tableBlk">
            <span>Manufacturer</span>
            <span class="bold">${deviceManufacturer}</span>
          </div>
          
           <div class="tableBlk">
            <div><span>Consumption</span> <span style="color:red">*</span></div>
            <span class="bold"> ${
              getValidValue(consumptionFor30Days?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }))} ${consumptionUnit}}</span>
          </div>

          <div class="tableBlk">
            <div><span>Data Availability Score</span> <span style="color:red">*</span></div>
            <span class="bold">${getValidValue(percentageData?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }))} %</span>
          </div>
         
          <div class="tableBlk">
            <span>Location</span>
            <span class="bold">${measurableLocation}</span>
          </div>

            <div>
                <span style="color:red">*</span> <span>Last 30 days data </span>
            </div>
        </div>
      </div>
    `
  }
}


export const createCustomPaginationForPopupHeader = (popupHeader, current, totalCount) => {
    // Clear the existing content (if any)
    while (popupHeader.firstChild) {
      popupHeader.removeChild(popupHeader.firstChild);
    }
  
    // Create a DocumentFragment to assemble the new structure
    const fragment = document.createDocumentFragment();
  
    // Create the custom div for the new content
    const customDiv = document.createElement('div');
    customDiv.style.display = 'flex';
    customDiv.style.alignItems = 'center';
    customDiv.style.justifyContent = 'space-between';
    customDiv.style.width = '100%';
  
    // Create the left-side zoom section
    const zoomDivContainer = document.createElement('div');
    const zoomDiv = document.createElement('div');
    zoomDiv.classList.add('material-symbols-outlined');
    zoomDiv.id = 'custom-esri-zoom';
    zoomDiv.textContent = 'zoom_in';
  
    const zoomSpan = document.createElement('span');
    zoomSpan.textContent = ' Zoom to';
  
    zoomDiv.appendChild(zoomSpan);
    zoomDivContainer.appendChild(zoomDiv);
  
    // Create the pagination section
    const paginationDivContainer = document.createElement('div');
    const paginationInnerDiv = document.createElement('div');
    paginationInnerDiv.style.display = 'flex';
    paginationInnerDiv.style.alignItems = 'center';
    paginationInnerDiv.style.justifyContent = 'space-between';
    paginationInnerDiv.style.width = '100%';
  
    // Previous button
    const prevButton = document.createElement('div');
    prevButton.classList.add('material-symbols-outlined');
    prevButton.id = 'esri-pagination-prev';
    prevButton.style.cursor = 'pointer';
    prevButton.style.marginRight = '10px';
    prevButton.textContent = 'chevron_backward';
  
    // Pagination text
    const paginationText = document.createElement('div');
    paginationText.id = 'esri-pagination-text';
    paginationText.textContent = `${current} of ${totalCount}`;
  
    // Next button
    const nextButton = document.createElement('div');
    nextButton.classList.add('material-symbols-outlined');
    nextButton.id = 'esri-pagination-next';
    nextButton.style.cursor = 'pointer';
    nextButton.style.marginLeft = '10px';
    nextButton.textContent = 'chevron_forward';
  
    // Append pagination elements
    paginationInnerDiv.appendChild(prevButton);
    paginationInnerDiv.appendChild(paginationText);
    paginationInnerDiv.appendChild(nextButton);
  
    paginationDivContainer.appendChild(paginationInnerDiv);
  
    // Append both sections to the custom div
    customDiv.appendChild(zoomDivContainer);
    customDiv.appendChild(paginationDivContainer);
  
    // Append customDiv to the DocumentFragment
    fragment.appendChild(customDiv);
  
    // Finally, append the fragment to the popupHeader
    popupHeader.appendChild(fragment);
}

const getValidValue = (value) =>  {
  return (value || value === 0 ) ? value : '-'
}