import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, QueryList, SimpleChanges, ViewChildren } from '@angular/core';;
import { IToken } from '../common.interfaces';
@Component({
  selector: 'app-token-dropdown',
  templateUrl: './token-dropdown.component.html',
  styleUrls: ['./token-dropdown.component.css']
})
export class TokenDropdownComponent implements OnInit, OnChanges {

  @Input() attributes: IToken[] = [];
  @Input() activeIndex: number = 0; // Active item index
  @Output() attributeSelected = new EventEmitter<IToken>();

  @ViewChildren('attributeItem') attributeItems!: QueryList<ElementRef>;

  ngOnInit(): void {
      
  }

  // Emit the selected attribute when clicked
  selectAttribute(attribute: IToken) {
    this.attributeSelected.emit(attribute);
  }

  // Detect changes in activeIndex and scroll the item into view
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['activeIndex'] && !changes['activeIndex'].firstChange) {
      this.scrollToActiveItem();
    }
  }

  // Scroll to the currently active item
  scrollToActiveItem(): void {
    const itemsArray = this.attributeItems.toArray();
    const activeElement = itemsArray[this.activeIndex]?.nativeElement;
    if (activeElement) {
      activeElement.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
  }
}
