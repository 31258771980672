import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { IEntityHeader, lineOfBusiness } from './entity.interface';
import { DEVICE_TYPE_PARAMETER_ENERGY, DEVICE_TYPE_PARAMETER_WATER, DEVICE_TYPE_WATER_METER } from '../constant';
import { getLoginUser } from '../user-util';

@Injectable({
  providedIn: 'root'
})

export class AlertsManagementService {

  constructor(private apiService: ApiService) { }

  leakageAlertsData(payload: any) {
    const entity: IEntityHeader = {
      deviceType: DEVICE_TYPE_WATER_METER,
      lineOfBusiness: lineOfBusiness.customer,
      sector: 'Water'
    }
    return this.apiService.post(`/alerts-management/leakage-alerts-data`, payload, entity);
  }

  leakageAlertsDataAggregate(payload: any, aggregationType: string) {
    const entity: IEntityHeader = {
      deviceType: DEVICE_TYPE_WATER_METER,
      lineOfBusiness: lineOfBusiness.customer,
      sector: 'Water'
    }
    return this.apiService.post(`/alerts-management/leakage-alerts-data-aggregate?aggregationType=${aggregationType}`, payload, entity);
  }

  alertDataCount(data: any) {
    data['username'] = getLoginUser()
    return this.apiService.post('/alerts-management/alert-count', data);
  }

  getAlertDataCountInRange(data: any) {
    return this.apiService.post('/alerts-management/alert-count', data);
  }

  getAlertFilterCount(deviceType) {
    const entity: IEntityHeader = {
      deviceType: deviceType,
      lineOfBusiness: lineOfBusiness.customer,
      sector: this.getSectorByDeviceType(deviceType)
    }
    const requestBody = {};
    requestBody['meterType'] = deviceType === DEVICE_TYPE_WATER_METER ? DEVICE_TYPE_PARAMETER_WATER : DEVICE_TYPE_PARAMETER_ENERGY;
    return this.apiService.post('/alerts-management/get-alert-filter-count', requestBody, entity);
  }

  getAllAlerts(data: any) {
    data['username'] = getLoginUser()
    return this.apiService.post(`/alerts-management/get-all-alerts`, data);
  }

  getAllAlertsPaginated(data: any) {
    data['username'] = getLoginUser()
    return this.apiService.post(`/alerts-management/all-alerts`, data);
  }

  getAlertFilter(data:any){
    data['username'] = getLoginUser()
    return this.apiService.post(`/alerts-management/get-alert-filter`, data);
  }


  getAlertById(alertID: any) {
    return this.apiService.get(`/alerts-management/alert-by-id?alertID=${alertID}`);
  }

  acknowledgeAlert(data: any) {
    return this.apiService.post(`/alerts-management/acknowledge-alert?alertID=${data.alertID}`, data.requestBody);
  }

  getAlertStatsInPeriod() {
    return this.apiService.get(`/alerts-management/get-alert-stats`);
  }

  public getSectorByDeviceType(deviceType) {
    return deviceType === DEVICE_TYPE_WATER_METER ? 'Water' : 'Energy';
  }

  getAlertsByDeviceId(deviceID: string, deviceType:string) {
    const entity: IEntityHeader = {
      deviceType: deviceType,
      lineOfBusiness: lineOfBusiness.customer,
      sector: this.getSectorByDeviceType(deviceType)
    }
    return new Promise((resolve, reject) => {
      this.apiService.post('/alerts-management/get-alerts-by-device-id', { deviceIds: [deviceID] }, entity).subscribe((response) => {
        resolve(response);
      }, (error: any) => {
        reject(error);
      });
    });
  }

  fetchNotifications() {
    return new Promise((resolve, reject) => {
      this.apiService.get('/alerts-management/fetchNotifications').subscribe((response) => {
        resolve(response);
      }, (error: any) => {
        reject(error);
      });
    });
  }

  markNotificationStatus(alertIds: string[], status: string, email: string) {
    return new Promise((resolve, reject) => {
      this.apiService.post('/alerts-management/markNotificationStatus', {alertIds, status, email}).subscribe((response) => {
        resolve(response);
      }, (error: any) => {
        reject(error);
      });
    });
  }

  getAlertByRuleId(data: any, ruleID: string) {
    return this.apiService.post(`/alerts-management/alert-by-ruleid?ruleID=${ruleID}`, data);
  }

  getAlertsPlotIndustrial(data: any) {
    return this.apiService.post(`/alerts-management/get-alerts-plot-industrial`, data);
  }

  getAlertsRules(deviceID: string, data: any) {
    return this.apiService.post(`/alerts-management/get-alerts-rules?deviceID=${deviceID}`, data);
  }

  unsubscribeAlertsRules(data: any) {
    return this.apiService.post(`/alerts-management/unsubscribe-alerts-rules`, data);
  }

  getZoneWiseAlertsCount(payload) {
    return this.apiService.post(`/alerts-management/get-zonewise-alert-count`, payload);
  }

  getActiveAlertCountByDeviceType(payload) {
    return this.apiService.post(`/alerts-management/active-alert-count-by-device-type`, payload);
  }
  getPotentialWaterSavings(payload){
    return this.apiService.post(`/alerts-management/potential-saving`, payload);
  }

  getAlertDetectionTime(payload){
    return this.apiService.post(`/alerts-management/detection-time`, payload);
  }

  // Leakage Water measure
  getTotalLeakage(payload){
    return this.apiService.post(`/alerts-management/total-leakage`, payload);
  }

  //Alert Severity Segregation Donut Chart
  getSeverityCount(payload){
    return this.apiService.post(`/alerts-management/severity-count`, payload);
  }

  getLeakageVsPotentialSaving(payload){
    return this.apiService.post(`/alerts-management/leakage-vs-potential-saving`, payload);
  }

  getLeakageDetectionTimeComparison(payload){
    return this.apiService.post(`/alerts-management/leakage-detection-time-comparsion`, payload);
  }

  getMissedAlertCount(payload){
    return this.apiService.post(`/alerts-management/missed-alert-count`, payload);
  }

  getActiveAlertCountByDeviceID(payload){
    return this.apiService.post(`/alerts-management/active-alert-count-by-deviceID`, payload)
  }
  
  
  getAlertCountByStatus(payload){
    return this.apiService.post(`/alerts-management/alert-count-by-status`, payload);
  }
  getAlertCountBySeverity(payload){
    return this.apiService.post(`/alerts-management/alert-count-by-severity`, payload);
  }
  getAlertCountByAlertType(payload){
    return this.apiService.post(`/alerts-management/alert-count-by-alertType`, payload);
  }
  getAlertCountByUserAction(payload){
    return this.apiService.post(`/alerts-management/alert-count-by-userAction`, payload);
  }
  getAlertCountByFacility(payload){
    return this.apiService.post(`/alerts-management/alert-count-by-facility`, payload);
  }
  getAverageResponseTimeBySeverity(payload){
    return this.apiService.post(`/alerts-management/average-response-time-by-severity`, payload);
  }
  updateUserAction(payload){
    return this.apiService.post(`/alerts-management/update-userAction`, payload);
  }
  
}
