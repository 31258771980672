
import { Clipboard } from '@angular/cdk/clipboard';
import { ToastService } from '../services/toast/toast.service';
import { Injectable } from '@angular/core';
import { ENCRYPTION_KEY } from 'src/app/reusable/constant';
import * as CryptoJS from 'crypto-js';
import markdownit from 'markdown-it';

@Injectable({
    providedIn: 'root'
})
export class Helper {
    constructor(private clipboard: Clipboard,
        public toastrService: ToastService) {

    }
    copyData(text: string, message: string = 'Copy successfully') {
        this.clipboard.copy(text);
        this.toastrService.success(message)
    }

    encryptPassword(password: string) {
        return CryptoJS.AES.encrypt(password, ENCRYPTION_KEY).toString();
    }

    decryptPassword(password: string) {
        const bytes = CryptoJS.AES.decrypt(password, ENCRYPTION_KEY);
        const decryptedPassword = bytes.toString(CryptoJS.enc.Utf8);
        return decryptedPassword;
    }

    renderText(text: string){
        const markdown = new markdownit();
        return markdown.render(text);
    }
}