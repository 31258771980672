import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[removeFocus]'
})
export class RemoveFocusDirective {
  @HostListener('click', ['$event.target'])
  onClick(target: HTMLElement) {
    target.blur();
  }
}