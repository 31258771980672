import { Injectable } from '@angular/core';
import * as moment from 'moment';
// import { deviceTypeDashboardRoute } from 'src/app/reusable/route-constants';
import { dashboardUrlForDescription } from 'src/app/reusable/route-constants';
import { getTemplatesBySystemType } from 'src/app/services/arcgis-map/devices-fields-template-renderer/device-templates';
import { loadModules } from "esri-loader";
import { SYSTEM_TYPE_DEVICES, SYSTEM_TYPE_SCADA, SYSTEM_TYPE_TRANSMISSIO_POINT, SYSTEM_TYPE_GATEWAYS } from 'src/app/reusable/siteConstants';
import { DecimalPipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class ArcgisMapsDeviceConfigurationsService {

  constructor(
    private decimalPipe: DecimalPipe
  ) { }
  createFields(systemType) {
    if(systemType === SYSTEM_TYPE_DEVICES) {
      return  [
        { name: "oid",alias: "Object ID",type: "oid"},
        { name: 'deviceID ', alias: 'Device ID', type: 'string' },
        { name: 'uplinkReferenceKey', alias: 'Uplink Reference Key', type: 'string' },
        { name: 'deviceType', alias: 'Device Type', type: 'string' },
        { name: 'status', alias: 'Status', type: 'string' },
        { name: 'measurableLocation', alias: 'Location', type: 'string' },
        { name: 'percentageData', alias: 'Percentage Data', type: 'string' },
        { name: 'consumptionFor30Days', alias: 'Consumption', type: 'double' },
        { name: 'deviceManufacturer', alias: 'Device Manufacturer', type: 'string' },
        { name: 'dmsLongitude', alias: 'DMS Longitude', type: 'string' },
        { name: 'dmsLatitude', alias: 'DMS Latitude', type: 'string' },
        { name: 'domain', alias: 'Domain', type: 'string' },
        { name: 'description', alias: 'Description', type: 'string' },
        {name : 'activeAlerts', alias: 'Active Alerts', type: 'integer'},
        {name : 'pointColor', alias: 'Point Color', type: 'string'},
        { name: 'flow', alias: 'Flow', type: 'string' },
        { name: 'volume', alias: 'Volume', type: 'string' },
        { name: 'lastDataReceivedOn', alias: 'Last Data Recieved On', type: 'string' },
        { name: 'consumptionUnit', alias: 'Consumption Unit', type: 'string' }
      ]
    }
    else if(systemType === SYSTEM_TYPE_SCADA) {
      return [
        { name: "oid",alias: "Object ID",type: "oid"},
        { name: 'deviceType', alias: 'Device Type', type: 'string' },
        { name: 'deviceID', alias: 'Device ID', type: 'string' },
        { name: 'uplinkReferenceKey', alias: 'Uplink Reference Key', type: 'string' },
        { name: 'status', alias: 'Status', type: 'string' },
        { name: 'dmsLongitude', alias: 'DMS Longitude', type: 'string' },
        { name: 'dmsLatitude', alias: 'DMS Latitude', type: 'string' },
        { name: 'lastDataReceivedOn', alias: 'Last Data Recieved On', type: 'string' },
        { name: 'Conductivity', alias: 'Conductivity', type: 'string' },
        { name: 'Turbidity', alias: 'Turbidity', type: 'string' },
        { name: 'domain', alias: 'Domain', type: 'string' },
        { name: 'description', alias: 'Description', type: 'string' },
        {name : 'pH', alias: 'pH', type: 'string'},
        {name : 'activeAlerts', alias: 'Active Alerts', type: 'integer'},
        {name : 'pointColor', alias: 'Point Color', type: 'string'}
      ]
    }
    else if(systemType === SYSTEM_TYPE_TRANSMISSIO_POINT){
      return [
        { name: "oid",alias: "Object ID",type: "oid"},
        { name: 'deviceType', alias: 'Device Type', type: 'string' },
        { name: 'deviceID', alias: 'Device ID', type: 'string' },
        { name: 'uplinkReferenceKey', alias: 'Uplink Reference Key', type: 'string' },
        { name: 'status', alias: 'Status', type: 'string' },
        { name: 'dmsLongitude', alias: 'DMS Longitude', type: 'string' },
        { name: 'dmsLatitude', alias: 'DMS Latitude', type: 'string' },
        { name: 'lastDataReceivedOn', alias: 'Last Data Recieved On', type: 'string' },
        { name: 'Downstream Pressure', alias: 'Downstream Pressure', type: 'string' },
        { name: 'Upstream Pressure', alias: 'Upstream Pressure', type: 'string' },
        { name: 'Flow Rate', alias: 'Flow Rate', type: 'string' },
        { name: 'domain', alias: 'Domain', type: 'string' },
        { name: 'description', alias: 'Description', type: 'string' },
        {name : 'activeAlerts', alias: 'Active Alerts', type: 'integer'},
        {name : 'pointColor', alias: 'Point Color', type: 'string'}
      ]
    }
    else if(systemType === SYSTEM_TYPE_GATEWAYS) {
      return [
        { name: "oid",alias: "Object ID",type: "oid"},
        { name: 'deviceID ', alias: 'Device ID', type: 'string' },
        { name: 'uplinkReferenceKey', alias: 'Uplink Reference Key', type: 'string' },
        { name: 'deviceType', alias: 'Device Type', type: 'string' },
        { name: 'status', alias: 'Status', type: 'string' },
        { name: 'measurableLocation', alias: 'Location', type: 'string' },
        { name: 'deviceManufacturer', alias: 'Device Manufacturer', type: 'string' },
        { name: 'dmsLongitude', alias: 'DMS Longitude', type: 'string' },
        { name: 'dmsLatitude', alias: 'DMS Latitude', type: 'string' },
        { name: 'domain', alias: 'Domain', type: 'string' },
        {name : 'pointColor', alias: 'Point Color', type: 'string'}
      ]
    }
  }

  createAttributes(data, groupedDevices,systemType?) {
    const key = `${data.location?.longitude},${data.location?.latitude}`;
    const singleOrMultipleDevicesOnPoint = groupedDevices[key] > 1 ? 'multiple' : 'single';
    const deviceSystemType = data.systemType || systemType;
    const additionalAttriutes = ['dmsLongitude', 'dmsLatitude', 'pointColor'];
    if(deviceSystemType === SYSTEM_TYPE_DEVICES || deviceSystemType===SYSTEM_TYPE_GATEWAYS) {
      return {
        ...data,
        percentageData: data.percentageData?.toFixed(2).toString() || '',
        domain: `${window.location.origin}${dashboardUrlForDescription[data.productName]}/${data.uplinkReferenceKey.includes('FLWM') ?'': data.deviceID}`,
        ...this.getAdditionalAttributes(additionalAttriutes, data, singleOrMultipleDevicesOnPoint)
      }
    }
    else if(deviceSystemType === SYSTEM_TYPE_SCADA) {
      return {
        ...data,
        domain: `${window.location.origin}${dashboardUrlForDescription[data.description]}`,
        ...this.getAdditionalAttributes([...additionalAttriutes, 'lastDataReceivedOn'], data, singleOrMultipleDevicesOnPoint)
      }
    }
    else if(deviceSystemType === SYSTEM_TYPE_TRANSMISSIO_POINT) {
      return {
        ...data,
        domain: `${window.location.origin}${dashboardUrlForDescription[data.description]}`,
        ...this.getAdditionalAttributes([...additionalAttriutes, 'lastDataReceivedOn'], data, singleOrMultipleDevicesOnPoint)
      }
    }
  }

  getAdditionalAttributes = (attributes, data, isMultipleDeviceOnAPoint) =>  {
    const expressions = {
      lastDataReceivedOn: moment(data.lastDataReceivedOn).format('Do MMMM YYYY, h:mm:ss A') || '',
      dmsLongitude: this.decimalToDMS(data.location.longitude) || '',
      dmsLatitude: this.decimalToDMS(data.location.latitude) || '',
      pointColor: `${isMultipleDeviceOnAPoint}-${data.activeAlerts > 0 ? 'red' : (data.status === 'Live' ? 'green' : 'gray')}` || '',
      percentageData: data.percentageData?.toFixed(2).toString() || '',

    }
    const res = {};
    attributes.forEach(attribute => {
      res[attribute] = expressions[attribute];
    });
    return res;
  }

  async createGraphics(devicesData, systemType) {
    const [Point, Graphic] = await loadModules([
      "esri/geometry/Point",
      "esri/Graphic"
    ]);
    const groupedDevices = this.groupTheDeviceCountOnLatLong(devicesData);
    const graphics = [];
    devicesData.forEach(device => {
      if(device.location && this.isItValidCoordinates(device.location)) {
        const point = new Point({
          longitude: device.location.longitude,
          latitude: device.location.latitude,  
        });
  
        const graphic = new Graphic({
          geometry: point,
          attributes: this.createAttributes(device, groupedDevices, systemType)
        });
        graphics.push(graphic);
      }
    });
    return graphics;
  }
  decimalToDMS(decimal) {
    const degrees = Math.floor(decimal);
    const decimalMinutes = (decimal - degrees) * 60;
    const minutes = Math.floor(decimalMinutes);
    const seconds = Math.round((decimalMinutes - minutes) * 60);

    return `${degrees}° ${minutes}\´${seconds}`
  }

  getLabelClass(LabelClass, TextSymbol) {
    return new LabelClass({
      labelExpressionInfo: {
        expression: "$feature.description"
      },
      symbol: new TextSymbol({
        color: "black",
        haloColor: "white",
        haloSize: "1px",
        font: {
          size: 12
        },
       
      }),
      labelPlacement: "center-right",
      deconflictionStrategy: "none",
      allowOverlapping: true
    })
  }

  getRenderer(UniqueValueRenderer, SimpleMarkerSymbol) {
    return  new UniqueValueRenderer({
      field: "pointColor",
      uniqueValueInfos: [
        {
          value: "single-green",
          symbol: new SimpleMarkerSymbol({
            color:'#4dc254', //Green color
            size: '12px',
            style: 'circle',
          }),
          label: "Live"
        },
        {
          value: "single-gray",
          symbol: new SimpleMarkerSymbol({
            color: '#AAAAAA', //Gray Color
            haloColor: "white",
            size: '12px',
            style: 'circle',
          }),
          label: "Offline"
        },
        // {
        //   value: "single-gray",
        //   symbol: {
        //     type: "picture-marker", // Use a picture-marker for the icon
        //     url: this.getConcentricCircleIcon('#4dc254'), // green color 
        //     width: "20px",
        //     height: "20px"
        //   },
        //   label: "Offline"
        // },
        {
          value: "single-red",
          symbol: new SimpleMarkerSymbol({
            color: '#FF7F00', //orange Color for showing alert
            haloColor: "white",
            size: '12px',
            style: 'circle',
          }),
          label: "Active Alert"
        },
        {
          value: "multiple-green",
          symbol: {
            type: "picture-marker", // Use a picture-marker for the icon
            url: this.getConcentricCircleIcon('#4dc254'), // green color 
            width: "20px",
            height: "20px"
          },
          label: "Multiple Green"
        },
        {
          value: "multiple-gray",
          symbol: {
            type: "picture-marker", // Use a picture-marker for the icon
            url: this.getConcentricCircleIcon('#AAAAAA'), 
            width: "20px",
            height: "20px"
          },
          label: "Multiple Gray"
        },
        {
          value: "multiple-red",
          symbol: {
            type: "picture-marker", // Use a picture-marker for the icon
            url: this.getConcentricCircleIcon('#FF7F00'), 
            width: "20px",
            height: "20px"
          },
          label: "Multiple Alert"
        }
      ]
    });
  }

  getConcentricCircleIcon(fillColor) {
    const svgContent = `
    <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 40 40">
      <!-- Outer Circle -->
      <circle cx="20" cy="20" r="17" fill="transparent" stroke="black" stroke-width="1.5"/>
  
      <!-- Inner Circle -->
      <circle cx="20" cy="20" r="10" fill="${fillColor}" stroke="black" stroke-width="1.5"/>
    </svg>
  `;
  return `data:image/svg+xml;base64,${btoa(svgContent)}`;

  }
  
  createTemplate(data, systemType) {
    const meterOrFacility = data.deviceType === 'Flow Meter' ? 'Flow Meter' : systemType;
    return getTemplatesBySystemType(meterOrFacility, data)   
  }

  getFeatureReductionTemplateForRadius({clusterRadius, clusterMinSize, clusterMaxSize}, LabelClass, siteName) {
    const clusterSymbol = {
      type: "simple-marker", // autocasts as new SimpleMarkerSymbol()
      style: "circle", // You can change this to 'circle', 'diamond', 'cross', 'x', etc.
      color: "#f4f4de",
      size: "30px", // Size of the cluster marker
      outline: {
        color: "#D4AF3C",
        width: 1.2
      }
    };
    return {
      type: 'cluster',
      clusterRadius: clusterRadius, // Increase the radius to form larger initial clusters
      // maxClusterRadius: 10, // Maximum cluster radius to enforce single large cluster
      clusterMinSize: clusterMinSize,
      clusterMaxSize: clusterMaxSize,
      labelingInfo: [
       new LabelClass( {
        deconflictionStrategy: "none",
        labelExpressionInfo: {
          expression: "Text($feature.cluster_count, '#,###')"
        },
        symbol: {
          type: "text",
          color: "black",
          font: {
            family: "Noto Sans",
            size: "14px",
            weight: "bold"
          },

        },
        labelPlacement: "center-center"
      }),
      new LabelClass({
        labelExpressionInfo: { expression: `'${siteName}'` }, // Shows status above the circle
        symbol: {
          type: "text", // Label symbol
          color: "black",
          backgroundColor: "black", // Background color of the label
          haloColor: "white",
          haloSize: "1px",
          font: {
            size: "14px",
          }
        },
        labelPlacement: 'center-right' // Position label above the circle
      })
      ],
      symbol: clusterSymbol, // Apply the custom cluster symbol
    }
  }

  getColorStopsForHeatMap() {
    const colors = ["rgba(115, 115, 0, 0)", "rgba(6, 189, 18, 0.51)", "rgba(0, 248, 33, 0.44)", "rgba(104, 255, 16, 0.44)", "rgba(134, 255, 16, 0.44)", "rgba(164, 255, 16, 0.44)", "rgba(198, 255, 16, 0.44)", "rgba(233, 255, 16, 0.44)", "rgba(255, 247, 16, 0.44)", "rgba(255, 187, 16, 0.44)", "rgba(255, 147, 16, 0.44)", "rgba(255, 97, 16, 0.44)", "rgba(255, 52, 21, 0.44)"];
    return  [
      { color: colors[0], ratio: 0 },
      { color: colors[1], ratio: 0.083 },
      { color: colors[2], ratio: 0.166 },
      { color: colors[3], ratio: 0.249 },
      { color: colors[4], ratio: 0.332 },
      { color: colors[5], ratio: 0.415 },
      { color: colors[6], ratio: 0.498 },
      { color: colors[7], ratio: 0.581 },
      { color: colors[8], ratio: 0.664 },
      { color: colors[9], ratio: 0.747 },
      { color: colors[10], ratio: 0.831 },
      { color: colors[11], ratio: 0.913 },
      { color: colors[12], ratio: 1 }
    ]
  }

  // new services 
  createAttributesForSites(data) {
    return {
      oid: data.site,
      site: data.site,
      deviceCount: data.deviceCount,
      longitude: data.location.longitude,
      latitude: data.location.latitude
    }
  }

  createFieldsForSites() {
    return [
      { name: "oid",alias: "Object ID",type: "oid"},
      { name: 'site', alias: 'Site Name', type: 'string' },
      { name: 'deviceCount ', alias: 'Device Count', type: 'integer'},
      { name: 'longitude', alias: 'Longitude', type: 'string' },
      { name: 'latitude', alias: 'Latitude', type: 'string' }
    ]
  }

  getSiteRenderer(site,SimpleRenderer, SimpleMarkerSymbol) {
    return new SimpleRenderer({
      symbol: new SimpleMarkerSymbol({
        style: "circle",
        color: "#f4f4de",
        size: 2*site.radius, // Size of the circle is double the radius
        outline: {
          color: "#D4AF3C",
          width: 1.2
        }
      })
    })
  }

  getLabelingInfoForSite(site, LabelClass) {
    return [
      // Label for text inside the circle
      new LabelClass({
        labelExpressionInfo: { expression: `'${this.decimalPipe.transform(site.totalDevices, '1.0-0')}'` }, // Shows deviceType inside the circle
        symbol: {
          type: "text", // Label symbol
          color: "black",
          // haloColor: "white",
          // haloSize: "1px",
          font: {
            size: "14px",
            weight: "bold"
          }
        },
        labelPlacement: "center-center" // Position label inside the circle
      }),
      // Label for text around the cluster
      new LabelClass({
        labelExpressionInfo: { expression: `'${site.site}'` },
        symbol: {
          type: "text",
          color: "black",
          backgroundColor: "black", 
          haloColor: "white",
          haloSize: "1px",
          font: {
            size: "14px",
          }
        },
        labelPlacement: site.labelPosition // Position label default above center, will change through Database
      })
    ]
  }

  getFacilityLabelClass(LabelClass) {
    return [
      // Label for text inside the circle
      new LabelClass({
        labelExpressionInfo: {
          expression: "$feature.description"
        }, // Shows status above the circle
        symbol: {
          type: "text", // Label symbol
          color: "black",
          backgroundColor: "black", // Background color of the label
          haloColor: "white",
          haloSize: "1px",
          font: {
            size: "14px",
          }
        },
        labelPlacement: "center-right"// Position label above the circle
      })
    ]
  }

  groupTheDeviceCountOnLatLong(devices) {
    const groupedDevices = {};  
    devices.forEach((device, idx) => {
      device['oid'] = this.generateUniqueId(device.systemType, idx);
      const key = `${device.location?.longitude},${device.location?.latitude}`;
      if (!groupedDevices[key]) {
        groupedDevices[key] = 0;
      }
      groupedDevices[key] += 1;
    })
    return groupedDevices;
  }

  generateUniqueId(systemType, idx) {
    if(systemType === SYSTEM_TYPE_TRANSMISSIO_POINT) {
      return 1000+idx;
    }
    else if(systemType === SYSTEM_TYPE_SCADA) {
      return 10000+idx;
    }
    return idx;
  }

  isItValidCoordinates({longitude, latitude}) {
    return latitude && longitude && latitude >= -90 && latitude <= 90 && longitude >= -180 && longitude <= 180;
  }

}
