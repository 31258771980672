import { Component, OnInit, ViewChild, Output, EventEmitter, Input } from '@angular/core';
import { ElementRef } from '@angular/core';
import { ArcgisMapService } from 'src/app/services/arcgis-map/arcgis-map.service';
import { loadModules } from "esri-loader";
import { MapInfo, ClusterInfo } from 'src/app/services/arcgis-map/interfaces';
import * as _ from 'underscore';
import { LandingPageEsriCacheService } from './landing-page-esri-cache.service';
import { createCustomPaginationForPopupHeader } from './landing-page-esri-templates';
import { ArcgisMapsDeviceConfigurationsService } from 'src/app/services/arcgis-map/devices-fields-template-renderer/arcgis-maps-device-configurations.service';
import { Subscription } from 'rxjs';
import { TEXT_ALL } from '../constant';
@Component({
  selector: 'app-landing-page-esri-map',
  templateUrl: './landing-page-esri-map.component.html',
  styleUrls: ['./landing-page-esri-map.component.css']
})
export class LandingPageEsriMapComponent implements OnInit {
  @ViewChild("mapViewNode", { static: true }) private mapViewEl: ElementRef;
  @Output() mapInitialized = new EventEmitter<any>();
  @Output() heatMapAdded = new EventEmitter<any>();
  @Output() showLoader = new EventEmitter<Boolean>();
  @Output() showProductNameOnFocusedSite = new EventEmitter<string[]>();
  @Output() showListVisibleFacilities = new EventEmitter<{show:string, visibleFacilitiesList:string[]}>();
  @Output() showSiteOnZoomEvent = new EventEmitter<string>();
  @Input() selectedHeatMap = false;
  @Input() loading:boolean = false;
  @Input() sitesList;
  @Input() selectedProductNames;
  @Input() facilityInfoSelected;
  @Input() detailedMetersOrFacilitiesCardsInfo;
  @Input() showMetersList;
  @Input() consumptionUnit;
  @Input() productNameList;
  @Input() selectedLobs;
  mapInfo:MapInfo = {
    center: [34.671961,28.096149],
    zoom: 10.4,
  }
  heatmapLayer;
  map;
  view;
  systemType;
  hitTestHandles;
  clusterAndLabelInfo;
  currentIndex = 0;
  totalOverllapedDevice = [];
  actionHandler;
  groupedDevices;
  selectedGeometry;
  currentZoom;
  isClusterEnabled = false;

  // New variables
  sitesFeatureLayer = {};
  facilitiesFeatureLayers = {};
  featureLayerList = [];
  LabelClass;
  cacheSize = 4;
  devicesFeatureLayer:any;
  selectedFeatureLayer:any;
  selectedSystemType;
  clusterInfo:ClusterInfo = {
    clusterRadius: '50px',
    clusterMinSize: '50px',
    clusterMaxSize: '60'
  }

  extentWatcher:any;
  zoomWatcher:any;
  hitTestEventHandler: any; 
    
  // focused or zoomed in site name
  focusedSite:string;

  requestOfDevicesFeatureLayer:{[deviceFeatureLayerId:string]:boolean} = {};
  private mapRecenterSubscription:Subscription

  constructor(
    private arcgisMapService:ArcgisMapService,
    private landingPageEsriCacheService: LandingPageEsriCacheService,
    private arcgisMapsDeviceConfigurationsService: ArcgisMapsDeviceConfigurationsService
  ) { 
      this.mapRecenterSubscription = this.arcgisMapService.mapReCentered.subscribe(() => {
          this.handleVisibilityOfSitesAndFacilitiesCard();
      })
  }

  async ngOnInit(){
    this.landingPageEsriCacheService.initializeCacheSize(this.cacheSize);
    this.currentZoom = this.mapInfo.zoom;
  } 

  async ngAfterViewInit():Promise<any> {
    await this.arcgisMapService.initializeMap(this.mapViewEl, this.mapInfo);
    this.map = this.arcgisMapService.getMapInstance();
    this.view = this.arcgisMapService.getViewInstance();
    this.view.popup.autoOpenEnabled = false;
    this.addOptionsToMap();
    this.mapInitialized.emit(true);
    this.view.constraints = {
      minZoom: 7
    };
    this.addViewWatchZoomEvent();
    this.addHitTestEvent();

  }

  addOptionsToMap() {
    // Add the custom dropdown to the map's UI
    this.view.ui.add(document.getElementById('leftOptions'), 'top-left');
    this.view.ui.add(document.getElementById('rightOption'), 'top-right');
  }

  reCentre(mapInfo:MapInfo) {
    this.arcgisMapService.reCentreWithEventEmit(mapInfo, false);
  }

  getCurrentZoom() {
    return this.currentZoom;
  }
 
  // add sites
  async addSites(sites) {
    const [FeatureLayer, Point, SimpleMarkerSymbol,SimpleRenderer, Graphic, UniqueValueRenderer, TextSymbol, LabelClass] = await loadModules([
      "esri/layers/FeatureLayer",
      "esri/geometry/Point",
      "esri/symbols/SimpleMarkerSymbol",
      "esri/renderers/SimpleRenderer",
      "esri/Graphic",
      "esri/renderers/UniqueValueRenderer",
      "esri/symbols/TextSymbol",
      "esri/layers/support/LabelClass"
    ]);
    this.LabelClass = LabelClass;
    this.removeAllSites();
    sites.forEach(siteInfo => {      
      const point = new Point({
        longitude: siteInfo.location.longitude,
        latitude: siteInfo.location.latitude  
      });
      const graphic = new Graphic({
        geometry: point,
        attributes: this.arcgisMapsDeviceConfigurationsService.createAttributesForSites(siteInfo)
      });     
      const siteFeatureLayer = new FeatureLayer({
        source: [graphic],
        fields: this.arcgisMapsDeviceConfigurationsService.createFieldsForSites(),
        objectIdField: 'oid',
        id: siteInfo.site,
        geometryType: 'point',
        spatialReference: { wkid: 4326 },
        title: 'cluster', // this will identify on hitTest function for site
        renderer: this.arcgisMapsDeviceConfigurationsService.getSiteRenderer(siteInfo, SimpleRenderer,SimpleMarkerSymbol),
        labelingInfo: this.arcgisMapsDeviceConfigurationsService.getLabelingInfoForSite(siteInfo, LabelClass)
      })
      this.featureLayerList.push(siteFeatureLayer);
      this.sitesFeatureLayer[siteInfo.site] = {
        systemType: siteInfo.systemType,
        featureLayer: siteFeatureLayer,
        site: siteInfo.site
      }
    })
    if(this.currentZoom <= 15) {
      Object.values(this.sitesFeatureLayer).forEach(d => this.map.add(d['featureLayer']))
    }
  } 

  toggleIndividualSiteFeatureLayerVisibility(siteInfo)  {
    if(this.currentZoom <= 15)
      this.sitesFeatureLayer[siteInfo.site].featureLayer.visible = siteInfo.selected;
  }
  removeAllSites() {
    this.map.removeMany(
      Object.values(this.sitesFeatureLayer).map(site => {
        return site['featureLayer'];
      })
    )
    this.sitesFeatureLayer = {};
  }

  async addFacilities(facilities) {
    const [FeatureLayer, Point, SimpleMarkerSymbol,SimpleRenderer, Graphic, UniqueValueRenderer, TextSymbol, LabelClass] = await loadModules([
      "esri/layers/FeatureLayer",
      "esri/geometry/Point",
      "esri/symbols/SimpleMarkerSymbol",
      "esri/renderers/SimpleRenderer",
      "esri/Graphic",
      "esri/renderers/UniqueValueRenderer",
      "esri/symbols/TextSymbol",
      "esri/layers/support/LabelClass"
    ]);
    const groupedDevices = this.arcgisMapsDeviceConfigurationsService.groupTheDeviceCountOnLatLong(facilities);
    const graphics = [];
    facilities.forEach(facility => {
      if(facility.location && this.arcgisMapsDeviceConfigurationsService.isItValidCoordinates(facility.location)) {
        const point = new Point({
          longitude: facility.location.longitude,
          latitude: facility.location.latitude,  
        });
        const graphic = new Graphic({
          geometry: point,
          attributes: this.arcgisMapsDeviceConfigurationsService.createAttributes(facility, groupedDevices)
        });
        graphics.push(graphic);
      }
    });
    const { systemType } = facilities[0];
    this.systemType = systemType;
    const facilityFeatureLayer =  new FeatureLayer({
      source: graphics,
      fields: this.arcgisMapsDeviceConfigurationsService.createFields(systemType),
      objectIdField: 'oid',
      geometryType: 'point',
      id: systemType,
      spatialReference: { wkid: 4326 },
      title: 'device',
      featureReduction: this.arcgisMapsDeviceConfigurationsService.getFeatureReductionTemplateForRadius(this.clusterInfo, LabelClass, systemType),
      renderer: this.arcgisMapsDeviceConfigurationsService.getRenderer(UniqueValueRenderer, SimpleMarkerSymbol),
      labelingInfo: this.arcgisMapsDeviceConfigurationsService.getFacilityLabelClass(LabelClass),
      visible: false
    })
    this.facilitiesFeatureLayers[systemType] = {
      systemType: systemType,
      featureLayer: facilityFeatureLayer
    }
    this.map.add(facilityFeatureLayer);    
  }

  toggleIndividualFacilityFeatureLayerVisibility(idsToShow) {
    if (idsToShow.length === 0) {
      Object.values(this.facilitiesFeatureLayers).forEach(item => item['featureLayer'].definitionExpression = '1=0' // No features will satisfy this condition
      )
    } else {
      // const featureLayer = this.facilitiesFeatureLayers[facilityInfo.systemType].featureLayer;
      // featureLayer.visible = true;
  
      // featureLayer.definitionExpression = `oid IN (${idsToShow.join(",")})`;
      Object.values(this.facilitiesFeatureLayers).forEach(item => {
        const featureLayer = item['featureLayer'];
        featureLayer.visible = true;
        featureLayer.definitionExpression = `oid IN (${idsToShow.join(",")})`;
      })
    }
  }

  toggleFacilitiesFeatureLayerClusterVisibility(visible) {
    Object.values(this.facilitiesFeatureLayers).forEach((facility) => {
      facility['featureLayer'].visible = visible;
      facility['featureLayer'].definitionExpression = "1=1"; // defination expression set to show all

    })
  }


  async fetchDevices(selectedProductNames=[]) {
      if(selectedProductNames.length) 
          this.selectedProductNames = selectedProductNames;
      
      if(!this.focusedSite || this.currentZoom <= 15) return;

      if(this.requestOfDevicesFeatureLayer.hasOwnProperty(this.createdDevicesFeatureLayerName(this.focusedSite) || this.map.findLayerById(this.createdDevicesFeatureLayerName(this.focusedSite))))
        return;
      
      this.requestOfDevicesFeatureLayer[this.createdDevicesFeatureLayerName(this.focusedSite)] = true;

      this.systemType = 'Meter';
      // Fetch the selected site and deviceType graphics data from cache service
      this.showLoader.emit(true);
      this.removeCurrentDeviceFeatureLayer();
      let devicesData = [];
      const devicesDataPromises: Promise<any>[]= this.selectedProductNames.map(async (productName) => {
        if (this.sitesList[productName]?.hasOwnProperty(this.focusedSite)) {
          return await this.landingPageEsriCacheService.getDevicesData(this.sitesList, this.focusedSite, productName);
        }
        return {deviceData:[], fetchedFromCachedService:false};
      });
      
      // Wait for all promises to resolve and flatten the result using reduce
      const results = await Promise.all(devicesDataPromises);
      devicesData = results.reduce((acc:any[], val) => acc.concat(val.deviceData), []);
      // Now add all accumulated graphics to the feature layer
      await this.addDevicesOnFeatureLayer(devicesData, this.focusedSite);
      this.fetchParametersData(this.focusedSite);
      this.getConsumptionsOfDevices(this.focusedSite);
      this.showLoader.emit(false);
  }

  async addDevicesOnFeatureLayer(devicesData, site) {
    const [FeatureLayer, SimpleMarkerSymbol, UniqueValueRenderer] = await loadModules([
        "esri/layers/FeatureLayer",
        "esri/symbols/SimpleMarkerSymbol",
        "esri/renderers/UniqueValueRenderer",
      ]);
      const graphics = await this.arcgisMapsDeviceConfigurationsService.createGraphics(devicesData, this.systemType);
      this.devicesFeatureLayer = new FeatureLayer({
        source: graphics,
        fields: this.arcgisMapsDeviceConfigurationsService.createFields(this.systemType),
        objectIdField: 'oid',
        geometryType: 'point',
        id: this.createdDevicesFeatureLayerName(site),
        spatialReference: { wkid: 4326 },
        title: 'device',
        renderer: this.arcgisMapsDeviceConfigurationsService.getRenderer(UniqueValueRenderer, SimpleMarkerSymbol),
      })
      if(this.currentZoom > 15) {
        this.map.add(this.devicesFeatureLayer);
      }
  }

  async getConsumptionsOfDevices(site) {
    const consumptionDataPromise = this.selectedProductNames.map(async(productName) => {
      if (this.sitesList[productName]?.hasOwnProperty(site)){
        return await this.landingPageEsriCacheService.getConsumptionsOfDevices(site, productName, this.devicesFeatureLayer); 
      }
      return {devicesConsumptions: {}, productName}
    })  

    const allDeviceTypeDevicesConsumptions:{productName:string, devicesConsumptions:[]}[] = await Promise.all(consumptionDataPromise);
    const devicesData = this.devicesFeatureLayer.source.items;
    // update data on source map
    devicesData.forEach(deviceSource => {
        const deviceData = deviceSource.attributes;
        const uplinkReferenceKey = deviceData['uplinkReferenceKey'];
        const data = allDeviceTypeDevicesConsumptions.filter(d => d.productName === deviceData.productName)[0];
        deviceData['consumptionFor30Days'] = data.devicesConsumptions[uplinkReferenceKey];
        deviceData['consumptionUnit'] = this.consumptionUnit &&  this.consumptionUnit[data.productName] || '';
    })
    this.devicesFeatureLayer.refresh(); 
  }

  async fetchParametersData(site) {
    let validDeviceTypes = [];
    this.selectedProductNames.forEach(productName => {
      if (this.sitesList[productName]?.hasOwnProperty(site)){
          validDeviceTypes.push(productName);
      }
    })

    const parametersData =  await this.landingPageEsriCacheService.getFacilitiesParameterData(site, validDeviceTypes);
    const devicesData = this.devicesFeatureLayer.source.items;
    devicesData.forEach(device => {
      const deviceData = device.attributes;
      deviceData['percentageData'] = parametersData[deviceData['uplinkReferenceKey']]['percentageDataFetched'];
    })
    this.devicesFeatureLayer.refresh();  

  }

  addViewWatchZoomEvent() {
    let zoomDebounce: NodeJS.Timeout | null = null;
    this.zoomWatcher = this.view.watch("zoom", (newZoom) => {
      if (zoomDebounce) {
        clearTimeout(zoomDebounce);
      }
    
      zoomDebounce = setTimeout(async () => {
        if (Math.ceil(newZoom) !== Math.ceil(this.currentZoom)) {
          this.currentZoom = newZoom;
          this.handleVisibilityOfSitesAndFacilitiesCard();
        }
      }, 100); // Adjust debounce delay as needed
    });
  }

  async handleVisibilityOfSitesAndFacilitiesCard() {
    const visibleClusterSites = await this.showSiteIfClusterIsInVisibleRange();
    this.FilterFacilitiesList();
    if(this.currentZoom > 18) return;
    if (this.currentZoom > 15) {
      if (!this.facilityInfoSelected) {
        this.toggleSiteFeatureLayerClusterVisibility(false);
      }
    } else {    
      if (!this.facilityInfoSelected && !this.selectedLobs.includes('Industrial')) {
        this.toggleSiteFeatureLayerClusterVisibility(true);
      }
    }
    visibleClusterSites.forEach(site => this.handleFetchDevices(site))
    this.showDeviceTypeCardsOnZoomEvent(visibleClusterSites);
    
  }

  async FilterFacilitiesList() {
    const visibleFacilitiesList = [];
    const queries =  Object.values(this.facilitiesFeatureLayers).map(d => {
        return (async () => {
          const facilityFeatureLayer = d['featureLayer'];
          // Create a query for the FacilityFeatureLayer
          const query = facilityFeatureLayer.createQuery();
          query.geometry = this.view.extent; // Use the current view extent
          query.spatialRelationship = "intersects"; // Find points that intersect with the extent
          query.returnGeometry = true; // Ensure geometry is returned for each feature
          query.outFields = ["*"]; // Include all attributes

          // Execute the query
          const result = await facilityFeatureLayer.queryFeatures(query);
          if (result.features.length > 0) {
            result.features.forEach((feature) => {
              visibleFacilitiesList.push(feature.attributes.description);
            });
          } 

        })();
    });

    await Promise.all(queries); 
    if(this.currentZoom < 11 && visibleFacilitiesList.length > 0 ) { 
      // zoom level taken on the bases of recentring logic
      // if zoom level and list is > 0 may be chance some facility will not show due to clustring that's why show all of them
      this.showListVisibleFacilities.emit({show:TEXT_ALL, visibleFacilitiesList: []})
      return;
    }
    this.showListVisibleFacilities.emit({show: 'filtered', visibleFacilitiesList})
  }

  async showSiteIfClusterIsInVisibleRange() {
    const visibleClusterSites = [];
    const extent = this.view.extent;
    const queries = Object.values(this.sitesFeatureLayer).map((d) => {
      return (async () => {
        const query = d['featureLayer'].createQuery();
        query.geometry = extent;
        query.spatialRelationship = "intersects";
        const result = await d['featureLayer'].queryFeatures(query);
        if (result.features.length > 0) {
          visibleClusterSites.push(d['site']);
        }
      })();
    });
  
    await Promise.all(queries);
    return visibleClusterSites;
  }

  handleFetchDevices(site) {
    if(this.currentZoom > 15) {
      if(!this.map.findLayerById(this.createdDevicesFeatureLayerName(site))) {
        this.focusedSite = site;
          this.fetchDevices();
          this.showSiteOnZoomEvent.emit(site);
      }
    }
    else {
      Object.values(this.sitesFeatureLayer).forEach(d => {
        if(!this.map.findLayerById(d['site'])) {
          this.map.add(d['featureLayer'])
        }
      })
      this.removeExistingDevicesFeatureLayer();
      this.showSiteOnZoomEvent.emit(TEXT_ALL);
    }
  }

  showDeviceTypeCardsOnZoomEvent(visibleClusterSites) {
    if(visibleClusterSites.length === 0) 
       this.showProductNameOnFocusedSite.emit([]);

    const productNameOnFocusedSite:string[] = [];
    visibleClusterSites.forEach(site => {
      this.selectedProductNames.map(productName => {
        if (this.sitesList[productName]?.hasOwnProperty(site)) {
            if(!productNameOnFocusedSite.includes(productName)) // if it is not in the list then append it
              productNameOnFocusedSite.push(productName);
        }
      })
    })
    this.showProductNameOnFocusedSite.emit(productNameOnFocusedSite);
  }
  addHitTestEvent() {
    this.hitTestHandles = this.view.on("click", (event) => {
      this.view.hitTest(event).then(({ results }) => {
        // By click on map, close the popupTemplate first and 
        this.closePopupTemplate();
        this.handleClick(results);
        /* call open popup component after 100ms so that map will complete there operations meanwhile
           open popup function is not async function and it not either give the instance to check of it open status, that's why need to wait manually
        */
        setTimeout(() => {
          this.openPopupTemplate(results, event);
        }, 100);
      });
    });
    this.addCustomActionOnPopupTooltip();

  }

  // if the clicked one is cluster then zoom into it
  handleClick(results) {
    const isCluster = results[0].layer?.featureReduction?.type === "cluster";
    const clusterCount = results[0]?.graphic?.attributes?.cluster_count;
    const isItSiteCluster = results[0].layer?.title === 'cluster';
    // if it is cluster and size greater than 1 then zoom to devices
    if((isCluster && clusterCount > 1) || isItSiteCluster){
      const cluster = {target:results[0].graphic.geometry, zoom: results[0].graphic?.attributes.hasOwnProperty('oid') ? 18: 16}; // if attributes has oid it means it site cluster 
      this.view.goTo(
        {
          target: cluster.target,
          zoom: cluster.zoom
        },
        {
          duration: 1200, // Duration in milliseconds (e.g., 2000ms = 2 seconds)
          easing: "ease-in-out" // Easing function for smooth transition
        }
      );
    }
  }

  createdDevicesFeatureLayerName(site) {
    const oneOrManyDeviceTypes = this.selectedProductNames.length > 1 ? TEXT_ALL : '1';
    return `${site}-${oneOrManyDeviceTypes}-devicesFeatureLayer`;
  }

  removeCurrentDeviceFeatureLayer() {
    this.map?.remove(this.devicesFeatureLayer)
  }
    closePopupTemplate() {
      this.view.popup.close();
      this.actionHandler?.remove();
      this.actionHandler = null;
    }

    removeExistingDevicesFeatureLayer() {
    Object.keys(this.requestOfDevicesFeatureLayer).forEach(id => {
      const layer = this.map.findLayerById(id);
      if (layer) {
        this.map.remove(layer);
      }
      delete this.requestOfDevicesFeatureLayer[id];
    })
  }
    openPopupTemplate(response, event) {
      const isItDevice = response[0].layer?.title === 'device';
      if(isItDevice) {
        const {results, selectedFeatureLayer} = this.getClickedFeatureLayerOfDevice(response);
        if(!results || !results.length) return;
        this.selectedFeatureLayer = selectedFeatureLayer;
        this.currentIndex = 0; 
        this.selectedGeometry = null;
        let selected = results[this.currentIndex];
        this.totalOverllapedDevice = results.filter((result) => result.graphic.geometry.longitude == selected.graphic.geometry.longitude && result.graphic.geometry.latitude == selected.graphic.geometry.latitude);
        let data = this.selectedFeatureLayer.source.items.filter((source) => source.attributes.oid === selected.graphic.attributes.oid)[0];
        this.selectedGeometry = selected.graphic.geometry;
        this.showDataOnPopupTooltip(data, selected.graphic.geometry);      
      }
    }

    getClickedFeatureLayerOfDevice(response) {
      const isItDeviceFeatureLayer = response.filter((result) => result.graphic.layer.id === this.devicesFeatureLayer?.id);
      if(isItDeviceFeatureLayer.length) return {results: isItDeviceFeatureLayer, selectedFeatureLayer: this.devicesFeatureLayer};
      const facilitiesName = Object.keys(this.facilitiesFeatureLayers);
      let res = {results: [], selectedFeatureLayer: null}
       response.forEach(item => {
        if(facilitiesName.includes(item.graphic.layer.id)) {
          res.results = [item];
          res.selectedFeatureLayer = this.facilitiesFeatureLayers[item.graphic.layer.id].featureLayer;
        }
      });
      return res;
    }
  
    showDataOnPopupTooltip(data, geometry) {
      if(!data) return;
      this.view.popup.open({
        content: this.arcgisMapsDeviceConfigurationsService.createTemplate(data.attributes, data.attributes.systemType || 'Meter'),
        location: geometry,
      });
    }

  addCustomActionOnPopupTooltip() {
      this.view.popup.watch('visible', (isVisible) => {
        if (isVisible) {
          let current = this.currentIndex+1;
          let totalCount = this.totalOverllapedDevice.length;
          setTimeout(() => {
            const popupHeader = document.querySelector('.esri-popup__inline-actions-container'); // Adjust the selector as necessary
            if (popupHeader) {
              createCustomPaginationForPopupHeader(popupHeader, current, totalCount)
              let paginationText = document.getElementById('esri-pagination-text');
               // listen event for click next
              document.getElementById("esri-pagination-next")?.addEventListener("click", () => {
                if(this.currentIndex+1 < this.totalOverllapedDevice.length ) {
                this.currentIndex = this.currentIndex +1 ;
                const selected = this.totalOverllapedDevice[this.currentIndex];
                const data = this.selectedFeatureLayer.source.items.filter((source) => source.attributes.oid === selected.graphic.attributes.oid)[0];
                paginationText.textContent = `${this.currentIndex+1} of ${this.totalOverllapedDevice.length}`;
                this.view.popup.content = this.arcgisMapsDeviceConfigurationsService.createTemplate(data.attributes, this.systemType);
                this.selectedGeometry = selected.graphic.geometry;
              }
              });

             // listen event for click prev
             document.getElementById("esri-pagination-prev")?.addEventListener("click", () => {
              if(this.currentIndex > 0) {
                this.currentIndex = this.currentIndex - 1;
                const selected = this.totalOverllapedDevice[this.currentIndex];
                const data = this.selectedFeatureLayer.source.items.filter((source) => source.attributes.oid === selected.graphic.attributes.oid)[0];
                paginationText.textContent = `${this.currentIndex+1} of ${this.totalOverllapedDevice.length}`;
                this.view.popup.content = this.arcgisMapsDeviceConfigurationsService.createTemplate(data.attributes, this.systemType);
                this.selectedGeometry = selected.graphic.geometry;
              }
             });

             document.getElementById("custom-esri-zoom")?.addEventListener("click", () => {
              this.currentZoom = this.currentZoom + 1;  
              this.view.goTo( {
                  target: this.selectedGeometry,
                  zoom: this.currentZoom
                },
                {
                  duration: 300, // Duration in milliseconds (e.g., 2000ms = 2 seconds)
                  easing: "ease-in-out" // Easing function for smooth transition
                })
            })
          }
        }, 100); // Delay to ensure the popup header is available
      }
    });
  }

  toggleSiteFeatureLayerClusterVisibility(visible) {
    Object.values(this.sitesFeatureLayer).forEach((site) => {
      if(!this.showMetersList && visible) {
        site['featureLayer'].visible = visible && this.isMeterSelected(site['site']);
      }
      else {
        site['featureLayer'].visible = visible;
      }
    })
  }

  isMeterSelected(site) {
    const siteInfoFound = this.detailedMetersOrFacilitiesCardsInfo.data.find(siteInfo => siteInfo.site === site);
    return siteInfoFound && siteInfoFound.selected;
  }
  updateFacilitiesSource(systemType, data) {
    const featureLayer = this.facilitiesFeatureLayers[systemType].featureLayer;
    const source = featureLayer.source;
    Object.keys(data).forEach(key => {
        const g = source.items.filter(graphic => graphic.attributes.description === key)[0];
        Object.keys(data[key]).forEach(parameter => g.attributes[parameter] = data[key][parameter]);
    })
    featureLayer.source = source;
    featureLayer.refresh();
  } 

  removeAllLayer() {
    this.map?.remove(this.devicesFeatureLayer);
    this.map?.remove(this.facilitiesFeatureLayers);
    this.map?.remove(this.sitesFeatureLayer);
    this.closePopupTemplate();
    this.hitTestHandles?.remove();
  }

  removeSubscribeEventsOfMap() {
    // Remove the extent change event if it's attached
    if (this.zoomWatcher) {
      this.zoomWatcher.remove();
    }
    if(this.hitTestHandles) {
      this.hitTestHandles.remove();
    }
    if(this.extentWatcher) {
      this.extentWatcher.remove()
    }
    // Destroy the map view
    if (this.view) {
      this.view.destroy();
    }
  }

  ngOnDestroy() {
    this.removeAllLayer();
    this.removeSubscribeEventsOfMap()
    this.landingPageEsriCacheService.clearCache();
    if(this.mapRecenterSubscription) {
      this.mapRecenterSubscription.unsubscribe();
    }
  }

  // async addHeatMap(devices) {
  //   const [FeatureLayer,] = await loadModules([
  //     "esri/layers/FeatureLayer",
  //   ]);
  //   // remove existing Heat map layer if any and recenter map if not in focus;
  //   this.removeHeatMapLayer();
  //   if(this.isClusterEnabled) {
  //     // this.removeCluster();
  //   }

  //   if (devices.length > 0) {
  //     const [Graphic] = await loadModules([
  //       "esri/Graphic",
  //     ]);
  //     const features = devices.map((data, index) => {
  //       let intensity = data.deviceType === "Water Meter" ? data.consumptionFor30Days * 25 : data.consumptionFor30Days;
  //       return new Graphic({
  //         geometry: {
  //           type: "point",
  //           latitude: data.location.latitude,
  //           longitude: data.location.longitude
  //         },
  //         attributes: {
  //           deviceID: data.deviceID,
  //           Intensity: intensity > 18 ? 180 : Math.ceil(intensity) * 10
  //         }
  //       });
  //     });
  //     this.heatmapLayer = new FeatureLayer({
  //       source: features,
  //       objectIdField: "deviceID",
  //       fields: [
  //         {
  //           name: "deviceID",
  //           alias: "Device ID",
  //           type: "string"
  //         },
  //         {
  //           name: "Intensity",
  //           alias: "Intensity",
  //           type: "integer"
  //         }
  //       ],
  //       renderer: {
  //         type: "heatmap",
  //         field: "Intensity",
  //         colorStops: this.landingPageEsriMapService.getColorStopsForHeatMap(),
  //         radius: 25,
  //         // blurRadius: 15,
  //         maxPixelIntensity: 10,
  //         minPixelIntensity: 0,
  //         maxDensity: 0.14,
  //         minDensity: 0,
  //         zoom: 4,

  //       }
  //     });
  //     this.view.constraints.snapToZoom = false;
  //     this.view.constraints.minScale = 1155582;
  //     this.map.add(this.heatmapLayer);
  //   }
  // }


 
}
