import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.css']
})
export class PaginationComponent implements OnInit {
  @Input() currentPage: number = 1;
  @Input() itemPerPage: number = 10;
  @Input() searchFieldsArr: any = [];
  @Input() COLUMN_NAMES: any = [];
  @Input() data = [];
  @Input() appliedFilters = []; // it will used in async pagination 
  @Input() totalRecords = 1;
  @Input() totalPageCount = 1;
  @Input() asyncPagination: boolean = false;
  @Input() pageSizeOption = [10, 15, 20, 25];
  @Output() paginationDone = new EventEmitter<any>();
  @Output() pageChange = new EventEmitter<any>(); // this event in async pagination call which gives the current page as per pagination perform
  @Output() itemPerPageChange = new EventEmitter<any>();

  searchedText = "";
  searchedMapText:string = "";
  public filteredData = []
  public pageNumberArr = [];

  constructor() { }

  ngOnInit(): void { }

  ngOnChanges() {
    this.filteredData = this.data;
    // if it doesn't async pagination 
    if (!this.asyncPagination) {
      this.updatePageNumberArray();
      this.initializePagination();
    }
    else {
      this.totalPageCount = Math.ceil(this.totalRecords / this.itemPerPage);
    }

  }

  // update page number array based on data and item per page
  updatePageNumberArray() {
    const size = this.filteredData?.length;
    this.pageNumberArr = (Array.from({ length: (size / this.itemPerPage + (size % this.itemPerPage != 0 ? 1 : 0)) }).fill('a'));
    this.totalPageCount = this.pageNumberArr?.length;
    this.totalRecords = this.filteredData?.length;
  }

  paginateData() {
    if(!this.asyncPagination) {
      this.updatePageNumberArray();
    }
    const start = this.currentPage * this.itemPerPage - this.itemPerPage;
    const end = this.currentPage * this.itemPerPage;
    const data = {
      paginatedData : this.filteredData?.slice(start, end),
      filteredData : this.filteredData
    };
    this.paginationDone.emit(data);
  }

  initializePagination() {
    // may possible by default some filter added (from local storage)
    this.handleAppliedFilters();
  }

  // pagination
  handleSetPage(pageNo) {
    this.currentPage = pageNo;
    if (this.asyncPagination) {
      this.pageChange.emit(pageNo);
    }
    else {
      this.paginateData();
    }
  }

  handlePrev() {
    if (this.currentPage > 1) {
      this.handleSetPage(this.currentPage - 1);
    }
  }
  handleNext() {

    if (this.currentPage < this.totalPageCount) {
      this.handleSetPage(this.currentPage + 1);
    }
  }

  handleSearch(searchedText) {
    this.searchedText = searchedText;
    // set page to 1 first, otherwise when filter is done current page still point to paginated page in which data not found
    this.handleSetPage(1);
    this.filteredData = this.makeArrayWithSearchFilter(this.searchedText);
    this.paginateData();
  }
  handleMapSearch(searchedText) {
    this.searchedMapText = searchedText;
    this.filteredData = this.makeArrayWithSearchFilter(this.searchedMapText);
    const data = {
      paginatedData : this.filteredData,
      filteredData : this.filteredData
    };
    this.paginationDone?.emit(data);
  }

  makeArrayWithSearchFilter(searchedText) {
    const originalData = this.data;
    const filteredData = this.appliedFilters?.length === 0 ? originalData : this.getDataOnApplyingFilter();
    // if filtered applied then search on filtered table
    if (searchedText === "" && this.searchedMapText === "") {
      return this.appliedFilters?.length === 0 ? originalData : filteredData;
    }
    else {
      let data ;
      if(this.searchedMapText){
        data = originalData;
      }else{
        data = ( this.appliedFilters?.length === 0) ? originalData : filteredData;
      }
      const res = data.filter((row) => {
        for (let i = 0; i < this.searchFieldsArr?.length; i++) {
          const field = this.searchFieldsArr[i];
          if (row[field]?.toString().toLowerCase().includes(searchedText.toLowerCase())) {
            return row
          }
        }
      })
      return res;
    }
  }

  handleAppliedFilters() {
    this.handleSetPage(1);
    this.filteredData = this.getDataOnApplyingFilter();
    this.paginateData();
  }

  getDataOnApplyingFilter() {
    // if no filter applied return all data
    const originalData = this.data;
    if (this.appliedFilters?.length === 0) {
      return this.searchedText === "" ? originalData : this.makeArrayWithSearchFilter(this.searchedText);
    }
    else {
      const columnNames = JSON.parse(JSON.stringify(this.COLUMN_NAMES));
      for (let i = 0; i < this.appliedFilters?.length; i++) {
        const key = this.appliedFilters[i]['key'];
        columnNames[key].push(this.appliedFilters[i]);
      }
      let returnData = originalData;
      Object.keys(columnNames)?.forEach((column) => {
        if (columnNames[column]?.length > 0) {
          const filtersAppliedOnColumn = columnNames[column];
          let temp = [];
          for (let i = 0; i < filtersAppliedOnColumn?.length; i++) {
            const filter = filtersAppliedOnColumn[i];
            const key = filter['key'];
            const value = filter[key];
            const res = returnData?.filter((row) => {
              const valueCorrespondingToKeyInRow = this.getValueOfKey(row, key);
              if (key === 'alertType') {
                if (valueCorrespondingToKeyInRow?.toString().toLowerCase().includes(value?.toString().toLowerCase())) {
                  return row;
                }
              } else {
                if (valueCorrespondingToKeyInRow?.toString().toLowerCase() === value?.toString().toLowerCase()) {
                  return row;
                }
              }
            })
            // push array if they belongs to same key
            res?.forEach(item => temp.push(item))
          }
          returnData = temp;
        }
      })
      return returnData;
    }
  }

  // it will check if key is nested or not and return value accordingly
  getValueOfKey(row, key) {
    if (key.includes('.')) {
      const keysArray = key.split('.');
      return keysArray.reduce((acc, key) => (acc && acc[key] !== undefined) ? acc[key] : undefined, row);
    }
    else {
      return row[key];
    }
  }

  handleSort(sortingInfo) {
    this.filteredData = this.getSortedData(sortingInfo);
    this.paginateData();
  }

  getSortedData({ sort, column }) {
    return this.filteredData?.sort((a, b) => {
      let valueOnA = this.getValueOfKey(a, column);
      let valueOnB = this.getValueOfKey(b, column);
      valueOnA = valueOnA ? valueOnA.toString().toLowerCase() : "";
      valueOnB = valueOnB ? valueOnB.toString().toLowerCase() : "";
      if (!valueOnA && !valueOnB) {
        return 0; // Both are empty strings, consider them equal
      } else if (this.isItEmptyOrUndefined(valueOnA)) {
        return sort === 'inc' ? 1 : -1; // Empty string comes after non-empty string for ascending, and before for descending
      } else if (this.isItEmptyOrUndefined(valueOnB)) {
        return sort === 'inc' ? -1 : 1; // Non-empty string comes before empty string for ascending, and after for descending
      } else {
        if (sort === 'inc') {
          return valueOnA <= valueOnB ? -1 : 1
        } else {
          return valueOnA <= valueOnB ? 1 : -1;
        }
      }
    });
  }

  isItEmptyOrUndefined(value) {
    return value != 0 && !value
  }

  tablePageSizeChange() {
    if (this.asyncPagination) {
      this.itemPerPageChange.next(this.itemPerPage);
    }
    else {
      this.initializePagination();
    }
  }

}



