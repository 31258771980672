import { Component, OnInit } from '@angular/core';
import { RegistrationService } from 'src/app/reusable/services/registration.service';

@Component({
  selector: 'app-meter-status',
  templateUrl: './meter-status.component.html',
  styleUrls: ['./meter-status.component.css']
})
export class MeterStatusComponent implements OnInit {
  deviceData;
  // ************************** This component is no currently in use *************************

  constructor(private registrationService:RegistrationService) { }

  ngOnInit(): void {
    this.initialize();
  }

  initialize() {
    const payload = {
      meterTypes:["Water Meter","Energy Meter","Flow Meter"],
      facilityTypes:["all"]
    };

    // this.registrationService.getStatusCountByDeviceType(payload).subscribe(data => {
    //   this.deviceData = data
    // })
  }

  getWaterMeterReading(device) {
    return this.deviceData?.[device];
  }

  getStyles(data){
    return  {'width': `${data?.liveDevices*100/data?.totalDevices}%`}
  }
}
