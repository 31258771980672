<div style="position: relative;">
  
    <div class="mapOptions customTab">
        <mat-button-toggle-group name="fontStyle" aria-label="Font Style" [(ngModel)]="selectedMap">
            <mat-button-toggle value="devices" [checked]="true" (click)="selectMapType('devices')">Devices</mat-button-toggle>
            <mat-button-toggle value="consumption" (click)="selectMapType('consumption')">Consumption</mat-button-toggle>
        </mat-button-toggle-group>
        <div class="alignText">
            <div *ngIf="heatMapEnabled" class="mapOptionsConsumption">
                <div class="dateFunction">
                <app-common-datepicker (dateChange)="datepicker($event)"
                    [dateSelected]="[startDate,endDate]"></app-common-datepicker>
                </div>
                <section class="example-section">
                    <mat-checkbox [(ngModel)]="displayDevices" (change)="handleShowDevicesToggle($event)">Show Devices</mat-checkbox>
                </section>
            </div>
        </div>
        <div style="text-align: center;" class="loaderAndMessage">
            <div class="messageToast" *ngIf="!loading && isHeatMapRendered && !isConsumptionsExist_">No data available</div>
        </div>
    </div>
    <div *ngIf="loading" class=" spinner">
        <!-- <img id="loading-image" src="assets/images/loader.gif" alt="Loading..." /> -->
        <div class="loader"></div>
    </div>   

    <app-device-listing-esri style="position: relative;" (mapInitialized)="esriMapInitialized($event)"></app-device-listing-esri>

</div>