import { IDetailedFacilityCardInfo, IDetailedMeterCardInfo } from "./interfaces";

export const METERS = 'meters';
export const FACILITIES = 'facilities';
export const TEXT_ALL = 'all';
export const TEXT_INDUSTRIAL = 'Industrial';
export const INDUSTRIAL_FACILITIES = 'Industrial Facilities';
export const CONSUMER = 'Consumer';
export const DEFAULT_METER_LIST =  [
    {
      description: null,
      productName: null,
      icon: 'humidity_low',
      liveDevices: null,
      offlineDevices: null,
      totalDevices: null,
      dashboardUrl: '/watermeters/dashboard'
    },
    {
      description: null,
      productName: null,
      icon: 'electric_bolt',
      liveDevices: null,
      offlineDevices: null,
      totalDevices: null,
      dashboardUrl: '/energymeters/dashboard'
    },
    {
      description : null,
      productName: null,
      icon: 'mode_fan',
      liveDevices: null,
      offlineDevices: null,
      totalDevices: null,
      dashboardUrl: '/coolingmeters/dashboard'
    },
    {
      description: null,
      productName: null,
      icon: 'airwave',
      liveDevices: null,
      offlineDevices: null,
      totalDevices: null,
      dashboardUrl: '/watermeters-customer/flow-meters'
    }
  ]


export const DEFAULT_DETAILED_METER_CARD_INFO:IDetailedMeterCardInfo = {
  type: METERS,
  productName: null,
  selectedMeterOrFacilityName: null,
  data: [
    { site: null, totalDevices: 0, activeDevices: 0 }
  ],
};

export const DEFAULT_DETAILED_FACILITY_CARD_INFO:IDetailedFacilityCardInfo = {
  type: FACILITIES,
  productName:null,
  selectedMeterOrFacilityName: null,
  data: [
    { tags: 0, lastDataReceivedOn: null, status: null, description: null },
    { tags: 0, lastDataReceivedOn: null, status: null, description: null },
  ],
};

export const formatedSystemTypeName = {
  'SCADA' : 'SCADA Systems',
  'Transmission Point': 'Transmission Points'
}