import { 
    DUBA_DEVICE_TYPE, 
    PUMP_STATION_DEVICE_TYPE, 
    ALBADA_DEVICE_TYPE, 
    HPR_STAION_DEVICE_TYPE ,
    FLOW_METER_DEVICE_TYPE,
    SHARMA_OPCUA_DEVICE_TYPE,
    LOGISTICS_PARK_DEVICE_TYPE,
    NEOM_BAY_AIRPORT_DEVICE_TYPE,
    EXPERIENCE_CENTER_DEVICE_TYPE,
    DEVICE_TYPE_WATER_METER,
    DEVICE_TYPE_ENERGY_METER,
    DEVICE_TYPE_COOLING_METER
} from "./constant";

const DESCRIPTION_LOGISTICS_PARK_BATCHING_PLANT = "Logistics Park - Batching Plants";
const  DESCRIPTION_LOGISTICS_PARK_CONSTRUCTION_VILLAGE_1 = "Logistics Park - ConstructionVillage 1";
const  DESCRIPTION_LOGISTICS_PARK_CONSTRUCTION_VILLAGE_2 = "Logistics Park - ConstructionVillage 2";
const  DESCRIPTION_LOGISTICS_PARK_CONSTRUCTION_VILLAGE_3 = "Logistics Park - ConstructionVillage 3";
const DESCRIPTION_LOGISTICS_PARK_LAYDOWN_AREA = "Logistics Park - Laydown Area";
const DESCRIPTION_LOGISTICS_PARK_WASTE_SEGGREGATION = "Logistics Park - Waste Seggregation";
const DESCRIPTION_NEOM_BAY_AIRPORT = "NEOM Bay Airport";
const DESCRIPTION_EXPERIENCE_CENTER = "Experience Center"

export const deviceTypeDashboardRoute = {
    [DUBA_DEVICE_TYPE] : '/watermeters-industrial/dubadesalplant', 
    [PUMP_STATION_DEVICE_TYPE]: '/watermeters-industrial/sharmaPumpStation', 
    [ALBADA_DEVICE_TYPE] : '/watermeters-industrial/ALBADA', 
    [HPR_STAION_DEVICE_TYPE]: '/watermeters-industrial/hprstation',
    [FLOW_METER_DEVICE_TYPE]: '/watermeters-customer/flow-meters',
    [DESCRIPTION_LOGISTICS_PARK_BATCHING_PLANT]: '/watermeters-industrial/logisticspark?site=LOGISTICS PARK - BATCHING PLANTS',
    [DESCRIPTION_LOGISTICS_PARK_CONSTRUCTION_VILLAGE_1]: '/watermeters-industrial/logisticspark?site=LOGISTICS PARK - CONSTRUCTIONVILLAGE 1',
    [DESCRIPTION_LOGISTICS_PARK_CONSTRUCTION_VILLAGE_2]: '/watermeters-industrial/logisticspark?site=LOGISTICS PARK - CONSTRUCTIONVILLAGE 2',
    [DESCRIPTION_LOGISTICS_PARK_CONSTRUCTION_VILLAGE_3]: '/watermeters-industrial/logisticspark?site=LOGISTICS PARK - CONSTRUCTIONVILLAGE 3',
    [DESCRIPTION_LOGISTICS_PARK_LAYDOWN_AREA]: '/watermeters-industrial/logisticspark?site=LOGISTICS PARK - LAYDOWN AREA',
    [DESCRIPTION_LOGISTICS_PARK_WASTE_SEGGREGATION]: '/watermeters-industrial/logisticspark?site=LOGISTICS PARK - WASTE SEGGREGATION',
    [DESCRIPTION_NEOM_BAY_AIRPORT]: '/watermeters-industrial/neombayairport',
    [DESCRIPTION_EXPERIENCE_CENTER]: '/watermeters-industrial/experiencecenter',
    [DEVICE_TYPE_WATER_METER]: '/watermeters/devices/device-dashboard', // append /deviceID to the string
    [DEVICE_TYPE_ENERGY_METER]: '/energymeters/devices/device-dashboard', // append /deviceID to the string
    [DEVICE_TYPE_COOLING_METER]: '/coolingmeters/devices/device-dashboard' // append /deviceID to the string
}

export const dashboardUrlForDescription = {
    "Water Meters": "/watermeters/dashboard",
    "Energy Meters": "/energymeters/dashboard",
    "Cooling Meters": "/coolingmeters/dashboard",
    "Water Meter": "/watermeters/devices/device-dashboard",
    "Energy Meter": "/energymeters/devices/device-dashboard",
    "Cooling Meter": "/coolingmeters/devices/device-dashboard",
    "Royal Palace Area 1, Sharma": "/watermeters-customer/flow-meters",
    "Royal Palace Area 2, Sharma": "/watermeters-customer/flow-meters",
    "Desalination Plant, Duba" : '/watermeters-industrial/dubadesalplant', 
    "Pump Station & TFS, Sharma": '/watermeters-industrial/sharmaPumpStation', 
    "Water Recycling Plant, Al Bada" : '/watermeters-industrial/ALBADA', 
    "High Point Reservoir, Sharma": '/watermeters-industrial/hprstation',
    "Logistics Park - Batching Plants, Sharma": '/watermeters-industrial/logisticspark',
    "Logistics Park - Construction Village 1, Sharma": '/watermeters-industrial/logisticspark',
    "Logistics Park - Construction Village 2, Sharma": '/watermeters-industrial/logisticspark',
    "Logistics Park - Construction Village 3, Sharma": '/watermeters-industrial/logisticspark',
    "Logistics Park - LayDown Area, Sharma": '/watermeters-industrial/logisticspark',
    "Logistics Park - Waste Seggregation, Sharma": '/watermeters-industrial/logisticspark',
    "Neom Bay Airport, Sharma": '/watermeters-industrial/neombayairport',
    "Experience Center, Sharma": '/watermeters-industrial/experiencecenter'
  }