import { ACTIVE, ALERT_NAMES, CRITICAL, DEVICE, DEVICE_TYPE_ENERGY_METER, DEVICE_TYPE_WATER_METER, DUBA_PLANT_SCADA, HIGH, LOW, MEDIUM, RESOLVED, SMART, THRESHOLD } from "src/app/reusable/constant"

export const COLUMN_NAMES = {
  alertName: [],
  severity: [],
  status: [],
  alertType: [],
  deviceTypeOrFacility: [],
  createdOn: [],
  activeFor: [],
  uplinkReferenceKey: [],
  alertID: [],
  acknowledgedBy: [],
  measurableLocation: [],
  site: [],
  zone: [],
  acknowledgedType: [],
  assignedTo: []
}

export class FILTER_COLUMNS_CLASS {
  alertName = []
  acknowledgedType = []
  productType = []
  severity = []
  status = []
  alertType = []
  site = []
  measurableLocation = []
  uplinkReferenceKey = []
  zone = []
}

export const FILTER_COLUMNS = {
  alertName: [],
  acknowledgedType: [],
  productType: [],
  severity: [],
  status: [],
  alertType: [],
  site: [],
  measurableLocation: [],
  uplinkReferenceKey: [],
  zone: []
}


export class ApiResponse {
  status: number;
  data: {
    severity: string[];
    alertType: string[];
    alertName: string[];
    "Device Serial": Array<{
      uplinkReferenceKey: string | null;
      deviceID: string;
    }>;
    acknowledgedType: string[];
    location: Array<{
      zone: string[];
      gateway: string[];
      coordinates: Array<{
        coordinates: {
          latitude: number;
          longitude: number;
        };
      }>;
      site: string[];
      measurableLocation: string[];
    }>;
    alertID: string[];
  };
}


// export class FILTER_COLUMNS {
//   alertName = []
//   acknowledgedType = []
//   deviceTypeOrFacility = []
//   severity = [
//     {
//       selected: false,
//       name: 'Low',
//       severity: LOW,
//       key: "severity"
//     },
//     {
//       selected: false,
//       name: 'Medium',
//       severity: MEDIUM,
//       key: "severity"
//     },
//     {
//       selected: false,
//       name: 'High',
//       severity: HIGH,
//       key: "severity"

//     },
//     {
//       selected: false,
//       name: 'Critical',
//       severity: CRITICAL,
//       key: "severity"
//     }
//   ]
//   status = [
//     {
//       selected: false,
//       name: 'Active',
//       status: ACTIVE,
//       key: "status"
//     },
//     {
//       selected: false,
//       name: 'Resolved',
//       status: RESOLVED,
//       key: "status"
//     }
//   ]
//   alertType = []
//   site = []
//   measuredLocation = []
//   uplinkReferenceKey = []
//   zone = []
// }

export const SYSTEMALERTTYPE = [
  {
    selected: false,
    name: ALERT_NAMES[DEVICE],
    alertType: DEVICE,
    key: "alertType"
  },
  {
    selected: false,
    name: ALERT_NAMES[SMART],
    alertType: SMART,
    key: "alertType"
  }
]
export const USERDEFINEDALERTTYPE = [
  {
    selected: false,
    name: ALERT_NAMES[THRESHOLD],
    alertType: THRESHOLD,
    key: "alertType"
  }
]

export const SORT_FILTER = {
  createdOn: {
    inc: true,
    dec: false,
    hover: false,
    key: "createdOn"
  },
  activeFor: {
    inc: true,
    dec: false,
    hover: false,
    key: "activeFor"
  },
  uplinkReferenceKey: {
    inc: true,
    dec: false,
    hover: false,
    key: "uplinkReferenceKey"
  },
  alertID: {
    inc: true,
    dec: false,
    hover: false,
    key: "alertID"
  },
  acknowledgedBy: {
    inc: true,
    dec: false,
    hover: false,
    key: "acknowledgedBy"
  },
  assignedTo: {
    inc: true,
    dec: false,
    hover: false,
    key: "assignedTo"
  }

}

export const COLUMNSTODISPLAY = ['Alert Name', 'Severity', 'Status', 'Alert Type', 'DeviceTypeOrFacility', 'Device Serial', 'Site', 'Location', 'Zone', 'Created On', 'Active For', 'Alert Id', 'User Action', 'Assigned To']

export const SEARCHFIELDSARR = ["alertName", "severity", "status", "alertType", "deviceTypeOrFacility", "createdOn", "activeFor", "uplinkReferenceKey", "alertID", "acknowledgedType"]


export interface StatusCount {
  Active: number;
  Resolved: number;
  Total: number;
}

export interface FacilityCount {
  Facility: number;
  Site: number;
  Zone: number;
  Total: number;
}

export interface SeverityCount {
  Critical: number;
  Medium: number;
  High: number;
  Low: number;
}

export interface UserActionCount {
  Open: number;
  Close: number;
  Remediation: number;
  Rejected: number;
  Accepted: number;
  Investgating: number;
}

export interface AlertTypeCount {
  Smart: number;
  Threshold: number;
  Device: number;
  Total: number;
}

export interface SeverityBarWidth {
  Critical: number;
  Medium: number;
  High: number;
  Low: number;
}
export const REQUESTBODY = {
  dates: [],
  severity: [],
  status: [],
  alertType: [],
  productType: [],
  deviceID: [],
  alertID: [],
  userAction: [],
  measurableLocation: [],
  site: [],
  zone: [],
  acknowledgedType: [],
  deviceSerialNumber: [],
  alertName: [],
  username: ""
}

export class PAGINATEDALERTSREQUESTCLASS {
  dates
  filter: FILTER_COLUMNS_CLASS
  sortOrder: string
  sortBy: string
  searchParameter: string
  pageNumber: string
  pageSize: string
}


export const PAGINATEDALERTSREQUESTBODY = {
  dates: [],
  filter: FILTER_COLUMNS,
  // sites: {},
  // measurableLocation: [],
  sortOrder: "",
  sortBy: "",
  // username: "",
  searchParameter: "",
  pageNumber: "",
  pageSize: ""
}

export interface PAGINATEDALERTSRESPONSE {
  data: [];
  message: string;
  pageNumber: number;
  pageSize: number;
  status: number;
  totalPages: number;
  totalRecords: number;
}

export const createAlertFilterRequestBody = () => ({
  dates: [],
  currentFilter: "",
  filterValues: {
    alertName: [],
    alertID: [],
    alertType: [],
    severity: [],
    status: [],
    productType: [],
    deviceID: [],
    acknowledgedType: []
  },
  locationFilterValues: {
    site: [],
    zone: [],
    measurableLocation: []
  },
  alertCategory: ""
});


export interface ALERTFILTERAPIRESPONSE {
  data: AlertResponseData;
  message: string;
  pageNumber: number;
  pageSize: number;
  status: number;
  totalPages: number;
  totalRecords: number;
}


export interface AlertResponseData {
  severity: string[];
  alertType: string[];
  alertName: string[];
  DeviceSerial: DeviceSerial[];
  acknowledgedType: string[];
  location: Location[];
  alertID: string[];
  productType: string[];
  status: string[];
  uplinkReferenceKey: string[];
}

export interface DeviceSerial {
  uplinkReferenceKey: string | null;
  deviceID: string;
}

export interface Location {
  site: string[];
  measurableLocation: string[];
  zone: string[];
  gateway: string[];
  coordinates: CoordinateData[];
}

export interface CoordinateData {
  coordinates: Coordinates;
}

export interface Coordinates {
  latitude: number;
  longitude: number;
}






