import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DaterangepickerComponent } from '../../reusable/daterangepicker/daterangepicker.component';
import { Daterangepicker } from 'ng2-daterangepicker';
import { NgxEchartsModule } from 'ngx-echarts';
import { EchartGraphsService } from '../../services/echart-graphs.service';
import { FilterTypesComponent } from './shared-components/filter-types/filter-types.component';
import { SharedDataService } from './shared-meter-type.service';
import { LoaderComponent } from 'src/app/reusable/loader/loader.component';
import { MarketplacedaterangepickerComponent } from "../../reusable/marketplacedaterangepicker/marketplacedaterangepicker.component";
import { RouterModule } from '@angular/router';
import { NavigationComponent } from 'src/app/reusable/navigation/navigation.component';
import { DatePickerComponent } from 'src/app/reusable/date-picker/date-picker.component';
import { FromNowTimePipe } from 'src/app/reusable/pipes/fromNowTime/from-now-time.pipe';
import { AttributeRenamePipe } from 'src/app/reusable/pipes/attribute-rename/attribute-rename.pipe';
import { BreadcrumbComponent } from 'src/app/reusable/breadcrumb/breadcrumb.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PaginationComponent } from 'src/app/reusable/custom-table/pagination/pagination.component';
import { TableSearchComponent } from 'src/app/reusable/custom-table/table-search/table-search.component';
import { TableSelectFilterComponent } from 'src/app/reusable/custom-table/table-select-filter/table-select-filter.component';
import { TableAppliedFiltersComponent } from 'src/app/reusable/custom-table/table-applied-filters/table-applied-filters.component';
import { TableSortIconComponent } from 'src/app/reusable/custom-table/table-sort-icon/table-sort-icon.component';
import { ChartDataNotFoundTemplateComponent } from 'src/app/reusable/chart-data-not-found-template/chart-data-not-found-template.component';
import { DataNotFoundComponent } from 'src/app/reusable/data-not-found/data-not-found.component';
import { CommonDatepickerComponent } from 'src/app/reusable/common-datepicker/common-datepicker.component';
import { ArcgisDeviceLocationComponent } from 'src/app/reusable/arcgis-device-location/arcgis-device-location.component';
import { TableViewComponent } from 'src/app/reusable/table-view/table-view.component';
import { TablePaginationComponent } from 'src/app/reusable/pagination/pagination.component';
import { KpiCardComponent } from 'src/app/reusable/kpi-card/kpi-card.component';
import { NgSelectModule, NgSelectConfig, ɵs } from '@ng-select/ng-select';
import { ChartLegendComponent } from 'src/app/reusable/chart-legend/chart-legend.component';
import { TableFilterForColumnComponent } from '../admin/table-filter-for-column/table-filter-for-column.component';

import { SitesComponent } from 'src/app/reusable/sites/sites.component';
import { SqlInjectionValidationDirective } from 'src/app/directive/sql-injection-validation.directive';
import { SparkLineComponent } from 'src/app/reusable/spark-line/spark-line.component';
import { HighchartsChartModule } from 'highcharts-angular';
import { TagResultsComponent } from '../watermeters-industrial/tag-results/tag-results.component';
import { MatTreeModule } from '@angular/material/tree';
import { AvatarModule } from 'ngx-avatar';
import { HttpClientModule } from '@angular/common/http';
import { AlertsTableComponent } from 'src/app/reusable/alerts-table/alerts-table.component';
import { MaterialModule } from './material/material.module';
import { MeterLogsComponent } from 'src/app/reusable/meter-logs/meter-logs.component';
import { DeviceListingMapComponent } from '../device-management/device-listing-map/device-listing-map.component';
import { HomeComponent } from '../watermeters/home/home.component';
import { ZoneMapComponent } from '../watermeters/home/zone-map/zone-map.component';
import { LandingPageMapComponent } from 'src/app/reusable/landing-page-map/landing-page-map.component';
import { LandingPageEsriMapComponent } from 'src/app/reusable/landing-page-map/landing-page-esri-map/landing-page-esri-map.component';

import { InputTrimDirective } from 'src/app/directive/input-trim.directive';
import { TriggerHistoryComponent } from 'src/app/reusable/trigger-history/trigger-history.component';
import { ThresholdInputValidationDirective } from 'src/app/directive/threshold-input-validation.directive';
import { TextLoaderComponent } from 'src/app/reusable/text-loader/text-loader.component';
import { DeviceListingEsriComponent } from '../device-management/device-listing-map/device-listing-esri/device-listing-esri.component';
import { MatDialogModule } from '@angular/material/dialog';
import { AttributeDropdownComponent } from 'src/app/reusable/attribute-dropdown/attribute-dropdown.component';
import { RemoveFocusDirective } from 'src/app/directive/remove-focus.directive';
import { ExpandedInfoPanelComponent } from 'src/app/reusable/landing-page-map/expanded-info-panel/expanded-info-panel.component';
import { DonutChartComponent } from 'src/app/reusable/donut-chart/donut-chart.component';
import { LinearShimmerComponent } from 'src/app/reusable/shimmer/linear-shimmer/linear-shimmer.component';
import { CircularShimmerComponent } from 'src/app/reusable/shimmer/circular-shimmer/circular-shimmer.component';
import { TokenDropdownComponent } from 'src/app/reusable/token-dropdown/token-dropdown.component';
const COMPONENTS = [
  DaterangepickerComponent,
  MarketplacedaterangepickerComponent,
  FilterTypesComponent,
  LoaderComponent,
  NavigationComponent,
  DatePickerComponent,
  BreadcrumbComponent,
  ChartLegendComponent,
  TableFilterForColumnComponent,
  SitesComponent,
  SparkLineComponent,
  TagResultsComponent,
  AlertsTableComponent,
  TableViewComponent,
  TablePaginationComponent,
  KpiCardComponent,
  PaginationComponent,
  TableSearchComponent,
  TableSelectFilterComponent,
  TableAppliedFiltersComponent,
  TableSortIconComponent,
  ChartDataNotFoundTemplateComponent,
  DataNotFoundComponent,
  CommonDatepickerComponent,
  MeterLogsComponent,
  ArcgisDeviceLocationComponent,
  DeviceListingMapComponent,
  HomeComponent,
  ZoneMapComponent,
  TriggerHistoryComponent,
  LandingPageMapComponent,
  LandingPageEsriMapComponent,
  TextLoaderComponent,
  DeviceListingEsriComponent,
  AttributeDropdownComponent,
  ExpandedInfoPanelComponent,
  DonutChartComponent,
  LinearShimmerComponent,
  CircularShimmerComponent,
  TokenDropdownComponent
];

const PIPES = [
  FromNowTimePipe,
  AttributeRenamePipe
];
const DIRECTIVES = [
  SqlInjectionValidationDirective,
  InputTrimDirective,
  ThresholdInputValidationDirective,
  RemoveFocusDirective
];
const MODULES = [MaterialModule, CommonModule, AvatarModule, HighchartsChartModule, NgSelectModule, ReactiveFormsModule, FormsModule, NgxEchartsModule,MatTreeModule, MatDialogModule];
const SERVICES = [EchartGraphsService,
  SharedDataService
];

@NgModule({
  declarations: [
    ...DIRECTIVES,
    ...COMPONENTS,
    ...PIPES,
  ],
  imports: [
    Daterangepicker,
    RouterModule,
    HttpClientModule,
    ...MODULES,
  ],
  providers: [
    ...SERVICES
  ],
  exports: [
    ...COMPONENTS,
    ...PIPES,
    ...DIRECTIVES,
    ...MODULES,
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class SharedModuleModule { }
