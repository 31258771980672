<div class="metersContentBlk waterMeterInside">
    <div class="stepTwo">
        <div class="backNndSearchBlk">
            <!-- <div class="backSearchBtn" (click)="onClickBack()">
                <span class="material-symbols-outlined backBtn">keyboard_backspace</span>
            </div> -->
            <div class="insideSearchBlk">
                <div class="backSearchBtn" (click)="onClickBack()">
                    <span class="material-symbols-outlined backBtn">keyboard_backspace</span>
                    <span class="panelName" *ngIf="showData(detailedMetersOrFacilitiesCardsInfo.selectedMeterOrFacilityName); else searchHeaderNameShimmer">
                        {{detailedMetersOrFacilitiesCardsInfo.selectedMeterOrFacilityName}}
                    </span>
                </div>
                <ng-template #searchHeaderNameShimmer>
                    <app-linear-shimmer [height]="'10px'" [width]="'100px'"></app-linear-shimmer>
                </ng-template>
                <div class="insideSearchBar">
                    <span class="material-symbols-outlined searchBtn">search</span>
                    <div class="blockHeading">
                        <input type="text" class="form-control" placeholder="Search..." [(ngModel)]="searchedText" (ngModelChange)="showFilterList()">
                    </div>
                    <span *ngIf="searchedText" class="material-symbols-outlined clearIcon" (click)="closeSearchBtn()">close</span>
                </div>
            </div>
        </div>
                <!-- ********************* Meters card ******************** -->
            <ng-container *ngIf="detailedMetersOrFacilitiesCardsInfo.type == 'meters'">
                <div *ngIf="filteredCards.length ;else noResultFound">
                    <div class="stepTwoInside" *ngFor="let siteInfo of filteredCards; let i = index">
                        <div class="metersMiddleContent">
                            <div class="totalDeviceCount">
                              <ng-container *ngIf="showData(siteInfo.site); else siteHeadingShimmer">
                                <div class="contentBlockTotalDevice">
                                    <div class="totalDeviceCont" style="max-width: 70%;">
                                        <span class="material-symbols-outlined">location_on</span>
                                        <div class="ellipsis">{{siteInfo.site}}</div>
                                        <a class="viewMoreLink" [routerLink]="dashboardUrlForDescription[detailedMetersOrFacilitiesCardsInfo.selectedMeterOrFacilityName]" target="_blank">
                                            <span class="material-symbols-outlined">arrow_outward</span>
                                        </a>
                                    </div>
                                    <div class="warningIconWithText">
                                        <span class="material-symbols-outlined">warning</span> {{siteInfo?.activeAlerts}}
                                        <div class="customCheckBox" (click)="$event.stopPropagation();toggleSelection(detailedMetersOrFacilitiesCardsInfo.type, siteInfo)">
                                            <div class="cutsom-checkbox-row">
                                                <input type="checkbox" [(ngModel)]="siteInfo.selected">
                                                <label>&nbsp;</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                              </ng-container>
                            <ng-template #siteHeadingShimmer>
                                <app-linear-shimmer [height]="'10px'" [width]="'250px'"></app-linear-shimmer>
                            </ng-template>
                            </div>
                            <div class="totalDeviceCount flex-wrapper">
                                <div>
                                    <div class="contentBlockTotalDevice">
                                        <div class="totalDeviceCont pb-10">
                                             <!--********************** This info must be come form api currently quick fix it **************  -->
                                            {{siteInfo?.site?.includes('Royal Palace') ? 'Total Tags' : 'Live Devices'}}
                                        </div>
                                    </div>
                                    <div class="subTotalDevice">
                                       <ng-container *ngIf="showData(siteInfo?.totalDevices); else deviceCountShimmer">
                                            <span class="dots online"></span>{{siteInfo.activeDevices}} 
                                            <div class="subText">/ {{siteInfo.totalDevices}}</div>
                                       </ng-container>
                                       <ng-template #deviceCountShimmer>
                                            <app-linear-shimmer [height]="'10px'" [width]="'200px'"></app-linear-shimmer>
                                        </ng-template>
                                    </div>
                                </div>
                                <app-donut-chart [live]="siteInfo.activeDevices" [total]="siteInfo.totalDevices"></app-donut-chart>
                            </div>
                            <div class="totalDeviceCount">
                                <div class="contentBlockTotalDevice">
                                    <div class="totalDeviceCont">
                                        Consumption <span>(MTD)</span>
                                    </div>
                                    <div class="totalDeviceCont"  *ngIf="showData(siteWiseConsumption[siteInfo.site]); else deviceConsumptionShimmer">
                                        {{getValidValue(siteWiseConsumption[siteInfo.site]) | number: '1.2-2' }} 
                                        <span style="font-weight: bold;" [innerHTML]="getSanitizedConsumptionUnit([detailedMetersOrFacilitiesCardsInfo.productName])"></span>
                                    </div>
                                    <ng-template  #deviceConsumptionShimmer >
                                        <app-linear-shimmer [height]="'10px'" [width]="'50px'"></app-linear-shimmer>
                                    </ng-template>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>

            <!-- ********************* Facilities card ******************** -->
            <ng-container *ngIf="detailedMetersOrFacilitiesCardsInfo.type == 'facilities'">
                <ul class="singleBlock" *ngIf="filteredCards.length ;else noResultFound">
                    <li *ngFor="let facilityInfo of filteredCards; let i = index">
                        <mat-accordion class="example-headers-align" multi>
                            <mat-expansion-panel [expanded]="i == 0" #panel>
                              <mat-expansion-panel-header>
                                <div class="my-panel">
                                    <div class="facilitiesListBlk">
                                        <div class="facilitiesListName">
                                            <span class="industrialPlantsTitle">{{facilityInfo.description}}</span>
                                            <a *ngIf="panel.expanded" (click)="$event.stopPropagation()" class="viewMoreLink" [routerLink]="dashboardUrlForDescription[facilityInfo.description]" target="_blank">
                                                <span class="material-symbols-outlined">arrow_outward</span>
                                            </a>
                                        </div>
                                    </div>
                                    <div class="customCheckBox" (click)="$event.stopPropagation(); toggleSelection(detailedMetersOrFacilitiesCardsInfo.type, facilityInfo)">
                                        <div class="cutsom-checkbox-row">
                                            <input type="checkbox" [(ngModel)]="facilityInfo.selected">
                                            <label>&nbsp;</label>
                                        </div>
                                    </div>
                                </div>
                              </mat-expansion-panel-header>
                              <div class="stepTwoInside">
                                <div class="metersMiddleContent">
                                    <div class="totalDeviceCount">
                                        <div class="contentBlockTotalDevice">
                                            <div class="totalDeviceCont plantStatus">
                                                Total Tags
                                                <span class="totalTagsCount">{{facilityInfo.totalTags}}</span>
                                            </div>
                                            <div class="totalDeviceCont plantStatus">
                                                <div class="statusGroupBlk">
                                                    <div class="statusText" [ngClass]="facilityInfo.status === 'Live' ? 'online': 'off'">{{facilityInfo.status}}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="totalDeviceCount">
                                        <div class="contentBlockTotalDevice">
                                            <div class="totalDeviceCont">
                                                Last Data Recieved
                                            </div>
                                            <div class="totalDeviceCont">
                                                {{facilityInfo.lastDataReceivedOn | date:'MMM dd, yyyy HH:mm:ss'}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                              </div>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </li>
                </ul>
            </ng-container>

            <!-- ******************** No result found ******************** -->
            <ng-template #noResultFound>
                <div class="align-items-center noResultFoundInside stepTwoInside ">
                    <p>No matching results found <span>{{searchedText}}</span></p>
                </div>
            </ng-template>

    </div>
</div>