<div class="customMapFilter">
    <!-- ********************* Map's Left sife options ********************* -->
    <div class="leftBlk" id="leftOptions">
        <ng-container *ngIf="mapInitialized">

            <!--********** This will show the Meter and Industrial plants kpi card ******************-->
            <div class="metersContentBlk" *ngIf="showMetersList" [ngClass]="{'fullScreenMode': fullScreenModeEnabled}">
                <div class="stepOne">

                    <!--******************  Meters Information  ************************-->
                    <ul>
                        <li class="searchMapBlk">
                            <div class="searchMapArea">
                                <span class="material-symbols-outlined searchBtn">search</span>
                                <div class="blockHeading">
                                    <input type="text" class="form-control" placeholder="Search meters & facilities"
                                        [(ngModel)]="searchText"
                                        (ngModelChange)="filterCardsOnSearch()"
                                    />
                                    <span *ngIf="searchText" class="material-symbols-outlined clearIcon"  (click)="clearAllAppliedFilters()">close</span>
                                </div>
                                <div class="buttonCreate">
                                    <div *ngIf="isFilterApplied()" [ngClass]="{'filterActive': isFilterApplied()}" >{{appliedFilterCount()}}</div>
                                    <span  (click)="datePickerDefaultAction()" class="material-symbols-outlined filterBtn" >filter_list</span>
                                </div>
                            </div>
                            <div (click)="datePickerDefaultAction()" class="searchPopup" *ngIf="showFilterPopup">
                                <div class="searchListing" (click)="datePickerDefaultAction()">
                                    <div class="lineOfBusiness">
                                        <span>Line of Business</span>
                                        <span class="clearAll" (click)="clearAllAppliedFilters()">Clear all</span>
                                    </div>
                                    <div class="businessList">
                                        <span class="filterSelected" [ngClass]="{'filterSelected': item.selected}" *ngFor="let item of lobFilterList" (click)="handleFilterSelection(item)">
                                            {{item.lob}}
                                        </span>
                                    </div>
                                </div>
                                <div class="searchListing">
                                    <div class="lineOfBusiness">
                                        <span>Department</span>
                                    </div>
                                    <div class="businessList">
                                        <span [ngClass]="{'filterSelected': item.selected}" *ngFor="let item of sectorFilterList" (click)="handleFilterSelection(item)">
                                            {{item.sector}}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="" *ngFor="let item of metersList">
                            <div class="cursor-pointer" (click)="fetchDevicesOfMeter(item)">
                                <div class="metersHeaderBlk">
                                   <ng-container *ngIf="showData(item?.description); else deviceHeadingShimmer">
                                    <div class="blockHeading">
                                        <span class="material-symbols-outlined">{{item.icon}}</span>
                                        {{item.description}} 
                                        <a (click)="$event.stopPropagation()" [routerLink]="[item.dashboardUrl]" class="viewMoreLink" target="_blank">
                                            <span class="material-symbols-outlined">arrow_outward</span>
                                        </a>
                                    </div>
                                    <!-- ********************* Active alerts only show when alerts > 0 -->
                                    <div class="warningIconWithText" *ngIf="item.activeAlerts.length" [matTooltip]="'Active Alerts'" matTooltipClass="above" matTooltipPosition="above">
                                        <span class="material-symbols-outlined">warning</span> {{item.activeAlerts}}
                                    </div>
                                   </ng-container>
                                   <ng-template #deviceHeadingShimmer>
                                        <app-linear-shimmer [height]="'10px'" [width]="'250px'"></app-linear-shimmer>
                                   </ng-template>
                                </div>

                                <div class="metersMiddleContent">
                                    <div class="totalDeviceCount flex-wrapper">
                                        <div>
                                            <div class="contentBlockTotalDevice">
                                                <div class="totalDeviceCont">
                                                    <!--********************** This info must be come form api currently quick fix it **************  -->
                                                    {{item?.description?.includes('Royal Palace') ? 'Total Tags' : 'Live Devices'}}
                                                </div>
                                            </div>
                                            <div class="subTotalDevice">
                                                <ng-container  *ngIf="showData(item?.totalDevices); else deviceCountShimmer">
                                                    <span class="dots online"></span>{{item.liveDevices | number: '1.0-0'}} 
                                                    <div class="subText">/ {{item.totalDevices | number: '1.0-0'}}</div>
                                                </ng-container>
                                                <ng-template #deviceCountShimmer>
                                                    <app-linear-shimmer [height]="'10px'" [width]="'200px'"></app-linear-shimmer>
                                                </ng-template>
                                            </div>
                                        </div>
                                        <!-- ******************************** Donut Chart **************************** -->
                                        <app-donut-chart [live]="item.liveDevices" [total]="item.totalDevices"></app-donut-chart>
                                    </div>
    
                                    <div class="totalDeviceCount">
                                        <div class="contentBlockTotalDevice">
                                            <div class="totalDeviceCont">
                                                Consumption <span>(MTD)</span>
                                            </div>
                                            <div class="totalDeviceCont" *ngIf="showData(consumptionByProductNames[item.productName]); else deviceConsumptionShimmer">
                                                {{ getValidValue(consumptionByProductNames[item.productName]) | number: '1.2-2' }}                  
                                                <span style="font-weight: bold;" [innerHTML]="getSanitizedConsumptionUnit(item.productName)"></span>                          
                                            </div>
                                            <ng-template  #deviceConsumptionShimmer >
                                                <app-linear-shimmer [height]="'10px'" [width]="'50px'"></app-linear-shimmer>
                                            </ng-template>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                      
                    </ul>
                    
                    <!-- ******************* Industrial Plants Information ******************* -->
                    <ul class="singleBlock" *ngIf="focusedSiteOnMap === TEXT_ALL">
                        <div *ngFor="let facility of filteredSelectedFacilities; let i = index" style="width: 100%;">
                            <ng-container *ngIf="i === firstOccurenceOfFacilitySystemType.scada">
                                <span class="facilityHeadingBlk">SCADA Systems</span>
                            </ng-container>
                            <ng-container *ngIf="i === firstOccurenceOfFacilitySystemType.transmission">
                                <span class="facilityHeadingBlk">Transmission Points</span>
                            </ng-container>

                            <li>
                                <div class="insideChartContentBlk">
                                    <div class="facilitiesListBlk wd100">
                                        <div class="facilitiesListName" (click)="showDetailedFacilityFeatureLayerInformation(facility)" [matTooltip]="facility.description" matTooltipClass="above" matTooltipPosition="above">
                                            <span class="industrialPlantsTitle">{{facility.description}}</span>
                                        </div>
                                        <div class="statusGroupBlk">
                                            <div class="statusText" [ngClass]="facility.status === 'Live' ? 'live': 'offline'">{{facility.status}}</div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </div>
                    </ul>

                    <div *ngIf="fetchFacilityLoader" style="display: flex;justify-content: center; align-items: center;">
                        <img src="../../../assets/images/loader.gif" width="50px" height="50px"/>
                    </div>
                </div>

                <!-- ****************** No result found ******************** -->
                <ng-container *ngIf="showNoResultFound()">
                    <div class="align-items-center noResultFound">
                        <p>No matching results found <span>{{searchText}}</span></p>
                    </div>
                </ng-container>
            </div>
           
            <!-- This will show the meter's detailed information -->
            <div *ngIf="!showMetersList">
                <!-- <div class="stepTwo"> -->
                    <!--********************* Site Wise details information with consumption ************ -->
                    <app-expanded-info-panel [detailedMetersOrFacilitiesCardsInfo]="detailedMetersOrFacilitiesCardsInfo" 
                        [detailedCardsInfoLoader]="detailedCardsInfoLoader" [facilityInfoSelected]="facilityInfoSelected" (handleBack)="showMeters()" 
                        [siteWiseConsumption]="siteWiseConsumption" [consumptionUnit]="consumptionUnit"
                        (FilterSitesOnMap)="toggleIndividualSiteFeatureLayerVisibility($event)"
                        (FilterFacilityOnMap)="toggleIndividualFacilityFeatureLayerVisibility($event)"
                    >
                    </app-expanded-info-panel>
            </div>
        </ng-container>
    </div>
    
    <!-- ********************* Map's Right side options ********************* -->
    <div class="rightBlk" id="rightOption">
       <!-- Ṛight options will be added here -->
    </div>

    <!-- ********************* Map's Loader ****************************** -->
     <div *ngIf="loading" class="mapLoader">
        <div class="spinner">
            <div class="loader"></div>
       </div>
       <div>Loading Map</div>
     </div>
    <!-- <div *ngIf="loading" class="spinner fullScreen">
         <div class="loader"></div>
    </div> -->

    <!-- ************************ Map's Component ************************* -->
    <app-landing-page-esri-map 
        [selectedHeatMap]="selectedHeatMap" style="position: relative;" [sitesList]="sitesList"
        (mapInitialized)="esriMapInitialized($event)" [selectedProductNames]="selectedProductNames"  [productNameList]="productNameList" [selectedLobs]="selectedLobs"
        (showProductNameOnFocusedSite)="showMeterOnSelectedSite($event)" [facilityInfoSelected]="facilityInfoSelected" (showListVisibleFacilities)="showListVisibleFacilities($event)"
        [loading]="loading" (showLoader)="showLoader($event)" (showSiteOnZoomEvent)="showSiteOnZoomEvent($event)"
        [detailedMetersOrFacilitiesCardsInfo]="detailedMetersOrFacilitiesCardsInfo" [showMetersList]="showMetersList" [consumptionUnit]="consumptionUnit"
    >
    </app-landing-page-esri-map>
    
</div>
