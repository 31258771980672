import { Injectable } from '@angular/core';
import { RegistrationService } from '../../services/registration.service';
import * as moment from 'moment';
import { DATE_TIME_FORMAT, DEVICE_TYPE_ENERGY_METER } from '../../constant';
import { TelemetryService } from '../../services/telemetry.service';

@Injectable({
  providedIn: 'root'
})
export class LandingPageEsriCacheService {
  private deviceDataMap: Map<string, any> = new Map();
  private cacheLimit: number;

  constructor(
    private registrationService: RegistrationService,
    private telemetryService: TelemetryService
  ) {
  }

  initializeCacheSize(cacheLimit:number) {
    this.cacheLimit = cacheLimit;
  }

  getSelectedDeviceTypeOnSite(site, productName) {
    return `${site} ${productName}`;
  }
   // Method to fetch device data, either from cache or via API
   async getDevicesData(sitesList:string[], site:string, productName: string): Promise<any> {
    const selectedDeviceTypeOnSite = this.getSelectedDeviceTypeOnSite(site, productName);
    if (this.deviceDataMap.has(selectedDeviceTypeOnSite)) {
      // Move the accessed deviceType to the end to mark it as recently used
      const deviceData = this.deviceDataMap.get(selectedDeviceTypeOnSite);
      this.deviceDataMap.delete(selectedDeviceTypeOnSite);
      this.deviceDataMap.set(selectedDeviceTypeOnSite, deviceData);
      // console.log(`${deviceType} data fetched from cache.`);
      return Promise.resolve({deviceData, fetchedFromCachedService:true});
    } else {
      // Fetch from API since it's not in the cache
      const payload = {filter: {[site]: sitesList[productName][site]}, productName};
      const deviceData = await this.registrationService.getDeviceDetailsBySiteAndProductName(payload).toPromise();
      this.cacheDeviceData(productName, site, deviceData);
      return {deviceData, fetchedFromCachedService:false};
    }
  }

   // Method to add new device data to the cache
   private cacheDeviceData(productName: string, site:string, deviceData: any) {
    // Check if the cache size exceeds the limit
    if (this.deviceDataMap.size >= this.cacheLimit) {
      // Remove the first (least recently used) item
      const oldestDeviceType = this.deviceDataMap.keys().next().value;
      this.deviceDataMap.delete(oldestDeviceType);
      // console.log(`${oldestDeviceType} data removed from cache.`);
    }
      // Add the new device data
      const selectedDeviceTypeOnSite = `${site} ${productName}`;
      this.deviceDataMap.set(selectedDeviceTypeOnSite, deviceData);
      // console.log(`${selectedDeviceTypeOnSite} data added to cache.`);
  }

  async getConsumptionsOfDevices(site, productName, devicesFeatureLayer) {
    const start = moment().subtract(29, 'days').startOf('day').format(DATE_TIME_FORMAT);
    const end = moment().endOf('day').format(DATE_TIME_FORMAT);

    const payload = {
      dates: [start, end],
      productName: productName,
      site: site
    }
    const devicesConsumptions =  await this.telemetryService.deviceWiseConsumptionForSite(payload).toPromise();
    return {
      productName,
      devicesConsumptions
    }
      
  }

  async getFacilitiesParameterData(site, deviceTypes) {
    const start = moment().subtract(29, 'days').startOf('day').format(DATE_TIME_FORMAT);
    const end = moment().endOf('day').format(DATE_TIME_FORMAT);
    const payload = {
      dates : [start, end],
      systemType: deviceTypes
    }

    const res = await this.registrationService.getFacilitiesParametersData(payload).toPromise();
    const parametersData = Object.values(res).reduce((acc:any[], val:{}) => ({...acc, ...val}), {});

    //update the parameter data in cache 
    Object.keys(deviceTypes).forEach(deviceType => {
      const selectedDeviceTypeOnSite = this.getSelectedDeviceTypeOnSite(site, deviceType);
    if (this.deviceDataMap.has(selectedDeviceTypeOnSite)) {
        const deviceData = this.deviceDataMap.get(selectedDeviceTypeOnSite);
        deviceData.forEach(device => {
            device['percentageData'] = parametersData[device['uplinkReferenceKey']]['percentageDataFetched'];
        })
      }
    })
  return parametersData;
 
  }

  clearCache(): void {
    this.deviceDataMap.clear();
  }

  hasDeviceDataMap(selectedDeviceTypeOnSite) {
    return this.deviceDataMap.has(selectedDeviceTypeOnSite);
  }

  getDeviceDataMap(selectedDeviceTypeOnSite) {
    return this.deviceDataMap.get(selectedDeviceTypeOnSite);
  }
}
