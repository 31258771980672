import { Injectable } from '@angular/core';
import { loadModules } from "esri-loader";
import { ElementRef } from '@angular/core';
import { MapInfo } from './interfaces';
import { createCustomPaginationForPopupHeader } from "src/app/services/arcgis-map/arcgis-map-templates";
import { ArcgisMapsDeviceConfigurationsService } from './devices-fields-template-renderer/arcgis-maps-device-configurations.service';
import { Subject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class ArcgisMapService {
  private map: any;
  private view: any;
  private recenterIcon:any
  private selectedGeometry:any
  private currentZoom:number
  private currentIndex:number;
  private totalOverllapedDevice;
  private selectedFeatureLayer;
  mapInfo:MapInfo
  mapReCentered = new Subject<void>();


  constructor(
    private arcgisMapsDeviceConfigurationsService: ArcgisMapsDeviceConfigurationsService
  ) { }

  async initializeMap(mapViewEl: ElementRef, mapInfo:MapInfo): Promise<void> {
    try {
      const [esriConfig, Map, MapView, Fullscreen, Zoom] = await loadModules([
        "esri/config",
        'esri/Map',
        'esri/views/MapView',
        "esri/widgets/Fullscreen",
        "esri/widgets/Zoom"
      ]);
      esriConfig.apiKey = "AAPK42c8946bf93b4d33b26315762163300af2a7iancfHwc19TRSo35thrPVaa1vCkSW4W718K72WpLRqT5BbYFE2Wvz5E2zHFJ"

      this.map = new Map({
        basemap: 'osm-streets-relief'
      });

      this.mapInfo = mapInfo;

      this.view = new MapView({
        container: mapViewEl.nativeElement,
        map: this.map,
        center: this.mapInfo.center,
        zoom: this.mapInfo.zoom
      });

      // Add the full screen option to map
      const fullscreen = new Fullscreen({
        view: this.view
      });

      // added fullscreen widget
      this.view.ui.add(fullscreen, {
        position:"bottom-right",
        index: 2
      });

      // added recenter icon widget
      const recenterIcon = this.getRecenterIcon();

      this.view.ui.add(recenterIcon, {
        position: "bottom-right",
        index: 0
      });

      this.view.ui.remove('zoom');

      const zoomWidget = new Zoom({
        view: this.view
      });
   
      // Add the zoom widget to the top right corner of the view
      this.view.ui.add(zoomWidget, {
        position: 'bottom-right',
        index: 1
      });

      return this.view.when().then(() => {
        console.log('Map is ready');
      });
      

    } catch (error) {
      console.error('Error loading the ArcGIS modules:', error);
    }
  }

  setMapInfo(mapInfo:MapInfo) {
    this.mapInfo = mapInfo;
    this.reCentreWithEventEmit(this.mapInfo, false);
  }

  async reCentreWithEventEmit(mapInfo:MapInfo, emitEvent = false) {
    const [Point] = await loadModules([
      "esri/geometry/Point"
    ]);
    const centerPoint = new Point({
      longitude: mapInfo.center[0],
      latitude: mapInfo.center[1],
      spatialReference: { wkid: 4326 }  // WGS84 spatial reference
    });
    this.view.goTo({
      center: centerPoint,
      zoom: mapInfo.zoom
    }, {
      duration: 1500,
      easing: 'easy-in-out'
    })
    
    if(emitEvent) {
      const timeout = setTimeout(() => {
        this.mapReCentered.next(); // emit the map reCenter event
        clearTimeout(timeout) // carefully subscribe timeout for preventing redundant calling 
      }, 2000) // take little more than time as easy-in-out of recentering  
    }
  }

  

  getRecenterIcon() {
    this.recenterIcon = document.createElement('div');
    this.recenterIcon.className = 'custom-icon';
    this.recenterIcon.style.backgroundImage = 'url("assets/images/recenter.svg")';
    this.recenterIcon.style.backgroundSize = 'contain';
    this.recenterIcon.style.width = '32px';
    this.recenterIcon.style.height = '32px';
    this.recenterIcon.style.cursor = 'pointer';
    this.recenterIcon.style.backgroundSize = '60%';
    this.recenterIcon.style.backgroundRepeat =  'no-repeat';
    this.recenterIcon.style.backgroundPosition = 'center center';
    this.recenterIcon.style.backgroundColor = 'white';

    this.recenterIcon.title = 'Re-center';

    this.recenterIcon.addEventListener('click', () => {
      this.reCentreWithEventEmit(this.mapInfo, true);
    })
    return this.recenterIcon;
  }

  addCustomActionOnPopupTooltipAndSubscibeActionEvent(systemType) {
    this.view.popup.watch('visible', (isVisible) => {
      if (isVisible) {
        let current = this.currentIndex+1;
        let totalCount = this.totalOverllapedDevice.length;
        setTimeout(() => {
          const popupHeader = document.querySelector('.esri-popup__inline-actions-container'); // Adjust the selector as necessary
          if (popupHeader) {
            createCustomPaginationForPopupHeader(popupHeader, current, totalCount)
            let paginationText = document.getElementById('esri-pagination-text');
             // listen event for click next
            document.getElementById("esri-pagination-next")?.addEventListener("click", () => {
              if(this.currentIndex+1 < this.totalOverllapedDevice.length ) {
                this.currentIndex = this.currentIndex +1 ;
                const selected = this.totalOverllapedDevice[this.currentIndex];
                const data = this.selectedFeatureLayer.source.items.filter((source) => source.attributes.oid === selected.graphic.attributes.oid)[0];
                paginationText.textContent = `${this.currentIndex+1} of ${this.totalOverllapedDevice.length}`;
                this.view.popup.content = this.arcgisMapsDeviceConfigurationsService.createTemplate(data.attributes, systemType);
                this.setSelectedGeometry(selected.graphic.geometry);
              }
            });

           // listen event for click prev
           document.getElementById("esri-pagination-prev")?.addEventListener("click", () => {
            if(this.currentIndex > 0) {
              this.currentIndex = this.currentIndex - 1;
              const selected = this.totalOverllapedDevice[this.currentIndex];
              const data = this.selectedFeatureLayer.source.items.filter((source) => source.attributes.oid === selected.graphic.attributes.oid)[0];
              paginationText.textContent = `${this.currentIndex+1} of ${this.totalOverllapedDevice.length}`;
              this.view.popup.content = this.arcgisMapsDeviceConfigurationsService.createTemplate(data.attributes, systemType);
              this.selectedGeometry = selected.graphic.geometry;
            }
           });

           // listen for zoom event

           document.getElementById("custom-esri-zoom")?.addEventListener("click", () => {
            this.currentZoom = this.currentZoom + 1;  
            this.view.goTo( {
                target: this.selectedGeometry,
                zoom: this.currentZoom
              },
              {
                duration: 300, // Duration in milliseconds (e.g., 2000ms = 2 seconds)
                easing: "ease-in-out" // Easing function for smooth transition
              })
          })
        }
      }, 100); // Delay to ensure the popup header is available
    }
  });
  }

  setSelectedGeometry(geometry) {
    this.selectedGeometry = geometry;
  }

  setCurrentZoom(zoom) {
    this.currentZoom = zoom;
  }

  setCurrentIndex(index) {
    this.currentIndex = index;
  }

  setTotalOverllapedDevices(overllapedDevices) {
    this.totalOverllapedDevice = overllapedDevices;
  }

  setSelectedFeatureLayer(selectedFeatureLayer)  {
    this.selectedFeatureLayer = selectedFeatureLayer;
  }

  getMapInstance(): any {
    return this.map;
  }

  getViewInstance(): any {
    return this.view;
  }

  getSelectedGeometry() {
    return this.selectedGeometry;
  }

  getCurrentZoom() {
    return this.currentZoom;
  }

  getCurrentIndex() {
    return this.currentIndex;
  }

  getTotalOverllapedDevices() {
    return this.totalOverllapedDevice;
  }

  getSelectedFeatureLayer() {
    return this.selectedFeatureLayer;
  }
}
