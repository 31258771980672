import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { HomeService } from "./home.service";
import { SharedServiceService } from 'src/app/services/shared-service.service';
import { SocketService } from 'src/app/services/socket.service';
import { renderChart } from "src/app/reusable/chart-util/aggregation-line-bar-area";
import { DATE_TIME_FORMAT, DATE_TIME_FORMAT_DAY_END, DATE_TIME_FORMAT_DAY_START, DEVICE_TYPE_GATEWAY, DEVICE_TYPE_WATER_METER_GATEWAY, meterType, severityColor } from "src/app/reusable/constant";
import * as d3 from "d3";
import * as _ from "underscore";
import { Router } from "@angular/router";
import { initializeDateRange, setDateRange } from "src/app/reusable/date-util";
import { IAlertDataModel } from "../../alerts/alerts.interface";
import { DataList, ForecastinGroups, IRegisteredDevice, Sites } from "../../device-management/interfaces";
import { DeviceManagementService } from "../../device-management/services/device-management.service";
import { breakPoints } from "src/app/reusable/constant";
import { BreakpointService } from "src/app/services/breakpoint-service/breakpoint.service";
import {
  NC1_BASE_CAMP,
  ISKRA_DUBAI_LAB,
  WATER_METER_STITES,
  ENERGY_METER_SITES,
  COOLING_METER_SITES,
  SINDALAH_ISLAND
} from "src/app/reusable/siteConstants";
import { RegistrationService } from "src/app/reusable/services/registration.service";
import { TelemetryService } from "src/app/reusable/services/telemetry.service";
import { AlertsManagementService } from "src/app/reusable/services/alerts-management.service";
import { Subscription } from "rxjs";
import * as moment from "moment";
import { lineGraphForNative } from "src/app/reusable/chart-util/line-graph-native";
import { forecastinglineGraphForNative } from "src/app/reusable/chart-util/forecasting-line-graph-native";
import { downloadData } from "src/app/reusable/chart-util/chart-helper";
import { NgSelectComponent } from "@ng-select/ng-select";
// consumptionAggretType options
const averageConsumption = "Average Consumption";
const totalConsumption = "Total Consumption";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.css", '../../../../assets/Reusable-CSS/main.scss', '../../../../assets/Reusable-CSS/form.scss']
})

export class HomeComponent implements OnInit {
  @Input() accessingFrom;
  @Output() mapGraphToggle = new EventEmitter<any>();
  @ViewChild('selectDropdown') select: NgSelectComponent;

  public startDate = null;
  public endDate = null;
  public severityColor = severityColor;
  public statusBasedLeakageDataChartOption;
  isMap = false;

  // alerts related variable
  public allAlerts: IAlertDataModel[];
  public alertLoader: boolean = false;
  public deviceCountLoader: boolean = false;

  // Leakage Alerts start
  public leakageDataLoader = false;
  public leakageData;
  // Leakage Alerts end
  // Leakage Alert Count start 
  public leakageDataAggregate;
  public leakageDataAggregateNoDataFound = false;
  public leakageDataAggregateLoader = false;
  public leakageAlertAggregate = 'daily';
  // Leakage Alert Count end 
  // Water Leaked start 
  public leakageWaterLeaked;
  public leakageWaterLeakedNoDataFound = false;
  public leakageWaterLeakedLoader = false;
  public leakageWaterLeakedAggregate = 'daily';
  // Water Leaked end 
  // Average Consumption Per Household start
  public averageConsumptionPerHousehold;
  public averageConsumptionPerHouseholdNoDataFound = false;
  public averageConsumptionPerHouseholdLoader = false;
  public averageConsumptionPerHouseholdStatLoader = false;
  public averageConsumptionPerHouseholdAggregate = 'hourly';
  public consumptionAggretType = totalConsumption;
  // Average Consumption Per Household end
  // Average Consumption Per Device start
  public averageConsumptionPerDevice;
  public averageConsumptionPerDeviceNoDataFound = false;
  public foreCastingNoDataFound = false;
  public averageConsumptionPerDeviceLoader = true;
  public averageConsumptionPerDeviceAggregate = 'monthly';
  // Average Consumption Per Device end
  public totalAlert: number = 0;
  public activeAlerts: number = 0;
  public zonewiseDivision: any;
  public zoneList = [];
  public showMap = false;
  public zonewiseAlert = {};
  public meterType: string;
  public percentageValue;
  public percentageLoader = false;
  public zoneLoader = false;
  public consumptionChange = 0;

  // device count
  public activeDevices: number = 0;
  public totalDevices: number = 0;
  public activeGateways: number = 0;
  public totalGateways: number = 0;
  public showMeterCount: boolean = false;

  // deviceListing variable
  public deviceListing: IRegisteredDevice[];
  public filterDeviceListing: IRegisteredDevice[];
  public deviceSubgroups = [];
  public searchDevice = "";
  public mapData = [];
  breakPoints = breakPoints;
  sites: Sites = {};
  selectedSites;
  selectedZones = [];
  consumptionGraphSubscription: Subscription;
  selectedDevice = [];
  filter: any = {};
  forecastinGroups: ForecastinGroups[];
  dataList: any[] = [];
  allDeviceData = [];
  maxResults: number = 20;
  offset: number = 0;
  loading: boolean = false;
  filterValue: string = '';
  debouncedSearch = _.debounce(() => this.searchDevices(), 1000);
  expectedFrequency: string;
  selectedDeviceType

  constructor(
    public sharedService: SharedServiceService,
    public homeService: HomeService,
    private registrationService: RegistrationService,
    private alertsManagementService: AlertsManagementService,
    private io: SocketService,
    private router: Router,
    private breakpointService: BreakpointService,
    private telemetryService: TelemetryService
  ) {
    this.averageConsumptionPerHouseholdStatLoader = true;
    this.averageConsumptionPerHouseholdLoader = true;
    this.averageConsumptionPerHouseholdNoDataFound = true;
  }

  ngOnInit() {
    const date = initializeDateRange();
    this.startDate = date.startDate;
    this.endDate = date.endDate;
    this.getMeterTypeAndSites();
    this.offset = 0;
    this.deviceListing = [];
    this.fetchMore();
    // subscribe for change in breakpoint(Mobile, Tablet, Desktop) and there orientation
    this.consumptionGraphSubscription = this.breakpointService.breakpointChanges.subscribe(matches => {
      this.consumptionTypeChange(this.consumptionAggretType);
      // this.forecastingByDeviceId();
      if (this.selectedDevice) {
        this.getConsumptionByDeviceId(this.averageConsumptionPerDeviceAggregate)
      }
    });
  }

  async getZonewiseCount() {
    if (!Object.keys(this.filter)?.length) {
      this.zoneList = [];
      return
    };
    this.zoneLoader = true;
    const payload = { deviceType: this.selectedDeviceType, siteZoneFilter: this.filter };
    let zoneList = [];
    let gatewayCount = [];
    let alertsCount = [];
    await this.registrationService.getZoneWiseMeterCount(payload)
      .toPromise().then(resp => {
        zoneList = resp;
      })

    zoneList?.sort(function (a, b) {
      return a.zone - b.zone;
    });
    if (this.selectedZones?.length) {
      const zones = zoneList?.filter(zone => this.selectedZones?.includes(zone.zone));
      this.zoneList = zones;
    } else {
      this.zoneList = zoneList;
    }
    this.zoneLoader = false;

    if (this.meterType !== 'energy') {
      await this.registrationService.getZoneWiseGatewayCount(payload)
        .toPromise().then(resp => {
          gatewayCount = resp;
        })

      let activeGateways = 0;
      let totalGateways = 0;

      this.zoneList = this.zoneList?.map(zone => {
        if (!gatewayCount?.length) return zone;
        const gateway = gatewayCount?.find(gateway => (gateway.site === zone.site) && (gateway.zone === zone.zone))
        if (!gateway) return zone;
        activeGateways = activeGateways + gateway?.live;
        totalGateways = totalGateways + gateway?.gateways;
        zone['gatewayTotal'] = gateway?.gateways;
        zone['gatewayLive'] = gateway?.live;
        return zone;
      })

      this.showMeterCount = true;
      this.activeGateways = activeGateways;
      this.totalGateways = totalGateways;
    }

    payload['username'] = 'system';

    await this.alertsManagementService.getZoneWiseAlertsCount(payload)
      .toPromise().then(resp => {
        alertsCount = resp;
      })


    this.zoneList = this.zoneList?.map(zone => {
      const alerts = alertsCount?.find(data => (data.site === zone.site) && (data.zone === zone.zone))
      if (!alerts) return zone;
      zone['activeAlerts'] = alerts?.activeAlerts;
      return zone;
    })

  }

  getMeterTypeAndSites() {
    if (this.router.url.includes('water')) {
      this.meterType = 'water';
    } else if (this.router.url.includes('energy')) {
      this.meterType = 'energy';
    } else if (this.router.url.includes('home')) {
      this.meterType = this.showContent
    } else {
      this.meterType = 'cooling';
    }

    this.selectedDeviceType = meterType[this.meterType]

    this.getSites();
  }

  getSites() {
    this.deviceCountLoader = true;
    this.alertLoader = true;
    this.percentageLoader = true;
    this.registrationService.getZoneBySite(this.selectedDeviceType)
      .subscribe(res => {
        const sites: Sites = {};
        for (const key in res) {
          if (key) sites[key] = res[key];
        }
        this.sites = sites;
      })
  }

  getUserName(value: string) {
    return value.split('@')[0];
  }

  getAlertsForAll(sector: string) {
    this.alertLoader = true;
    this.zonewiseAlert = {};

    const payload = {
      deviceTypeOrFacility: [meterType[sector]],
      dates: [this.startDate, this.endDate],
      filter: this.filter
    }
    this.alertsManagementService.getAllAlerts(payload).subscribe((response: any) => {
      this.allAlerts = response.data;
      this.getAlertsCounts();
      this.alertLoader = false;
    });
  }

  getAlertsCounts() {
    this.totalAlert = this.allAlerts?.length;
    this.activeAlerts = 0;
    this.allAlerts.forEach(alert => {
      if (alert.status.toLowerCase() === 'active') {
        const zone = alert.location.zone;
        this.activeAlerts++;
        this.zonewiseAlert[zone] = this.zonewiseAlert[zone] + 1 || 1;
      }
    })
  }

  public searchDevices() {
    if (this.filterValue && this.filterValue.length > 0) {
      const data = this.deviceListing?.filter((item: any) => {
        return item?.uplinkReferenceKey?.toLocaleLowerCase()?.includes(this.filterValue?.trim());
      });
      this.filterDeviceListing = data;
    } else {
      this.filterDeviceListing = this.allDeviceData?.slice(0, this.maxResults);
    }
  }
  public _filterDeviceList(value: any) {
    this.filterValue = value?.target?.value?.toLocaleLowerCase();
    this.debouncedSearch();
  }

  deviceSelectionChange(data) {
    const deviceSubgroups = [];
    this.selectedDevice?.forEach(device => {
      deviceSubgroups.push({
        displayLegend: device,
        deviceId: device,
        display: true
      })
    });
    this.deviceSubgroups = deviceSubgroups;
    this.getConsumptionByDeviceId(this.averageConsumptionPerDeviceAggregate);
  }
  onScroll(event: any) {
    const element = event.target;
    if (element.scrollHeight - element.scrollTop <= element.clientHeight + 10) {
      this.fetchMore();
    }
  }
  onScrollToEnd() {
    const dropdownPanel = this.select?.dropdownPanel?.scrollElementRef?.nativeElement;
    dropdownPanel.addEventListener('scroll', this.onScroll.bind(this));
  }

  fetchMore() {
    const nextBatch = this.allDeviceData.slice(this.offset, this.offset + this.maxResults);
    if (nextBatch.length > 0) {
      this.filterDeviceListing = [...this.filterDeviceListing, ...nextBatch];
      this.offset += this.maxResults;
    }
  }

  async getDeviceListing() {
    try {
      const dates = [moment().subtract(29, 'days').format(DATE_TIME_FORMAT_DAY_START), moment().format(DATE_TIME_FORMAT_DAY_END)];
      this.deviceCountLoader = true;
      this.showMeterCount = false;
      if (this.filter && Object.keys(this.filter).length > 0) {
        await this.registrationService.getDeviceCount({ filter: this.filter, deviceType: this.selectedDeviceType })
          .toPromise().then(resp => {
            this.activeDevices = resp?.onlineDeviceCount || 0;
            this.totalDevices = resp?.totalDeviceCount || 0;
            this.deviceCountLoader = false;
          })

        const filterType = {
          deviceID: [],
          uplinkReferenceKey: []
        };
        this.registrationService.getDeviceFilterOptions({
          deviceType: this.selectedDeviceType,
          filterType,
          sites: this.filter,
          pageNumber: 0,
          pageSize: 0,
        }).subscribe((response: any) => {
          const deviceIds = response['deviceID'];
          this.allDeviceData = deviceIds;
          this.filterDeviceListing = this.allDeviceData.slice(0, this.maxResults);
          this.deviceListing = deviceIds;
          this.offset = this.maxResults;
          this.selectedDevice = deviceIds && deviceIds?.length ? deviceIds?.filter(Boolean).splice(0, 2)?.map(device => device?.uplinkReferenceKey) : [];
          this.deviceSubgroups = this.selectedDevice?.map(device => {
            return {
              displayLegend: device,
              deviceId: device,
              display: true
            };
          });
          this.showMap = true;
          this.getConsumptionByDeviceId();
        })
      } else {
        this.deviceListing = [];
        this.filterDeviceListing = [];
        this.selectedDevice = [];
        this.activeDevices = 0;
        this.totalDevices = 0;
        this.deviceCountLoader = false;
      }
    } catch (error) {
      this.deviceListing = [];
      this.selectedDevice = [];
      this.filterDeviceListing = [];
      this.activeDevices = 0;
      this.totalDevices = 0;
      this.deviceCountLoader = false;
    }
  }

  getNotificationTimeFormatted(dateString: Date) {
    return this.sharedService.returnDaysAgoFromTodayDate(dateString, true);
  }

  consumptionTypeChange(consumptionAggretType: string) {
    this.consumptionAggretType = consumptionAggretType;
    let dataList;

    if (this.consumptionAggretType === averageConsumption) {
      dataList = this.averageConsumptionPerHousehold?.avgConsumptionPerDeviceAggregated?.map(x => {
        return {
          dateTime: x.timestamp,
          value: x.value
        }
      })
    }
    else {
      dataList = this.averageConsumptionPerHousehold?.totalConsumptionAggregated?.map(x => {
        return {
          dateTime: x.timestamp,
          value: x.value
        }
      })
    }
    if (dataList?.length > 0) {
      this.averageConsumptionPerHouseholdNoDataFound = false;
      const name = this.consumptionAggretType;
      const dataSet = [{
        name,
        nativeList: dataList
      }]
      const attributes = [{
        name,
        deviceType: this.selectedDeviceType
      }]
      this.createChart(dataSet, attributes, '.average-consumption', this.averageConsumptionPerHouseholdAggregate);
    }
    else {
      d3.selectAll(`.average-consumption svg`).remove();
      this.averageConsumptionPerHouseholdNoDataFound = true;
    }
  }

  getConsumptionPerDevice(averageConsumptionPerHouseholdAggregate = 'hourly', averageConsumptionPerHouseholdStatLoader = false) {
    this.averageConsumptionPerHouseholdLoader = true;
    if (averageConsumptionPerHouseholdStatLoader) this.averageConsumptionPerHouseholdStatLoader = true;
    this.consumptionAggretType = this.consumptionAggretType || averageConsumption;
    this.averageConsumptionPerHouseholdAggregate = averageConsumptionPerHouseholdAggregate;
    if (this.accessingFrom == 'dashboard') {
      this.startDate = moment().startOf('month').format(DATE_TIME_FORMAT_DAY_START);
      this.endDate = moment().format(DATE_TIME_FORMAT_DAY_END);
    }
    const dates = [this.startDate, this.endDate];
    const payload = {
      dates,
      filter: this.filter
    };
    // try {
    //   this.telemetryService.getPercentageChangesForConsumption(dates).subscribe(data => {
    //     const consumptionChange = this.meterType === 'water' ? data?.waterConsumptionChange : (this.meterType === 'energy' ? data?.energyConsumptionChange : 0);
    //   })
    // } catch (error) {
    //   console.log(error);
    // }
    try {
      if(this.filter){
        this.telemetryService.getConsumptionPerDevice(payload, this.averageConsumptionPerHouseholdAggregate, this.selectedDeviceType).subscribe((resp: any) => {
          this.averageConsumptionPerHouseholdLoader = false;
          this.averageConsumptionPerHouseholdStatLoader = false;
          this.averageConsumptionPerHousehold = resp;
          let dataList;
          if (this.consumptionAggretType === averageConsumption) {
            dataList = this.averageConsumptionPerHousehold?.avgConsumptionPerDeviceAggregated?.map(x => {
              return {
                dateTime: x.timestamp,
                value: x.value
              }
            })
          } else {
            dataList = this.averageConsumptionPerHousehold?.totalConsumptionAggregated?.map(x => {
              return {
                dateTime: x.timestamp,
                value: x.value
              }
            })
          }
  
          if (dataList?.length > 0) {
            this.averageConsumptionPerHouseholdNoDataFound = false;
            const name = this.consumptionAggretType;
            const dataSet = [{
              name,
              nativeList: dataList
            }]
            const attributes = [{
              name,
              deviceType: this.selectedDeviceType
            }]
            this.createChart(dataSet, attributes, '.average-consumption', this.averageConsumptionPerHouseholdAggregate);
          } else {
            d3.selectAll(`.average-consumption svg`).remove();
            this.averageConsumptionPerHouseholdNoDataFound = true;
          }
        });
      }
    } catch (error) {
      this.averageConsumptionPerHouseholdLoader = false;
      this.averageConsumptionPerHouseholdStatLoader = false;
      this.averageConsumptionPerHouseholdNoDataFound = true;
    }
  }

  percentageData() {
    this.percentageLoader = true;
    const payload = {
      dates: [this.startDate, this.endDate],
      filter: this.filter
    };
    this.telemetryService.percentageData(payload, this.selectedDeviceType).subscribe((resp: any) => {
      this.percentageLoader = false;
      this.percentageValue = resp;
    });
  }

  dataAvailabilityScoreByDatesSite() {
    if (Object.keys(this.filter).length > 0) {
      this.percentageLoader = true;
      const payload = {
        dates: [this.startDate, this.endDate],
        filter: this.filter,
        deviceType: this.selectedDeviceType
      };
      this.telemetryService.dataAvailabilityScoreByDatesSite(payload, this.selectedDeviceType).subscribe((resp: any) => {
        this.percentageLoader = false;
        this.percentageValue = resp ? resp : 0;
      });
    }
  }
  getConsumptionByDeviceId(averageConsumptionPerDeviceAggregate = 'hourly') {
    this.averageConsumptionPerDeviceLoader = true;
    this.averageConsumptionPerDeviceAggregate = averageConsumptionPerDeviceAggregate;
    const devices = this.deviceSubgroups?.filter(subgroup => subgroup?.display)?.map(device => device?.deviceId);
    // fetching all the deviceIDs of the device using the uplinkreferencekey
    const deviceIDs = this.allDeviceData?.filter(device => devices.includes(device?.uplinkReferenceKey))?.map(device => device?.deviceID);
    const payload = { dates: [this.startDate, this.endDate], deviceIDs };
    const attributes = this.deviceSubgroups?.map((device) => {
      return {
        name: device?.deviceId,
        deviceType: this.selectedDeviceType,
        display: device?.display
      }
    });
    const allDeviceData = this.allDeviceData?.reduce((deviceIDs, device) => {
      const { uplinkReferenceKey, deviceID } = device;
      deviceIDs[deviceID] = uplinkReferenceKey;
      return deviceIDs;
    }, {});
    this.telemetryService.getConsumptionPlotByDeviceIds(payload, this.averageConsumptionPerDeviceAggregate, this.selectedDeviceType)
      .subscribe((response: any) => {
        if (Object.keys(response)?.length) {
          this.averageConsumptionPerDeviceLoader = false;
          const dataList = [];
          for (const deviceId in response) {
            const aggregatedData = response[deviceId];
            const uplinkReferenceKey = allDeviceData[deviceId];
            const nativeList = aggregatedData?.map(x => {
              return {
                dateTime: x.timestamp,
                value: x.value
              }
            })
            dataList.push({
              name: uplinkReferenceKey,
              nativeList
            });
          }
          const checkForNoData = dataList?.every(data => !data.nativeList?.length);
          if (!checkForNoData) {
            const dataSet = dataList?.sort((a, b) => b?.nativeList?.length - a?.nativeList?.length);
            this.averageConsumptionPerDeviceNoDataFound = false;
            this.createChart(dataSet, attributes, '.monthly-consumption', this.averageConsumptionPerDeviceAggregate);
          } else {
            this.averageConsumptionPerDeviceLoader = false;
            d3.selectAll(`.monthly-consumption svg`).remove();
            this.averageConsumptionPerDeviceNoDataFound = true;
          }
        } else {
          this.averageConsumptionPerDeviceLoader = false;
          d3.selectAll(`.monthly-consumption svg`).remove();
          this.averageConsumptionPerDeviceNoDataFound = true;
        }
      },
        (error) => {
          this.averageConsumptionPerDeviceLoader = false;
          this.averageConsumptionPerDeviceNoDataFound = true;
          d3.selectAll(`.monthly-consumption svg`).remove();
        }
      )
  }

  createChart(dataSet, attributes, element, aggregationInterval = 'daily', chartType = 'areachart') {
    const config = {
      attributes,
      showScrollbar: true,
    }
    const dataSetForBarChart = dataSet?.map((set) => {
      const resultObj = {};
      set?.nativeList?.forEach((nativeData, index) => {
        const { dateTime, value } = nativeData
        const dateRange = [dateTime];
        resultObj[`Tag ${index}`] = {
          dateRange,
          value
        }
      })
      set['aggregation'] = resultObj;
      return set;
    });
    config["removeExisting"] = true;
    config["widgetType"] = chartType;
    config["aggregationInterval"] = aggregationInterval;
    d3.select(element).attr('hidden', null);
    if (chartType === 'line') {
      renderChart(element, dataSet, config);
    } else {
      renderChart(element, dataSetForBarChart, config);
    }
  }

  // createChartNative(dataSet, attributes, element, aggregationInterval?: string) {
  //   const config = {
  //     attributes,
  //     showScrollbar: true,
  //   }
  //   const dataSetForBarChart = dataSet?.map((set) => {
  //     const resultObj = {};
  //     set?.nativeList?.forEach((nativeData, index) => {
  //       const { dateTime, value } = nativeData
  //       const dateRange = [dateTime];
  //       resultObj[`Tag ${index}`] = {
  //         dateRange,
  //         value
  //       }
  //     })
  //     set['aggregation'] = resultObj;
  //     return set;
  //   });
  //   config["removeExisting"] = true;
  //   config["widgetType"] = 'linechart';
  //   d3.select(element).attr('hidden', null);
  //   if(aggregationInterval){
  //     config["aggregationInterval"] = aggregationInterval;
  //     const Forecasted = dataSetForBarChart?.find(x => x.name !== "Recorded Consumption").nativeList.map(x => x.dateTime)
  //     const Recorded = dataSetForBarChart?.find(x => x.name === "Recorded Consumption").nativeList.map(x => x.dateTime)
  //     const alertDates = Forecasted?.filter(function (obj) { return Recorded.indexOf(obj) == -1; });
  //     alertDates.sort(function (a, b) { return a - b });
  //     config["alertRange"] = {
  //       startDate: moment( alertDates[0]).subtract(1,'day'),
  //       endDate: alertDates[alertDates.length - 1]
  //     }
  //     forecastinglineGraphForNative(element, dataSetForBarChart, config);
  //   }else{
  //     config["aggregationInterval"] = 'native';
  //     lineGraphForNative(element, dataSet, config);
  //   }
  // }

  datepicker(dateArr: any) {
    const date = setDateRange(dateArr);
    this.startDate = date.startDate;
    this.endDate = date.endDate;
    this.getConsumptionPerDevice(this.averageConsumptionPerHouseholdAggregate, true);
    this.getConsumptionByDeviceId();
    // this.percentageData();
    this.dataAvailabilityScoreByDatesSite();
    this.getAlertsForAll(this.meterType);
  }

  async siteChanges(selectedSites: any) {
    this.filter = selectedSites;
    this.selectedSites = _.isEmpty(selectedSites) ? null : selectedSites;
    const zones = [];
    for (const site in selectedSites) {
      zones.push(...selectedSites[site]);
    }

    this.selectedZones = zones;
    this.getDeviceListing();
    this.getZonewiseCount();
    this.getConsumptionPerDevice(this.averageConsumptionPerHouseholdAggregate, true);
    // this.percentageData();
    this.dataAvailabilityScoreByDatesSite();
    this.getExpectedFrequency();
    this.getAlertsForAll(this.meterType);
  }

  getExpectedFrequency() {
    const sindalahDuration = '1 hour';
    const expectedFrequency = this.meterType === 'energy' ? '15 mins' : sindalahDuration;
    this.expectedFrequency = expectedFrequency;
  }

  ngOnDestroy() {
    this.consumptionGraphSubscription.unsubscribe();
  }

  showContent = 'energy';
  handleToggle(selectedToggle) {
    this.showContent = selectedToggle;
    this.getMeterTypeAndSites()
    this.getConsumptionPerDevice('daily', true);
    // this.selectedToggle.emit(selectedToggle)
  }

  changeMap() {
    this.isMap = !this.isMap
    this.mapGraphToggle.emit(this.isMap)
  }

  displayChart(data: any) {
    this.deviceSubgroups = this.deviceSubgroups?.map(attribute => {
      if (attribute.deviceId === data.deviceId) {
        attribute.display = !attribute.display;
      }
      return attribute;
    });
    this.getConsumptionByDeviceId(this.averageConsumptionPerDeviceAggregate);
  }

  // forecastingChart(data: any) {
  //   this.forecastinGroups = this.forecastinGroups?.map(attribute => {
  //     if (attribute.deviceId === data.deviceId) {
  //       attribute.display = !attribute.display;
  //     }
  //     return attribute;
  //   });
  //   this.forecastingByDeviceIdfilter();
  // }

  downloadData() {
    const config = {
      aggregationInterval: 'hourly',
      searchTerm: 'Water Meter Forecast',
      subgroups: this.forecastinGroups,
      aggregationType: 'average',
      widgetType: 'linechart',
      oneDeviceData: true,
    }
    downloadData(this.dataList, config, 'forecast');
  }
  getStyles(activeAlerts, totalAlert) {
    return { 'width': `${activeAlerts * 100 / totalAlert}%` }
  }
}
