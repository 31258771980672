import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class ChatbotService {

  constructor(private apiService: ApiService) { }
  
    public getChatbotQuery(payload: any) {
      return this.apiService.post(`/chatbot/query`, payload);
    }
}
