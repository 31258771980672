import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IFacilityInfo, ISiteInfo } from '../interfaces';
import { METERS } from '../constant';
import * as _ from 'underscore';
import { UtilsService } from 'src/app/services/utils.service';
import { dashboardUrlForDescription } from '../../route-constants';



@Component({
  selector: 'app-expanded-info-panel',
  templateUrl: './expanded-info-panel.component.html',
  styleUrls: [
    './expanded-info-panel.component.css',
    '.././landing-page-map.component.css'
  ]
})
export class ExpandedInfoPanelComponent implements OnInit {
  @Input() detailedMetersOrFacilitiesCardsInfo;
  @Input() facilityInfoSelected;
  @Input() siteWiseConsumption;
  @Input() consumptionUnit;
  @Output() handleBack = new EventEmitter<string>();
  @Output() FilterSitesOnMap = new EventEmitter<ISiteInfo>();
  @Output() FilterFacilityOnMap = new EventEmitter<{idsToShow: number[], facilityInfo: IFacilityInfo}>();
  filteredCards;
  searchedText;
  dashboardUrlForDescription = dashboardUrlForDescription;
  constructor(private utilsService: UtilsService) { 
    this.showFilterList = _.debounce(this.showFilterList, 500);
  }

  searchBarShow: boolean = false;

  ngOnInit(): void {
    this.filteredCards = JSON.parse(JSON.stringify(this.detailedMetersOrFacilitiesCardsInfo.data));
    if(this.facilityInfoSelected) {
        const facilityInfo = this.detailedMetersOrFacilitiesCardsInfo.data.find(d => d.selected);
        const idsToShow = this.detailedMetersOrFacilitiesCardsInfo.data.filter(d => d.selected).map(d => d.oid);
        this.FilterFacilityOnMap.emit({facilityInfo, idsToShow});
      }
    this.reOrder();
  }

  showSearchBtn() {
    this.searchBarShow = true;
  }

  closeSearchBtn() {
    this.searchBarShow = false;
    this.filteredCards = this.detailedMetersOrFacilitiesCardsInfo.data;
    this.reOrder();
    this.searchedText = "";
  }

  onClickBack() {
    this.handleBack.emit('');
  }

  toggleSelection(type, siteOrFacilityInfo) {
    siteOrFacilityInfo.selected = !siteOrFacilityInfo.selected;
    this.storeDataCopy(siteOrFacilityInfo);  // first store in orignal list then filter from that list

    if(type === METERS) {
      this.FilterSitesOnMap.emit(siteOrFacilityInfo);
    }
    else  {
      this.FilterFacilityOnMap.emit(
        { facilityInfo: siteOrFacilityInfo, 
          idsToShow: this.detailedMetersOrFacilitiesCardsInfo.data.filter(d => d.selected).map(d => d.oid)
        });
    }
  }

  reOrder() {
    // this will sort all selected true in ascending
    this.filteredCards.sort((a, b) => b.selected-a.selected);
  }

  storeDataCopy(siteOrFacilityInfo) {
    this.detailedMetersOrFacilitiesCardsInfo.data.find(d => d.oid === siteOrFacilityInfo.oid).selected = siteOrFacilityInfo.selected;;
  }

  showFilterList() {
    let data = this.detailedMetersOrFacilitiesCardsInfo.data;
    if(this.facilityInfoSelected) {
      this.filteredCards = data.filter(d => d.description.toLowerCase().includes(this.searchedText.toLowerCase()));
    }
    else {
      this.filteredCards = data.filter(d => d.site.toLowerCase().includes(this.searchedText.toLowerCase()));
    }
  }
  
  getSanitizedConsumptionUnit(productName) {
    const unit = this.consumptionUnit &&  this.consumptionUnit[productName] || '';
    return this.utilsService.getSanitizedHTMLContent(unit);
  }

  showSiteOnZoomEvent(site) {
    if(!this.facilityInfoSelected && site) {
      if(site === 'all') {
        this.filteredCards = this.detailedMetersOrFacilitiesCardsInfo.data;
      }
      else {
        this.filteredCards = this.detailedMetersOrFacilitiesCardsInfo.data.filter(item => item.site === site)
      }
    }
  }

  showData(value) {
    return (value || value === 0) ? true : false
  }

  getValidValue(value) {
    return (value || value === 0 ) ? value : ''
  }

}
