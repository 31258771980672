import { Component, OnInit, Input } from '@angular/core';


@Component({
  selector: 'app-linear-shimmer',
  templateUrl: './linear-shimmer.component.html',
  styleUrls: ['./linear-shimmer.component.css']
})
export class LinearShimmerComponent implements OnInit {
  @Input() height: string = '10px'; // Default height
  @Input() width: string = '200px'; // Default width
  @Input() backgroundColor: string = '#e0e0e0'; // Default color
  constructor() { }

  ngOnInit(): void {
  }

}
