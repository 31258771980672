import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, OnChanges } from '@angular/core';
@Component({
  selector: 'app-table-select-filter',
  templateUrl: './table-select-filter.component.html',
  styleUrls: ['./table-select-filter.component.css', '../../../../assets/Reusable-CSS/popup.scss', "../../../../assets/Reusable-CSS/buttons.scss", "../../../../assets/Reusable-CSS/form.scss", "../../../../assets/styles/form.css"]
})
export class TableSelectFilterComponent implements OnInit, OnChanges {
  @Input() data: any;
  @Input() name: string;
  @Input() key: string;
  @Input() displaySelectAll: boolean = false;
  @Input() isFilterLoading: boolean = false;

  @Input() filters = [];
  @Output() applySorting = new EventEmitter<any>();
  @Output() handleAppliedFilter = new EventEmitter<any>();
  @Output() handleAppliedBulkFilter = new EventEmitter<any>();

  searchedText: string = "";
  filteredData: any = [];
  sortingInfo: any;
  allSelected: boolean;

  constructor() { }

  ngOnInit(): void { }

  ngOnChanges() {
    if (this.data) {
      this.filteredData = [...this.data];
    }
    this.sortFilteredData();
  }

  sortFilteredData() {
    this.filteredData?.sort((a, b) => {
      if (a.name === null) return -1;
      if (b.name === null) return 1;
      return 0;
    });
  }

  // checkIfAnyOneSelected(){
  //   let selectedValuesArr = this.getAllSelectedValues();
  //   if(selectedValuesArr.length === 0){
  //     return true;
  //   }else{
  //     return false;
  //   }
  // }

  emitAppliedFilterEvent(item?: any) {
    const checkAllDevices = this.data?.every(filter => filter?.selected);
    let selectedValuesArr = this.getAllSelectedValues();
    this.allSelected = checkAllDevices;
    this.handleAppliedFilter.emit(selectedValuesArr);
    this.searchedText = '';
  
  }

  sort(trend) {
    this.sortingInfo = {
      column: this.data[0]['key'],
      sort: trend
    }
    this.applySorting.emit(this.sortingInfo)
  }

  onChangeInput() {
    if (this.searchedText === "") {
      this.filteredData = this.data;
    } else {
      this.filteredData = this.data.filter((item) => item.name?.toLowerCase().includes(this.searchedText.toLowerCase()))
    }
  }

  clearInputFilter() {
    this.searchedText = "";
    this.filteredData = this.data;
  }

  filterCount() {
    const filter = this.filters?.filter((data: any) => data.key === this.key);
    return filter?.length;
  }

  selectAllItems() {
    this.filteredData = this.filteredData?.map(element => {
      element.selected = this.allSelected;
      return element;
    });
    this.data = this.data?.map(element => {
      element.selected = this.allSelected;
      return element;
    });
    // this.handleAppliedBulkFilter.emit(filteredData);
  }

  checkAllItems() {
    return this.data?.every(filter => filter?.selected);
  }

  getAllSelectedValues() {
    try {
      let allSelectedArr = this.data?.filter(x => x?.selected);
      return allSelectedArr;
    } catch (error) {
      throw new Error("SelectFacilityDialogComponent : showFacilityName() : " + error);
    }
  }

  selectItem(name: string, selected: boolean) {
    const index = this.data?.findIndex(data => data.name === name);
    this.data[index]['selected'] = selected;
  }

}
