export const NC1_BASE_CAMP_WATER_METER = "NEOM Community 1";
export const NC1_BASE_CAMP_VALUE_WATER_METER = "6dfb64c6-4643-3349-8798-98adca286565";
export const NC1_BASE_CAMP = "NEOM Community 1";
export const NC1_BASE_CAMP_VALUE = "5dfb64c6-4643-3349-8798-98adca286565";
export const ISKRA_DUBAI_LAB = "ISKRA Dubai Lab";
export const ISKRA_DUBAI_LAB_VALUE = "8eafbd79-c9ce-330e-a9f6-18e2249237a1";
export const SINDALAH_ISLAND = "Sindalah Island";
export const ROYAL_PALACE = "Royal Palace"


export const WATER_METER_STITES = [
    {
        name: "NEOM Community 1",
    },
    {
        name: "Sample Device",
    },
    // {
    //     name: "Dummy Meter"
    // }

]

export const ENERGY_METER_SITES = [
    {
        name: "NEOM Community 1",
    },
    {
        name: "ISKRA Dubai Lab",
    },
    // {
    //     name: "Dummy Meter",
    // },

]

export const COOLING_METER_SITES = [
    {
        name: "Sample Device",
    },
    {
        name: "Simulated Device",
    }
    // {
    //     name: "Sample Device",
    // },
    // {
    //     name: "Dummy Meter",
    // }
]

export const SYSTEM_TYPE_DEVICES = 'Meter';
export const SYSTEM_TYPE_SCADA = 'SCADA';
export const SYSTEM_TYPE_TRANSMISSIO_POINT = 'Transmission Point';
export const SYSTEM_TYPE_GATEWAYS = 'Gateways';