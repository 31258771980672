import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ALERT_NAMES } from 'src/app/reusable/constant';

@Component({
  selector: 'app-table-applied-filters',
  templateUrl: './table-applied-filters.component.html',
  styleUrls: [
    './table-applied-filters.component.css',
    '../../../../assets/Reusable-CSS/form.scss'
  ]
})
export class TableAppliedFiltersComponent implements OnInit {
  @Input() filterColumns:any
  @Input() appliedFilters:any = []
  @Output() clearAllAppliedFilters = new EventEmitter<any>();
  @Output() handleRemoveFilter = new EventEmitter<any>();

  alertNames = ALERT_NAMES;

  constructor( ) {
   }

  ngOnInit(): void { 
    console.log(this.appliedFilters);
  }

  clearAllFilter() {
    Object.keys(this.filterColumns)?.forEach(key => this.filterColumns[key].forEach(item => item.selected = false));
    this.clearAllAppliedFilters.emit();
  }

  removeFilter(item) {
    // marked it false and update the appliedFilters
    item.selected = false;
    let key = item.key;
    let name = "";
    if(key === 'alertType'){
    name = this.getAlertType(item.name);
    }else{
      name = item.name
    }
    const index = this.filterColumns[key]?.findIndex(data => data?.name === name);
    this.filterColumns[key][index]['selected'] = false;
    this.handleRemoveFilter.emit(item);
  }

  showChipName(item){

    if(item.key === 'alertType'){
      return this.getAlertType(item.name);
    }else{
      if(!item.name){
        let key = item.key;
        if(item.key === "uplinkReferenceKey"){
          key = "Device Serial"
        }
        return this.capitalizeFirstWord(key) + ": (Blanks)";
      }
      return item.name;
    }
  }

  getAlertType(alertTypes) {
    return alertTypes.split(',').map((alertType) => this.alertNames[alertType?.toLowerCase()].charAt(0).toUpperCase() + this.alertNames[alertType?.toLowerCase()].substring(1)).toString();
  }

  capitalizeFirstWord(str: string): string {
    if (!str) return str;
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

}
