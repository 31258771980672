import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { RegistrationService } from '../services/registration.service';
import { LandingPageEsriMapComponent } from './landing-page-esri-map/landing-page-esri-map.component';
import { DEVICE_TYPE_WATER_METER, DEVICE_TYPE_ENERGY_METER, DEVICE_TYPE_COOLING_METER, FLOW_METER_DEVICE_TYPE } from '../constant';
import { IMapMetersCard } from '../common.interfaces';
import { NC1_BASE_CAMP, ROYAL_PALACE, SINDALAH_ISLAND } from '../siteConstants';
import { AlertsManagementService } from '../services/alerts-management.service';
import { UtilsService } from 'src/app/services/utils.service';
import { TelemetryService } from '../services/telemetry.service';
import { DEFAULT_METER_LIST, DEFAULT_DETAILED_METER_CARD_INFO, DEFAULT_DETAILED_FACILITY_CARD_INFO, METERS, FACILITIES, TEXT_ALL, INDUSTRIAL_FACILITIES, TEXT_INDUSTRIAL, CONSUMER, formatedSystemTypeName } from './constant';
import { DetailedCardInfo, IFacilityInfo, ISiteInfo } from './interfaces';
import { ExpandedInfoPanelComponent } from './expanded-info-panel/expanded-info-panel.component';
import { MapInfo } from 'src/app/services/arcgis-map/interfaces';
import * as _ from 'underscore';
import { dashboardUrlForDescription } from '../route-constants';
import { LandingPageMapService } from './landing-page-map.service';

@Component({
  selector: 'app-landing-page-map',
  templateUrl: './landing-page-map.component.html',
  styleUrls: ['./landing-page-map.component.css']
})
export class LandingPageMapComponent implements OnInit {
  @ViewChild(LandingPageEsriMapComponent) map : LandingPageEsriMapComponent;
  @ViewChild(ExpandedInfoPanelComponent) expandedInfoPanelComponent : ExpandedInfoPanelComponent;
  mapInfo:MapInfo = {
    center: [34.671961,28.096149],
    zoom: 10.4,
  }
  loading = false;
  selectedHeatMap = false;
  // This will help to identify is selected meter or facility has consumption and heatMap
  meters = [DEVICE_TYPE_WATER_METER, DEVICE_TYPE_ENERGY_METER, DEVICE_TYPE_COOLING_METER];
  showHeatMapCheckbox = false;
  showCluster = false;
  isOptionsAddedToMap = false;
  username;
  selectedMeter:any;

// new variabled
  mapInitialized = false
  metersList:any = DEFAULT_METER_LIST
  originalMeterList
  sitesList:{[key:string]: {[key:string]: string[]}} = {};
  showMetersList = true;
  servedSites = [NC1_BASE_CAMP, SINDALAH_ISLAND, ROYAL_PALACE];
  // deviceTypesList = [DEVICE_TYPE_WATER_METER, DEVICE_TYPE_ENERGY_METER, DEVICE_TYPE_COOLING_METER, FLOW_METER_DEVICE_TYPE];
  productNameList:string[] = [];
  selectedProductNames;
  selectedSites: ISiteInfo[];
  selectedFacilities: IFacilityInfo[] = [];
  filteredSelectedFacilities: IFacilityInfo[] = []
  activeAlertsCountByDeviceType:{[deviceType:string]:string} = {};
  siteWiseConsumption: {[site:string]:string} = {};
  detailedMetersOrFacilitiesCardsInfo:DetailedCardInfo;
  facilityInfoSelected = false;
  consumptionUnit // mapping of deviceType verse there unit
  // consumptionByDeviceTypeData = {}; // mapping of deviceType verse there consumption
  consumptionByProductNames:{[key:string]:number} = {}; // mapping of productName verse there consumption
  TEXT_ALL = TEXT_ALL;
  focusedSiteOnMap:string = TEXT_ALL;

  searchPopup: boolean = false;
  tagClicked: boolean = false;
  searchText: string = "";
  showFilterPopup: boolean = false;
  fetchFacilityLoader:boolean = false;
  // filter options
  lobFilterList:{lob:string, selected:boolean}[] = [];
  sectorFilterList:{sector:string, selected: boolean}[] = [];
  selectedLobs:string[] = [];
  selectedSectors:string[] = [];
  mapFiltersInfo;
  priductNameVerseIconInfo = {};
  fullScreenModeEnabled:boolean = false;
  firstOccurenceOfFacilitySystemType = {
    scada: null,
    transmission:null
  }
  constructor(
    private registrationService: RegistrationService,
    private alertsManagementService: AlertsManagementService,
    private utilsService: UtilsService,
    private telemetryService: TelemetryService,
    private landingPageMapService: LandingPageMapService
  ) {
    this.filterCardsOnSearch = _.debounce(this.filterCardsOnSearch, 500);
   }

  ngOnInit(): void {
    this.username = localStorage.getItem("useremail");
    this.mapFiltersInfo = this.getMapFilterInfo();
    this.searchText = this.mapFiltersInfo?.searchText;
    window.addEventListener("resize", this.handleResize);

  }

  handleResize = () => {
    // Check if the document is in fullscreen
    const isFullscreen = (document as any).fullscreenElement || 
    (document as any).webkitFullscreenElement || 
    (document as any).mozFullScreenElement || 
    (document as any).msFullscreenElement;
  
    // Log the screen size or fullscreen status
    if (isFullscreen) {
      this.fullScreenModeEnabled = true;
    } else {
      this.fullScreenModeEnabled = false;
    }
  };

  esriMapInitialized(event) {
    this.mapInitialized = event;
    this.getProductDetails();
    this.getDashboards();
    this.getSites(this.mapFiltersInfo?.selectedProductNames ? (this.mapFiltersInfo?.selectedProductNames?.length ? this.mapFiltersInfo.selectedProductNames : [""]) : []);
    this.getConsumptionUnit();
  }


  optionAddedToMap(isAdded) {
      this.isOptionsAddedToMap = isAdded;
  }

  getProductDetails() {
    this.registrationService.productDetailsBySystemType().subscribe(res => {
      this.handleCosumerList(res[CONSUMER]);
      this.handleIndustrialList(res[TEXT_INDUSTRIAL]);
    })
  }
  
  getDashboards() {
    //****************** creation of filter(LOB and Sector) *******************/ 
    this.selectedLobs = this.mapFiltersInfo?.selectedLobs ? this.mapFiltersInfo?.selectedLobs: [];
    this.selectedSectors = this.mapFiltersInfo?.selectedSectors ? this.mapFiltersInfo?.selectedSectors: [];
    this.registrationService.getDashboards().subscribe((res:any) => {
      this.lobFilterList = Object.keys(res).map(lob => ({lob, selected:this.mapFiltersInfo?.selectedLobs.includes(lob) ? true : false}));
      this.sectorFilterList = Object.keys(res[CONSUMER]).map(sector => ({sector, selected:this.mapFiltersInfo?.selectedSectors.includes(sector) ? true : false}));
    })
  }

  handleCosumerList(consumerList) {
    this.originalMeterList = consumerList['Meter'];
    this.productNameList = this.originalMeterList.map(item => {
      item['dashboardUrl'] = dashboardUrlForDescription[item.description]
      this.priductNameVerseIconInfo[item.productName] = item.icon;
      return item.productName;
    });

    if(this.mapFiltersInfo?.metersList) {
      this.metersList = this.mapFiltersInfo?.metersList;
      this.selectedProductNames = this.mapFiltersInfo.selectedProductNames;
    }
    else {
      this.metersList = this.originalMeterList;
      this.selectedProductNames = this.productNameList;
    }
    this.productStatusCount();
    this.getZoneBySite();
    // this.getActiveAlertCountByDeviceType();
    this.productNameList.forEach(productName => this.consumptionByProductName(productName));
    // store porudctNameVerso Icon info in ss
    localStorage.setItem('priductNameVerseIconInfo', JSON.stringify(this.priductNameVerseIconInfo));
  }

  handleIndustrialList(facilities) {
    this.addFacilitiesToMap(facilities);
  }

  // also handle the show deatiled meters information
  getSites(productNameList): Promise<void>  {
    this.loading = true;
    return new Promise((resolve, reject) => {
      this.registrationService.siteWiseDataByProductName(productNameList).subscribe({
        next: (res: ISiteInfo[]) => {
          this.selectedSites = res.map((data, idx) => {
            data['selected'] = true;
            data['oid'] = idx;
            return data;
          });
  
          this.map.addSites(this.selectedSites);
          this.fetchSiteWiseConsumption();
  
          // If the meter is selected, then call for it
          if (this.selectedMeter && !this.showMetersList) {
            this.initializeDetailedMetersOrFacilitiesCardsInfo('meters', this.selectedMeter.description, this.selectedSites, this.selectedMeter.productName);
            this.map.toggleFacilitiesFeatureLayerClusterVisibility(false);
            this.expandedInfoPanelComponent.showSiteOnZoomEvent(this.focusedSiteOnMap);
          }
  
          this.loading = false;
          resolve();  // Resolve the promise when complete
        },
        error: (err) => {
          console.error('Error fetching site data:', err);
          this.loading = false;
          reject(err);  // Reject the promise on error
        }
      });
    });
  }

  toggleIndividualSiteFeatureLayerVisibility(site) {
    this.map.toggleIndividualSiteFeatureLayerVisibility(site);
  }
  
  toggleIndividualFacilityFeatureLayerVisibility({facilityInfo, idsToShow}) {
    this.map.toggleIndividualFacilityFeatureLayerVisibility(idsToShow);
    const zoom = this.map.getCurrentZoom();
    this.map.reCentre({zoom, center: [facilityInfo.location.longitude,facilityInfo.location.latitude] })
  }

  async addFacilitiesToMap(industrialList) {
    this.fetchFacilityLoader = true;
    const addFacilitiesToMapPromise: Promise<any>[] = Object.values(industrialList).reverse().map((facilities:IFacilityInfo[]) => {
      facilities.forEach(facility => {
        facility['selected'] = false;
        this.selectedFacilities.push(facility);
      });
      return this.map.addFacilities(facilities);
    })
    await Promise.all(addFacilitiesToMapPromise);
    //create filteredList of selectedFacilities
    this.filteredSelectedFacilities = this.mapFiltersInfo?.filteredSelectedFacilities ? this.mapFiltersInfo?.filteredSelectedFacilities : JSON.parse(JSON.stringify(this.selectedFacilities));
    this.findFirstOccurenceOfSystemTypes(this.filteredSelectedFacilities);
    const idsToShow = this.filteredSelectedFacilities.map(d => d['oid']);
    this.map.toggleIndividualFacilityFeatureLayerVisibility(idsToShow);
    this.fetchFacilityLoader = false;
    Object.keys(industrialList).forEach(systemType => this.getFacilitiesParameterData(systemType));
  }

  getFacilitiesParameterData(systemType) {
    const payload = {
      dates : this.utilsService.getLast30DaysDateRange(),
      systemType: [systemType]
    }
    this.registrationService.getFacilitiesParametersData(payload).subscribe(res => {
      this.map.updateFacilitiesSource(systemType, res[systemType])
    })
  }

  showDetailedFacilityFeatureLayerInformation(selectedFacility) {
    this.showMetersList = false;
    this.facilityInfoSelected = true;
    this.searchText = "";
    this.detailedMetersOrFacilitiesCardsInfo = JSON.parse(JSON.stringify(DEFAULT_DETAILED_FACILITY_CARD_INFO));
    const selectedFacilityOnOriginalList = this.selectedFacilities.find(facility => facility.description === selectedFacility.description);
    selectedFacilityOnOriginalList.selected = true;
    this.map.toggleSiteFeatureLayerClusterVisibility(false);
    this.map.toggleFacilitiesFeatureLayerClusterVisibility(false); // default set all facilities hidden then only show selected one
    const data = this.filterFacilityListBasedOnSystemTypeOfSelectedFacility(selectedFacility.systemType);
    this.initializeDetailedMetersOrFacilitiesCardsInfo(FACILITIES, formatedSystemTypeName[selectedFacility.systemType], data, '');
  }

  // getActiveAlertCountByDeviceType() {
  //   const payload = {
  //     dates : this.utilsService.getLast30DaysDateRange(),
  //     deviceType: [DEVICE_TYPE_WATER_METER, DEVICE_TYPE_ENERGY_METER, DEVICE_TYPE_COOLING_METER, FLOW_METER_DEVICE_TYPE],
  //     username: this.username
  //   } 

  //   this.alertsManagementService.getActiveAlertCountByDeviceType(payload).subscribe(res => {
  //     this.activeAlertsCountByDeviceType = res;
  //   }) 
  // }

  fetchSiteWiseConsumption() {
    if(!this.selectedMeter) 
        return;

    const payload = {
      dates: this.utilsService.getMTDDateRange(),
      productName: this.selectedMeter['productName'],
      sites: this.selectedSites.map(siteInfo => siteInfo.site)
    }
    this.telemetryService.siteWiseConsumption(payload).subscribe(res => {
      this.siteWiseConsumption = res;
    })
  }

  getConsumptionUnit() {
    this.registrationService.getConsumptionUnit().subscribe(res => {
      this.consumptionUnit = res;
    })
  }

  consumptionByProductName(productName) {
    const payload = {
      productName,
      dates: this.utilsService.getMTDDateRange()
    }
    this.telemetryService.consumptionByProductName(payload).subscribe(res => {
      this.consumptionByProductNames[productName] = res.data;
    })
  }
  productStatusCount() {
    this.registrationService.productStatusCount(this.productNameList).subscribe(res => {
      this.originalMeterList.forEach((item) => {
        const meterData = res[item.productName]
        item.liveDevices = meterData.liveDevices;
        item.offlineDevices = meterData.offlineDevices;
        item.totalDevices = meterData.totalDevices;
      });
      this.metersList.forEach((item) => {
        const meterData = res[item.productName]
        item.liveDevices = meterData.liveDevices;
        item.offlineDevices = meterData.offlineDevices;
        item.totalDevices = meterData.totalDevices;
      });
      // original meter list will be used to filter and reset the meter lists
      this.originalMeterList = JSON.parse(JSON.stringify(this.originalMeterList)); 
    })
  }

  fetchDevicesOfMeter(meter) {
    this.detailedMetersOrFacilitiesCardsInfo = JSON.parse(JSON.stringify(DEFAULT_DETAILED_METER_CARD_INFO));
    this.selectedMeter = meter;
    this.showMetersList = false;
    this.selectedProductNames = [meter.productName];
    this.getSites([this.selectedMeter.productName]);
    this.map.fetchDevices(this.selectedProductNames);
  }

  filterMapDataOnApplyCardFilter() {
    if(!this.showMetersList) // if it is expanded info panel don't follow this trend
      return;
     
    this.selectedProductNames = this.metersList.map(item => item.productName);
    if(this.selectedProductNames.length === 0) {
      this.map.toggleSiteFeatureLayerClusterVisibility(false);
    }
    else {
      this.map.toggleSiteFeatureLayerClusterVisibility(true);
      this.getSites(this.selectedProductNames);
      this.map.fetchDevices(this.selectedProductNames);
    }
    const idsToShow = this.filteredSelectedFacilities.map(d => d['oid']);
    this.map.toggleIndividualFacilityFeatureLayerVisibility(idsToShow);
  }

  // Also handle the back button feature
  async showMeters() {
    if(this.facilityInfoSelected) {
      this.map.reCentre(this.mapInfo);
    }
    this.selectedProductNames = this.mapFiltersInfo?.selectedProductNames ? this.mapFiltersInfo.selectedProductNames : JSON.parse(JSON.stringify(this.productNameList));
    this.showMetersList = true;

    
    this.map.removeExistingDevicesFeatureLayer();
    this.map.fetchDevices(this.selectedProductNames);

    this.selectedFacilities.forEach(facility => facility.selected = false); // deselect all the facility
    this.facilityInfoSelected = false;
    this.filteredSelectedFacilities = this.mapFiltersInfo?.filteredSelectedFacilities ? this.mapFiltersInfo?.filteredSelectedFacilities : JSON.parse(JSON.stringify(this.selectedFacilities));
    this.findFirstOccurenceOfSystemTypes(this.filteredSelectedFacilities)
    const idsToShow = this.filteredSelectedFacilities.map(d => d['oid']);
    this.map.toggleIndividualFacilityFeatureLayerVisibility(idsToShow);



    const siteAddedToMapPromise = [this.getSites(this.selectedProductNames)];
    await Promise.all(siteAddedToMapPromise);
    this.map.handleVisibilityOfSitesAndFacilitiesCard();


  }

  showLoader(loading) {
    this.loading = loading;
  }

  getZoneBySite() {
    this.originalMeterList.forEach(meter => {
      this.registrationService.getZoneBySite(meter.productName).subscribe(sites => {
        this.sitesList[meter.productName] = {};
        Object.keys(sites).forEach(siteName => {
          if(this.servedSites.includes(siteName))
            this.sitesList[meter.productName][siteName] = sites[siteName];
        });
      })  
    })
  }

  showMeterOnSelectedSite(productNames:string[]) {
    if(productNames.length === 0) {
      this.metersList = [];
    }
    else {
      this.metersList = this.originalMeterList.filter(meter => productNames.includes(meter.productName));
    }
  }

  initializeDetailedMetersOrFacilitiesCardsInfo(type, name, data, productName) {
    this.detailedMetersOrFacilitiesCardsInfo.type = type;
    this.detailedMetersOrFacilitiesCardsInfo.selectedMeterOrFacilityName = name;
    this.detailedMetersOrFacilitiesCardsInfo.data = data;
    this.detailedMetersOrFacilitiesCardsInfo.productName = productName;
    if(type === METERS) {
      this.expandedInfoPanelComponent?.ngOnInit();
    }
  }

  getSanitizedConsumptionUnit(productName) {
    const unit = this.consumptionUnit &&  this.consumptionUnit[productName] || '';
    return this.utilsService.getSanitizedHTMLContent(unit);
  }

  showSiteOnZoomEvent(site) {
    this.focusedSiteOnMap = site;
    this.expandedInfoPanelComponent?.showSiteOnZoomEvent(site)
  }

  showListVisibleFacilities(data) {
    if(data.show === TEXT_ALL) {
      this.filteredSelectedFacilities = this.mapFiltersInfo?.filteredSelectedFacilities ? this.mapFiltersInfo?.filteredSelectedFacilities : JSON.parse(JSON.stringify(this.selectedFacilities));
    } else {
      this.filteredSelectedFacilities = this.selectedFacilities.filter(facility => data.visibleFacilitiesList.includes(facility.description))
    }
    this.findFirstOccurenceOfSystemTypes(this.filteredSelectedFacilities)
  }

  // Search bar open and close
  datePickerDefaultAction() {
    this.showFilterPopup = true;
    $(".buttonCreate").on("click", function (e) {
      $(".searchPopup").show();
      $(".buttonCreate").addClass("createNewFolderClick");
      e.stopPropagation();
    });
    $(".buttonCreate.createNewFolderClick").on("click", function (e) {
      $(".searchPopup").hide();
      $(".buttonCreate").removeClass("createNewFolderClick");
      e.stopPropagation();
    });

    $(".searchPopup").click(function (e) {
      e.stopPropagation();
      $(".buttonCreate").removeClass("createNewFolderClick");
    });

    $(document).click(function (e) {
      $(".searchPopup").hide();
      $(".buttonCreate").removeClass("createNewFolderClick");
      e.stopPropagation();
    });

    $(".closeIcon").on("click", function (e) {
      $(".searchPopup").hide();
      e.stopPropagation();
    });
    
  }

  filterCardsOnSearch() {
    this.clearLobsSectorsFiltersList();
    this.metersList = this.originalMeterList.filter(item => item.productName.toLowerCase().includes(this.searchText.toLocaleLowerCase()));
    this.filteredSelectedFacilities = this.selectedFacilities.filter(item => item.description.toLocaleLowerCase().includes(this.searchText.toLocaleLowerCase()));
    this.findFirstOccurenceOfSystemTypes(this.filteredSelectedFacilities);
    this.filterMapDataOnApplyCardFilter();
    this.storeMapFilterInfoInLS();
  }

  filterCardsOnApplyFilter() {
    this.searchText = "";
    this.selectedLobs = this.lobFilterList.filter(item => item.selected).map(item => item.lob);
    this.selectedSectors = this.sectorFilterList.filter(item => item.selected).map(item => item.sector);
    if(this.selectedLobs.length === 0 && this.selectedSectors.length === 0) {
      this.resetFilterCards();
      this.removeDataFromLS('mapFilterInfo');
      return;
    }
    this.metersList = this.filterList(this.originalMeterList);
    this.filteredSelectedFacilities = this.filterList(this.selectedFacilities);
    this.findFirstOccurenceOfSystemTypes(this.filteredSelectedFacilities);
    this.filterMapDataOnApplyCardFilter();
    this.storeMapFilterInfoInLS();
  }

  filterList(list) {
    if(this.selectedLobs.length  && this.selectedSectors.length) {
      return list.filter(item => this.selectedLobs.includes(item.lineOfBusiness) && this.selectedSectors.includes(item.sector))
    }
    else if(this.selectedLobs.length) {
      return list.filter(item => this.selectedLobs.includes(item.lineOfBusiness))
    }
    else if(this.selectedSectors.length) {
      return list.filter(item => this.selectedSectors.includes(item.sector))
    }
  }

  resetFilterCards() {
    this.showFilterPopup = false;
    this.searchText = "";
    this.metersList = JSON.parse(JSON.stringify(this.originalMeterList));
    this.filteredSelectedFacilities = this.selectedFacilities;
    this.findFirstOccurenceOfSystemTypes(this.filteredSelectedFacilities);
    this.lobFilterList.forEach(item => item.selected = false);
    this.sectorFilterList.forEach(item => item.selected = false);
    this.filterMapDataOnApplyCardFilter();
  }

  showNoResultFound() {
    return (this.searchText != "" && this.filteredSelectedFacilities?.length === 0 && this.metersList?.length === 0) ? true : false;
  }

  showData(value) {
    return (value || value === 0) ? true : false
  }

  getValidValue(value) {
    return (value || value === 0 ) ? value : ''
  }

  handleFilterSelection(item) {
    item.selected = !item.selected;
    this.filterCardsOnApplyFilter();
  }

  clearAllAppliedFilters() {
    this.clearLobsSectorsFiltersList()
    this.resetFilterCards(); 
    this.removeDataFromLS('mapFilterInfo');
  }

  removeDataFromLS(key) {
    this.landingPageMapService.removeDataFromLS(key);
    this.mapFiltersInfo = {};
  }

  clearLobsSectorsFiltersList() {
    this.selectedLobs = [];
    this.selectedSectors = [];
  }

  isFilterApplied() {
    return (this.selectedLobs.length > 0 || this.selectedSectors.length > 0)
  }

  appliedFilterCount() {
    return this.selectedLobs.length+this.selectedSectors.length;
  }

  storeMapFilterInfoInLS() {
    const mapFilterInfo = {
      metersList: this.metersList,
      filteredSelectedFacilities: this.filteredSelectedFacilities,
      selectedLobs: this.selectedLobs,
      selectedSectors: this.selectedSectors,
      selectedProductNames: this.selectedProductNames,
      searchText: this.searchText
    }
    this.mapFiltersInfo = mapFilterInfo;
    this.landingPageMapService.storeDataInLS('mapFilterInfo', mapFilterInfo);
  }

  getMapFilterInfo() {
    return this.landingPageMapService.getDataFromLS('mapFilterInfo');
  }

  findFirstOccurenceOfSystemTypes(data) {
    this.firstOccurenceOfFacilitySystemType = {
      scada: null,
      transmission: null
    }
    let scadaLabelFound = false;
    let transmissionLabelFound = false;

    data.forEach((d, idx) => {
      if(scadaLabelFound && transmissionLabelFound) return;
      if(!scadaLabelFound && d.systemType === 'SCADA') {
        this.firstOccurenceOfFacilitySystemType.scada = idx;
        scadaLabelFound = true;
      } else if(!transmissionLabelFound && d.systemType === 'Transmission Point'){
        this.firstOccurenceOfFacilitySystemType.transmission = idx;
        transmissionLabelFound = true;
      } 
    })
  }
  filterFacilityListBasedOnSystemTypeOfSelectedFacility(systemType) {
    return this.selectedFacilities.filter(facility => facility.systemType === systemType);
  }
  ngOnDestroy() {
    window.removeEventListener("resize", this.handleResize);
    if(this.isFilterApplied()) // if filter exist then only save on LS
      this.storeMapFilterInfoInLS();
  }
}
