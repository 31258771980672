import { Component, HostListener, OnInit } from "@angular/core";
import { NavigationStart, Router, NavigationEnd } from "@angular/router";
import * as $ from "jquery";
import { SharedDataService } from './modules/shared-module/shared-meter-type.service';
import * as moment from "./reusable/header/header.component";
import { DataService } from "./services/behaviour.service";
import { environment } from "src/environments/environment";
import { Subscription } from "rxjs";
import { BreakpointService } from "./services/breakpoint-service/breakpoint.service";
import { UserAuthService } from "./modules/sso/user-auth.service";
import { filter } from 'rxjs/operators';

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit {
  title = "neom-dashboard-ui-frontend";
  showHeader: boolean = false;
  showHeaderPreLogin: boolean;
  pagenamefromcustom;
  topicpages: any = [];
  goheader = true;
  hiddenRoutes: string[] = ['/user', '/user?', '/sso', '/connectioncanvas', '/user?returnUrl=%2Fhome'];
  subscription:any;
  private previousUrl: string | null = null;
  private currentUrl: string | null = null;
  // Responsive design related variables
  private breakpointSubscription: Subscription;
  @HostListener('window:beforeunload', ['$event'])
  handleBeforeUnload($event: any): void {
    localStorage.removeItem('selectedSites');
  }

  constructor(
    private router: Router,
    private dataService: DataService,
    private sharedChange: SharedDataService,
    private breakpointService: BreakpointService,
    public userAuthService: UserAuthService,
    
    ) {
      this.router.events
    .pipe(filter(event => event instanceof NavigationEnd))
    .subscribe((event: NavigationEnd) => {
      this.previousUrl = this.currentUrl;
      this.currentUrl = event.urlAfterRedirects;
      const getSecondToLastSegment = (url: string | null): string | null => {
        if (!url) return null;
        const segments = url.split('/').filter(segment => segment.length > 0);
        return segments.length > 0 ? segments[0] : null;
      };
      const previousSegment = getSecondToLastSegment(this.previousUrl);
      const currentSegment = getSecondToLastSegment(this.currentUrl);
      if (previousSegment !== currentSegment) {
        localStorage.removeItem('selectedSites');
      }
    });
     this.breakpointSubscription = this.breakpointService.breakpointChanges.subscribe(matches => {
        this.breakpointService.setScreenUsed(matches);
     });

    //  Hide the navbar for components
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      this.showHeader = !this.hiddenRoutes.some(route => event.urlAfterRedirects.includes(route));
      const token = localStorage.getItem('token');
      if (!token && event.url.includes("/developers-page")) {
        this.showHeaderPreLogin = true;
        this.showHeader = false;
      }
      if (event.url.includes("/connectioncanvas")) {
        this.showHeader = false;
      }

      if (event.url.includes("/alerts")) {
        this.selectSector('device alerts');
      } else if (event.url === '/dashboard') {
        this.selectSector('dashboard');
      } else if (event.url.includes("/devices")) {
        if (event.url.includes("/devices/device-dashboard")) {
          this.selectSector('device dashboard');
        } else {
          this.selectSector('device listing');
        }
      } else if (event.url.includes("/leakage")) {
        this.selectSector('leakage insights');
      } 
      else if(event.url.includes("/dashboard")){
        this.selectSector('dashboard');
      }
    });

    // disabling the console logs in production
    if (environment.production) {
      console.log = function (): void { };
    }
  }

  ngOnInit() {
    if (window.location.pathname.endsWith('user')) {
      this.showHeader = false;
    }
  }
  ngOnDestroy() {
    this.breakpointSubscription.unsubscribe();
  }
  selectSector(sector) {
    this.dataService.updateSector(sector);
  }

}
