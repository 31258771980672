import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { PaginationComponent } from 'src/app/reusable/custom-table/pagination/pagination.component';
import { saveAs } from 'file-saver-es';
import * as moment from 'moment';
import { meterLogsColumns, noDataMessage } from 'src/app/modules/device-management/device-filter/helper';
import { ToastService } from 'src/app/services/toast/toast.service';
@Component({
  selector: 'app-meter-logs',
  templateUrl: './meter-logs.component.html',
  styleUrls: ['./meter-logs.component.css', '../../../assets/Reusable-CSS/table.scss', '../../../assets/Reusable-CSS/main.scss', '../../../assets/Reusable-CSS/matTooltip.scss']
})

export class MeterLogsComponent implements OnInit {

  @Input() filterColumns = {};
  @Input() sortFilter = {};
  @Input() columnNames = {};
  @Input() loading = false;
  @Input() data = [];

  @ViewChild('tablePagination', { static: false }) tablePagination: PaginationComponent;

  constructor(private toastr: ToastService) { }
  ngOnInit() {
  }

  public appliedFilters = [];
  public paginatedData: any = []; // this will store the paginated data based on item per page, like 10 if itemPerPage = 10, this variable used on the ui
  public filterAlerts = [];
  public currentPage: number = 1;
  public itemPerPage: number = 10;
  public pageSizeOption = [10, 15, 20, 25]

  handlePaginationDone(data) {
    this.filterAlerts = data?.filteredData;
    this.paginatedData = data?.paginatedData;
  }

  handleAppliedFilter(item) {
    if (item.selected) {
      this.appliedFilters.push(item);
    }
    else {
      const idx = this.appliedFilters.findIndex(filter => filter.name === item.name);
      this.appliedFilters.splice(idx, 1);
    }
    this.tablePagination.handleAppliedFilters();
  }

  sortTable(data) {
    this.tablePagination.handleSort(data);
  }

  downloadMeterLogsData() {
    if(!this.filterAlerts?.length){
      this.toastr.error(noDataMessage);
      return;
    }
    const replacer = (key, value) => value === null ? '' : value;
    const columnsToDisplay = meterLogsColumns;
    const header = columnsToDisplay;
    let tempArr = [];
    this.filterAlerts?.forEach(row => {
      tempArr.push({ 'Event Name': row.eventName, 'Timestamp': moment(row.timestamp).format('dd-MMM-y, hh:mm:ss'), 'Event Details': `${row.events[0]['unit']} = ${row.events[0]['value']}` })
    })

    // header.shift();
    let csv = tempArr.map(row => header.map(filename => JSON.stringify(row[filename], replacer)).join(','));
    csv.unshift(header.join(','));
    const csvArray = csv.join('\r\n');
    const filenamelatest = 'meter-logs-' + new Date().getDate() + "-" + (new Date().getMonth() + 1) + "-" + new Date().getFullYear() + " " + new Date().getHours() + ":" + new Date().getMinutes() + '.csv';

    const blob = new Blob([csvArray], { 'type': 'text/csv' });
    saveAs(blob, filenamelatest);
    return;
  }

}
