import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-donut-chart',
  templateUrl: './donut-chart.component.html',
  styleUrls: ['./donut-chart.component.css']
})
export class DonutChartComponent implements OnInit {
  @Input() live;
  @Input() total;

  constructor() { }

  ngOnInit(): void {
  }

  getLiveDevicePercentage(live: number, total: number): number {
    return total > 0 ? Math.round((live / total) * 100) : 0;
  }
  
  getDonutChartStrokeForLiveDevices(live: number, total: number): string {
    let percent = this.getLiveDevicePercentage(live, total);
    return `${percent}, 100`;
  }
  
  getDonutChartStrokeForOfflineDevices(live: number, total: number): string {
    let percent = 100 - this.getLiveDevicePercentage(live, total);
    return `${percent}, 100`;
  }

  getLiveDeviceStrokeOffset(live: number, total: number): string {
    let percent = this.getLiveDevicePercentage(live, total);
    return `-${percent}`;
  }

}
