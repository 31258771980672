import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ChatbotService } from '../services/chatbot.service';
import * as moment from 'moment';
import { Helper } from '../helper-util';

@Component({
  selector: 'app-chatbot',
  templateUrl: './chatbot.component.html',
  styleUrls: ['./chatbot.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ChatbotComponent implements OnInit {
  isActive = false;
  messages: Array<{
    text: string;
    html: SafeHtml;
    sender: string;
    time: string;
  }> = [];
  userName: string = '';
  currentMessage: string = '';
  suggestions = [
    {
      label: 'How many alerts are active currently?',
      question: 'How many alerts are active currently?'
    },
    {
      label: 'How many critical alerts are there?',
      question: 'How many critical alerts are there?'
    }
  ];
  renderMessage;

  constructor(
    private chatbotService: ChatbotService,
    private sanitizer: DomSanitizer,
    private helper: Helper
  ) {}

  ngOnInit() {
    const useremail = localStorage.getItem('useremail');
    this.userName = this.getInitialsFromEmail(useremail);
  }

  toggleChat() {
    this.isActive = !this.isActive;
  }

  async handleSuggestion(suggestion: string) {
    await this.processMessage(suggestion);
  }

  async sendMessage() {
    const currentMessage = this.currentMessage;
    if (!currentMessage.trim()) return;
    this.currentMessage = '';
    await this.processMessage(currentMessage);
  }

  private async processMessage(message: string) {
    this.addMessage(message, true);
    this.addMessage('Typing...', false);
    try {
      const response = await this.getQuery(message);
      this.messages.pop();

      this.addMessage(response, false);
    } catch (error) {
      console.error('Error:', error);
      this.messages.pop();
      this.addMessage('Sorry, I encountered an error processing your request.', false);
    }
  }

  private addMessage(message: string, isUser: boolean) {
    let html: SafeHtml = message;

    if (!isUser) {
      try {
        const parsedHtml = message;
        const formattedMessage = this.helper.renderText(parsedHtml);
        html = this.sanitizer.bypassSecurityTrustHtml(formattedMessage);
      } catch (error) {
        console.error('Markdown parsing error:', error);
      }
    }

    const time = moment().format('LT');

    this.messages.push({ text: message, html, sender: isUser ? 'user' : 'chatbot', time });

    // Scroll to bottom on next tick
    setTimeout(() => {
      const element = document.querySelector('.chat-messages');
      if (element) {
        element.scrollTop = element.scrollHeight;
      }
    });
  }

  private async getQuery(question: string): Promise<string> {
    try {
      const response = await this.chatbotService.getChatbotQuery({ question, chatID: "1" }).toPromise();
      return response?.results || response?.response || 'No response received';
    } catch (error) {
      return 'Failed to get response from backend';
    }
  }

  getInitialsFromEmail(email) {
    const username = email.split('@')[0];
    const words = username.split(/[._]/);
    const initials = words[0]?.[0] + (words[1]?.[0] || '');
    return initials.toUpperCase();
  }

  formatMessage(text: string): string {
    return text.replace(/\*\*(.*?)\*\*/g, '<b>$1</b>');
  }

}
